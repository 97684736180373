import {Box, Button, FormControl, FormLabel, RadioGroup, Typography} from '@mui/material';
import {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {UserPreferencesContext} from '../../../../providers/UserPreferencesProvider';
import {FlagIcon} from 'react-flag-kit';
import RadioButton from '../../../../components/form/RadioButton';
import TopFlagIcon from '@mui/icons-material/Flag';
import StepHeader from '../shared/StepHeader';
import StepContainer from '../shared/StepContainer';

interface LanguageSelectionStepProps {
    onContinue: () => void;
}

export default function LanguageSelectionStep(props: LanguageSelectionStepProps) {
    const {t} = useTranslation();

    const {languageCode, setLanguageCode} = useContext(UserPreferencesContext);

    const handleLanguageChange = (lang: string) => {
        setLanguageCode(lang);
    };

    return (
        <StepContainer>
            <StepHeader
                icon={<TopFlagIcon/>}
                title={t('setup_wizard.language_selection_step.title')}
                description={t('setup_wizard.language_selection_step.description')}
            />

            <FormControl fullWidth
                         sx={{
                             textAlign: "left"
                         }}>
                <FormLabel id="demo-radio-buttons-group-label">
                    <Typography variant="smallTitle">
                        {t('setup_wizard.language_selection_step.select_language')}
                    </Typography>
                </FormLabel>
                <RadioGroup
                    name="radio-buttons-group"
                >
                    <RadioButton icon={<FlagIcon style={{marginBottom: '-5px'}} code={'GB'}/>} mainText={t('languages.english')}
                                 selected={languageCode === 'en'} onClick={() => {
                        handleLanguageChange('en');
                    }}/>
                    <RadioButton icon={<FlagIcon style={{marginBottom: '-5px'}} code={'FR'}/>} mainText={t('languages.french')}
                                 selected={languageCode === 'fr'} onClick={() => {
                        handleLanguageChange('fr');
                    }}/>
                </RadioGroup>
            </FormControl>
            <Box>
                <Button color='primary' sx={{marginBottom: "5px"}} fullWidth variant="contained"
                        onClick={props.onContinue}>{t('button.continue')}</Button>
            </Box>
        </StepContainer>
    );
};





