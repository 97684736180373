import { Box, Button, Divider, Grid, MenuItem, Typography } from "@mui/material";
import SelectInput from "../../../../components/form/SelectInput";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserPreferencesContext } from "../../../../providers/UserPreferencesProvider";
import { UserPreferences } from "../../../../hooks/user/dto/UserPreferences";
import useUserApi from "../../../../hooks/user/User";

interface AccessibilityPageProps {
}

export default function AccessibilityPage(props: AccessibilityPageProps) {

    const [editMode, setEditMode] = useState<boolean>(false);

    const { setUserPreferences } = useUserApi();
    const { colorPalette, setColorPalette, largeText, setLargeText, openDrawer, languageCode, clusterEnabled, labelsEnabled, mapType, labelType, twentyFourHourEnabled } = useContext(UserPreferencesContext);

    const [tempColorPalette, setTempColorPalette] = useState<string>(colorPalette);
    const [tempFontSize, setTempFontSize] = useState<string>(largeText ? 'large' : 'default');

    function cancelEdit() {
        setTempColorPalette(colorPalette);
        setTempFontSize(largeText ? 'large' : 'default');
        setEditMode(false);
    }

    async function saveChanges() {

        const userPreferences: UserPreferences = {
            colorPalette: tempColorPalette,
            largeText: tempFontSize === 'large',
            openDrawer: openDrawer,
            languageCode: languageCode,
            clusterEnabled: clusterEnabled,
            labelsEnabled: labelsEnabled,
            mapType: mapType,
            labelType: labelType,
            twentyFourHourEnabled: twentyFourHourEnabled
        }

        await setUserPreferences(userPreferences);
        setColorPalette(tempColorPalette);
        setLargeText(tempFontSize === 'large');
        setEditMode(false);

    }

    const { t } = useTranslation();

    const colorPalettes = [
        (<MenuItem id="color-palette-default" key="color-palette-default" value={'default'}>{t('setup_wizard.accessibility_step.color_palette.options.default.label')}</MenuItem>),
        (<MenuItem id="color-palette-protanopia" key="color-palette-protanopia" value={'protanopia'}>{t('setup_wizard.accessibility_step.color_palette.options.protanopia.label')}</MenuItem>),
        (<MenuItem id="color-palette-deuteranopia" key="color-palette-deuteranopia" value={'deuteranopia'}>{t('setup_wizard.accessibility_step.color_palette.options.deuteranopia.label')}</MenuItem>),
        (<MenuItem id="color-palette-tritanopia" key="color-palette-tritanopia" value={'tritanopia'}>{t('setup_wizard.accessibility_step.color_palette.options.tritanopia.label')}</MenuItem>)
    ];

    const fontSizes = [
        (<MenuItem id="font-sizes-default" key="color-palette-default" value={'default'}>{t('setup_wizard.accessibility_step.font_size.options.default')}</MenuItem>),
        (<MenuItem id="font-sizes-large" key="color-palette-large" value={'large'}>{t('setup_wizard.accessibility_step.font_size.options.large')}</MenuItem>),
    ];

    return (<Box sx={{
        display: 'flex', flexDirection: "column", flexGrow: 1, justifyContent: "space-between"
    }}>
        <Box sx={{
            padding: '30px',
            paddingTop: '10px',
        }}>
            <Typography variant="header" sx={{ paddingBottom: "30px" }}>
                {t('profile_page.accessibility.title')}
            </Typography>
            <Divider />
            <Grid container padding="10px" spacing={2.5}>
                <Grid item xs={12} md={4} lg={4}>
                    <Typography variant="title">
                        {t('profile_page.accessibility.visual_impairment.title')}
                    </Typography>
                    <Typography variant="body">
                        {t('profile_page.accessibility.visual_impairment.description')}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={8} lg={8}>
                    <SelectInput key="colour-palette-select" editMode={editMode} label={t('profile_page.accessibility.visual_impairment.colour_palette')} required={true} items={colorPalettes} value={tempColorPalette} onChange={(choice) => setTempColorPalette(choice)} />
                    <SelectInput key="font-size-select" editMode={editMode} label={t('profile_page.accessibility.visual_impairment.font_size')} required={true} items={fontSizes} value={tempFontSize} onChange={(choice) => setTempFontSize(choice)} />
                </Grid>
            </Grid>


        </Box>
        {editMode && (
            <Box
                bottom="0px"
                sx={{
                    padding: '10px',
                    borderTop: '1px solid #808080',
                    display: 'flex', flexDirection: "row", justifyContent: "space-between"
                }}>
                <Button
                    variant="contained"
                    size='small'
                    color="secondary"
                    onClick={() => {
                        cancelEdit()
                    }}>
                    {t('button.cancel')}
                </Button>
                <Button
                    variant="contained"
                    size='small'
                    color="primary"
                    onClick={saveChanges}>
                    {t('button.save')}
                </Button>
            </Box>)}

        {!editMode && (
            <Box
                bottom="0px"
                sx={{
                    padding: '10px',
                    borderTop: '1px solid #808080',
                    display: 'flex', flexDirection: "row", justifyContent: "space-between"
                }}>
                <Box />
                <Button
                    variant="contained"
                    size='small'
                    color="primary"
                    onClick={() => {
                        setEditMode(true);
                    }}>
                    {t('button.edit')}
                </Button>
            </Box>)
        }
    </Box>)

}
