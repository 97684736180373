import {ListItemButton, ListItemText} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import React from "react";
import {colors} from "../../themes/AppTheme";

interface AssetsDetailHeadingProps {
    title: String;
    open: boolean;
    onClick: Function;
}

function AssetsDetailHeading(props: AssetsDetailHeadingProps) {

    return (
        <ListItemButton
            onClick={() => props.onClick()}
            sx={{
                backgroundColor: "tertiary.main",
                borderTop: 1,
                borderBottom: 1,
                borderColor: "divider",
                paddingLeft: "10px",
                paddingTop: "10px",
                paddingBottom: "10px",
                color: colors.ram_purple
            }}
        >
            <ListItemText primary={props.title}/>
            {props.open ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
    );
}

export default AssetsDetailHeading;
