import {Checkbox, Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Table, TableCell, TableRow} from "../core/Table";
import {UserLocation} from "../../../hooks/userLocations/dto/UserLocation";

interface SelectUserLocationTableProps {
    loading: boolean;
    userLocations: UserLocation[];
    multiselect?: boolean;
    disableSelect?: boolean;
    selectedRows: number[];
    setSelectedRows: (selectedRows: number[]) => void;
}

export default function SelectUserLocationTable(props: Readonly<SelectUserLocationTableProps>) {
    const {t} = useTranslation();

    const handleRowSelect = (userLocation: number) => {
        if (props.multiselect) {
            if (props.selectedRows.includes(userLocation)) {
                props.setSelectedRows(props.selectedRows.filter(selectedUserLocation => selectedUserLocation !== userLocation));
            } else {
                props.setSelectedRows([...props.selectedRows, userLocation]);
            }
        } else {
            props.setSelectedRows([userLocation]);
        }
    };

    const handleSelectAllUserLocations = () => {
        if (props.userLocations.length === props.selectedRows.length) {
            props.setSelectedRows([]);
        } else {
            props.setSelectedRows(props.userLocations.map(userLocation => userLocation.id));
        }
    };

    const selectHeader = props.multiselect ? (
        <Tooltip title={t("select.all")}>
            <Checkbox
                id="select-all-user-locations"
                disabled={props.disableSelect}
                checked={props.userLocations.length === props.selectedRows.length}
                onChange={() => handleSelectAllUserLocations()}
            />
        </Tooltip>) : <></>;

    const columns = [
        {id: 'select', content: selectHeader},
        {id: 'name', content: t('user_location_table.name')},
    ];

    const rows = props.userLocations?.map((userLocation) => {
        return <TableRow key={userLocation.id}>
            <TableCell>
                <Checkbox
                    id={"select-user-location-" + userLocation.id}
                    disabled={props.disableSelect}
                    checked={props.selectedRows.includes(userLocation.id)}
                    onChange={() => handleRowSelect(userLocation.id)}
                />
            </TableCell>
            <TableCell>{userLocation.name || "-"}</TableCell>
        </TableRow>

    });

    return (
        <Table
            id="select-user-location-table"
            label="select-user-location-table"
            columns={columns}
            disableCreateButton={true}
            rows={rows}
            loading={props.loading}/>
    );
}
