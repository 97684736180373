import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";


export default function LandingPage() {

    const { t } = useTranslation();
    const auth: IAuthContext = useContext(AuthContext)
    const queryParams = new URLSearchParams(window.location.search);
    const forgotPasswordSent = queryParams.get('forgotpasswordsent');

    return (
        <div className="w-full min-h-[calc(100vh-48px)] bg-base-100">
            {forgotPasswordSent === "true" && (
                <div className={"w-full pt-4 px-6"}>
                    <div className={"text-center bg-base-300 rounded-xl p-4"}>
                        {t('landing_page.forgot_password.title')}
                    </div>
                </div>
            )}
        {/*  two columns  */}
            <div className="flex flex-col lg:flex-row h-full max-w-[1300px] mx-auto">
                <div
                    className="flex flex-col gap-4 p-10 justify-center items-center text-center lg:text-left lg:items-start   lg:w-1/2">
                    <img src={"/images/logos/ram-tracking.png"} alt="RAM Tracking" className="w-[100px]" />
                    <h1 className="mt-5 font-inter text-3xl lg:text-4xl font-bold leading-[1.15] lg:leading-[1.05] tracking-tight">
                        {t('landing_page.title')}
                    </h1>

                    <p className="text-sm font-inter max-w-[400px]">
                        {t('landing_page.description')}
                    </p>

                    <button
                        className="primary-button-inverse w-full max-w-[400px]"
                        onClick={() => {
                            auth.login();
                        }}
                    >
                        {t('landing_page.sign_in')}
                    </button>

                    <button
                        className="secondary-button-inverse w-full max-w-[400px]"
                        onClick={() => {
                            window.location.href = "https://www.ramtracking.com";
                        }}
                    >
                        {t('landing_page.sign_up')}
                    </button>
                </div>
                <div
                    className="w-full flex flex-col rounded-xl flex-1 items-center justify-center bg-base-100 p-4 lg:w-1/2">
                {/*<LandingPageImage/>*/}
                    <div className="p-14 rounded-lg w-full h-full bg-base-200">
                        <img
                            src="/images/landing/landing.png"
                            alt="RAM Tracking"
                            className="w-full h-full object-contain"
                        />
                    </div>
                </div>
            </div>

        </div>
    );
}

