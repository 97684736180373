import {Box, Button, Grid, Typography} from '@mui/material';
import {useTranslation} from "react-i18next";
import {colors} from '../../../themes/AppTheme';
import useAccountApi, {FeaturePackage} from '../../../hooks/account/Account';
import React, {useContext} from "react";
import {UserAccountContext} from "../../../providers/UserAccountProvider";

interface FeatureListPanelProps {
    color: string;
}

const FeatureListPanel: React.FC<FeatureListPanelProps> = (props: FeatureListPanelProps) => {

    const {t} = useTranslation();
    const {account} = useAccountApi();
    const userAccountContext = useContext(UserAccountContext);
    const accountFeaturePackage = FeaturePackage[account?.featurePackage as keyof typeof FeaturePackage];

    const isLitePackage = isLite(accountFeaturePackage)
    const isCorePackage = isCore(accountFeaturePackage)
    const isPlusPackage = isPlus(accountFeaturePackage)

    let getDescription = () => {
        if (isLitePackage) {
            return t('feature_package.package.lite.description');
        } else if (isCorePackage) {
            return t('feature_package.package.core.description');
        } else {
            return t('feature_package.package.plus.description');
        }
    };

    return (
        <Box
            id="feature-list-panel"
            display="flex"
            flexDirection="column"
            alignItems="Left"
            sx={{
                background: props.color,
                height: '100%',
                padding: '30px',
                paddingTop: '10px',
                paddingBottom: '10px',
                borderRadius: '5px',
                boxShadow: 3,
            }}>
            <Box sx={{
                flexDirection: "column", flexGrow: 1
            }}>
                <Typography variant="header">
                    {account?.name}
                </Typography>

                <Grid container>
                    <Grid item xs={4}>
                        <Box
                            sx={{
                                bgcolor: isLitePackage ? colors.ram_orange : 'rgba(243, 111, 33, 0.5)',
                                height: 10, borderRadius: 1, margin: '0 1px'
                            }}
                        />
                        <Typography variant="smallTitle"
                                    sx={{
                                        textAlign: 'center',
                                        color: isLitePackage ? "text.primary" : "text.secondary"
                                    }}
                        >
                            {t('feature_package.package.lite.name')}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Box
                            sx={{
                                bgcolor: isCorePackage ? colors.ram_orange : 'rgba(243, 111, 33, 0.5)',
                                height: 10,
                                borderRadius: 1,
                                margin: '0 1px'
                            }}
                        />
                        <Typography variant="smallTitle"
                                    sx={{
                                        textAlign: 'center',
                                        color: isCorePackage ? "text.primary" : "text.secondary"
                                    }}
                        >
                            {t('feature_package.package.core.name')}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Box
                            sx={{
                                bgcolor: isPlusPackage ? colors.ram_orange : 'rgba(243, 111, 33, 0.5)',
                                height: 10,
                                borderRadius: 1,
                                margin: '0 1px'
                            }}
                        />
                        <Typography variant="smallTitle"
                                    sx={{
                                        textAlign: 'center',
                                        color: isPlusPackage ? "text.primary" : "text.secondary"
                                    }}
                        >
                            {t('feature_package.package.plus.name')}
                        </Typography>
                    </Grid>
                </Grid>
                <Typography align={'justify'} variant="body">
                    {getDescription()}
                </Typography>


            </Box>

            {!isPlusPackage ?
                <Box>

                    <Box sx={{flexDirection: "row"}}>
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={() => window.location.replace(`https://ram2ams.azurewebsites.net/api/RAMMarketing?code=FZDqro1VuczY2-HPWgRzRoqwDUCfh0rMvKg9jRfrCjhYAzFu01v-tw==&sf_campaign=Banner&utm_medium=Upgrade%20Options&utm_campaign=Upgrade&utm_source=From%20RAM%20Tracking&destinationURL=https%3A%2F%2Fwww2.ramtracking.com%2Fuk%2Fe%2Fupgrade-to-plus&account_id=${account?.id}&user_id=${userAccountContext.user?.id}`)}
                            sx={{
                                marginTop: '20px',
                                marginBottom: '20px'
                            }}>
                            {t('feature_package.upgrade_button')}
                        </Button>
                    </Box>
                </Box> : <></>}
        </Box>
    );
}

function isLite(featurePackage: FeaturePackage) {
    return FeaturePackage.LITE === featurePackage || FeaturePackage.LEGACY_LITE === featurePackage;
}

function isCore(featurePackage: FeaturePackage) {
    return FeaturePackage.CORE === featurePackage || FeaturePackage.LEGACY_FULL === featurePackage;
}

function isPlus(featurePackage: FeaturePackage) {
    return FeaturePackage.PLUS === featurePackage;
}


export default FeatureListPanel;
