import React, { useState } from 'react';
import { Box, Grid, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {AccountContact} from "../../../../hooks/account/Account";
import {filterContactsBySearch} from "../../../../utils/RecipientArrayFiltering";
import SelectRecipientTable from "../../../../components/table/shared/SelectRecipientTable";

interface AlertRecipientSelectorProps {
    isEdit: boolean;
    multiselect: boolean;
    loadingRecipients: boolean;
    recipients: AccountContact[] | null;
    selectedRecipientsRows: string[];
    setSelectedRecipientsRows: React.Dispatch<React.SetStateAction<string[]>>;
    label: string;
    alert?: any[];
}
const AlertRecipientSelector: React.FC<AlertRecipientSelectorProps> = ({
                                                                     isEdit,
                                                                     multiselect,
                                                                     loadingRecipients,
                                                                     recipients,
                                                                     selectedRecipientsRows,
                                                                     setSelectedRecipientsRows,
                                                                     label,
                                                                     alert = []
                                                                 }) => {
    const [recipientFilter, setRecipientFilter] = useState<string | undefined>(undefined);

    const onRecipientSearchChange = (search: string) => {
        setRecipientFilter(search === "" ? undefined : search);
    };

    const filterRecipients = (recipients: Array<AccountContact>) => {
        let filteredRecipients = filterContactsBySearch(recipients, recipientFilter);

        if (!isEdit && alert.length > 0) {
            filteredRecipients = filterRecipientsNotInAlert(filteredRecipients);
        }

        return filteredRecipients;
    };

    const filterRecipientsNotInAlert = (recipients: Array<AccountContact>) => {
        const recipientIdList = alert.map(alert => alert.vehicleId);
        return recipients.filter(recipient => !recipientIdList.includes(recipient.id));
    };

    return (
        <>
            <Box sx={{ paddingBottom: '10px' }}>
                <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    label={label}
                    variant="outlined"
                    color="info"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    value={recipientFilter}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        onRecipientSearchChange(event.target.value);
                    }}
                />
            </Box>
            <Grid>
                <SelectRecipientTable
                    multiselect={multiselect}
                    loading={loadingRecipients}
                    recipients={filterRecipients(recipients || []).map(recipient => recipient.email)}
                    selectedRows={selectedRecipientsRows}
                    setSelectedRows={setSelectedRecipientsRows}
                />
            </Grid>
        </>
    );
};

export default AlertRecipientSelector;