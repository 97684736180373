import {useEffect, useState} from "react";
import usePlatformFetch from "../shared/PlatformFetch";
import PropertiesLoader from "../../properties/PropertiesLoader";
import {AssetGroup} from "./dto/AssetGroup";
import {AssetGroupRequest} from "./dto/AssetGroupRequest";

const properties = PropertiesLoader();

interface AssetGroupApiOptions {
    shouldLoadAll?: boolean;
    shouldPoll?: boolean;
    pollFrequency?: number;
}

export function useAssetGroupApi(props: AssetGroupApiOptions = {}) {
    const {platformFetch, checkOk} = usePlatformFetch();

    const [assetGroups, setAssetGroups] = useState<AssetGroup[]>([]);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const getAllAssetGroups = async () => {
        return new Promise((resolve, reject) => {
            setLoading(true)
            platformFetch(
                properties.platformApiEndpoint + "tracking/v1/labels",
                'get')
                .then(checkOk)
                .then(response => response.json())
                .then(assetGroups => {
                    setAssetGroups(assetGroups)
                    setLoading(false)
                    resolve(assetGroups)
                })
                .catch((error) => {
                    console.error("Error getting asset group: " + error)
                    setError(error)
                    setLoading(false)
                    reject(error)
                });
        });
    }

    const createAssetGroup = async (assetGroupRequest: AssetGroupRequest) => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `tracking/v1/labels`,
                'post',
                JSON.stringify(assetGroupRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(assetGroup => resolve(assetGroup))
                .catch((error) => {
                    console.error("Error creating asset group: " + error)
                    reject(error)
                });
        });
    }

    const updateAssetGroup = async (assetGroupUpdateRequest: AssetGroupRequest, assetGroupId: string) => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `tracking/v1/labels/${assetGroupId}`,
                'put',
                JSON.stringify(assetGroupUpdateRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(assetGroup => resolve(assetGroup))
                .catch((error) => {
                    console.error("Error updating asset group: " + error)
                    reject(error)
                });
        });
    }

    const deleteAssetGroup = async (assetGroupId: string) => {
        return new Promise<void>((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `tracking/v1/labels/${assetGroupId}`,
                'delete')
                .then(checkOk)
                .then(() => resolve())
                .catch((error) => {
                    console.error("Error deleting asset group: " + error)
                    reject(error)
                });
        });
    }

    useEffect(() => {
        if (props.shouldLoadAll) {
            getAllAssetGroups();
            if (props.shouldPoll) {
                const interval = setInterval(getAllAssetGroups, props.pollFrequency ?? 30000);
                return () => clearInterval(interval);
            }
        }
    }, []);

    return {
        loading, error, assetGroups: assetGroups, getAllAssetGroups: getAllAssetGroups,
        createAssetGroup, updateAssetGroup: updateAssetGroup, deleteAssetGroup: deleteAssetGroup
    }
}
