import { ReportResponse } from "./dto/ReportResponse"
import { ReportTypeResponse } from "./dto/ReportTypeResponse"

export function mapToReportTypeArray(json: any) {
    return json.map((reportType: any) => {
        return mapToReportType(reportType)
    })
}

export function mapToReportType(reportType: any) {
    return new ReportTypeResponse({
        id: reportType.id,
        description: reportType.description,
        reportCode: reportType.reportCode
    })
}

export function mapToReportResponse(reportResponse: any) {
    return new ReportResponse({
        success: reportResponse.success,
        link: reportResponse.link,
        errorMessage: reportResponse.errorMessage
    })
}