import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import TextInput from "../../../components/form/TextInput";
import {SelectorLabel} from "./SelectorLabel";
import React from "react";

interface ReportEmailProps {
    email: string;
    setEmail: (notes: string) => void;
    required: boolean;
}

export function ReportEmailSelector(props: ReportEmailProps) {

    const {t} = useTranslation();

    return <Grid container>
        <SelectorLabel
            title={t("report.email")}
            subtitle={t("report.enter_email")}
        />
        <Grid item xs={12} md={8} lg={10} paddingTop="10px" paddingLeft="20px" paddingRight="20px">
            <TextInput editMode={true}
                       isEmail={true}
                       required={props.required}
                       label={''}
                       placeholder={''}
                       value={props.email}
                       onChange={props.setEmail}/>
        </Grid>
    </Grid>
}
