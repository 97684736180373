// EventTypeSelection.tsx
import {MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {useTranslation} from "react-i18next";
import {VehicleEvent} from "../../../../enum/VehicleEvent";
import {colors} from "../../../../themes/AppTheme";

interface EventTypeSelectionProps {
    selectedEventType: VehicleEvent;
    setSelectedEventType: (selectedEventType: VehicleEvent) => void;
}

export default function EventTypeSelection(props: EventTypeSelectionProps) {
    const {t} = useTranslation();

    const handleEventTypeChange = (event: SelectChangeEvent<VehicleEvent>) => {
        props.setSelectedEventType(event.target.value as VehicleEvent);
    };

    // Get the event types and sort them based on translated values
    const eventTypes = Object.values(VehicleEvent).sort((a, b) => {
        const translationA = t(`vehicle_event.${a.toLowerCase()}`);
        const translationB = t(`vehicle_event.${b.toLowerCase()}`);
        return translationA.localeCompare(translationB);
    });

    return (
        <Select
            id="event_type"
            size="small"
            value={props.selectedEventType}
            color="info"
            style={{height: '100%', fontSize: '12px', color: colors.ram_purple}}
            onChange={handleEventTypeChange}
            fullWidth
        >
            {eventTypes.map((eventType) => (
                <MenuItem
                    key={eventType}
                    style={{height: 25, fontSize: '12px'}}
                    value={eventType}
                >
                    {t(`vehicle_event.${eventType.toLowerCase()}`)}
                </MenuItem>
            ))}
        </Select>
    );
}
