import { useTranslation } from "react-i18next";
import { AlertPeriod } from "../../../../hooks/alerts/GeofenceAlerts";
import SelectStringTable from "../../../../components/table/shared/SelectStringTable";

interface GeofenceAlertDayTableProps {
    alertPeriod: AlertPeriod;
    setAlertPeriod: (selectedAlertPeriod: AlertPeriod) => void;
}

export default function GeofenceAlertDayTable(props: GeofenceAlertDayTableProps) {
    const { t } = useTranslation();

    const handleRowSelect = (alertPeriod: AlertPeriod) => {
        props.setAlertPeriod(alertPeriod);
    };

    const geofenceAlertDays = [AlertPeriod.ALL, AlertPeriod.WEEKDAYS, AlertPeriod.WEEKENDS, AlertPeriod.MONDAY, AlertPeriod.TUESDAY, AlertPeriod.WEDNESDAY, AlertPeriod.THURSDAY, AlertPeriod.FRIDAY, AlertPeriod.SATURDAY, AlertPeriod.SUNDAY]

    const getAlertDayText = (alertPeriod: AlertPeriod) => {
        switch (alertPeriod) {
            case AlertPeriod.ALL:
                return t("days.all");
            case AlertPeriod.WEEKDAYS:
                return t("days.weekdays");
            case AlertPeriod.WEEKENDS:
                return t("days.weekends");
            case AlertPeriod.MONDAY:
                return t("days.monday");
            case AlertPeriod.TUESDAY:
                return t("days.tuesday");
            case AlertPeriod.WEDNESDAY:
                return t("days.wednesday");
            case AlertPeriod.THURSDAY:
                return t("days.thursday");
            case AlertPeriod.FRIDAY:
                return t("days.friday");
            case AlertPeriod.SATURDAY:
                return t("days.saturday");
            case AlertPeriod.SUNDAY:
                return t("days.sunday");
        }
    }

    return (
        <SelectStringTable
            loading={false}
            header={t("event.day")}
            values={geofenceAlertDays.map(period => getAlertDayText(period))}
            selectedRows={[getAlertDayText(props.alertPeriod)]}
            setSelectedRows={(selectedRows: string[]) => handleRowSelect(geofenceAlertDays.find(period => getAlertDayText(period) === selectedRows[0])!)}
        />
    )
}
