import {Box, Grid, InputAdornment, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {Asset} from "../../../hooks/assets/dto/Asset";
import {AssetSortOptions, sortByDriverName, sortByLastEventTime} from "../../../utils/AssetArraySorting";
import {useAssetApi} from "../../../hooks/assets/Assets";
import {FilterSortContext} from "../../../providers/FilterSortProvider";
import {SelectorLabel} from "./SelectorLabel";
import SelectAssetTable from "../../../components/table/shared/SelectAssetTable";
import {filterGenerator} from "../../../utils/AssetUtils";

interface AssetSelectorProps {
    singleReport: boolean;
    filterGenerator: boolean;
    selectedAssets: number[];
    setSelectedAssets: (selectedAssets: number[]) => void;
}

export function AssetSelector(props: AssetSelectorProps) {

    const { t } = useTranslation();
    const { assets, loading} = useAssetApi({shouldLoadAll:true});
    const [assetFilter, setAssetFilter] = React.useState<string | undefined>(undefined);
    const { sort } = useContext(FilterSortContext);

    function onSearchChange(search: string){
        if (search === undefined || search === "") {
            setAssetFilter(undefined);
        } else {
            setAssetFilter(search);
        }
    }

    const filterAssets = (assets: Array<Asset>) => {
        let filteredAssets = assets

        if(props.filterGenerator){
            filteredAssets = filterGenerator(filteredAssets);
        }

        if (assetFilter) {
            let lowerCaseFilter = assetFilter.toLowerCase();
            filteredAssets = assets.filter(asset => {
                if (asset) {
                    // Check for asset properties and filter accordingly
                    if (asset.alias && asset.alias.toLowerCase().includes(lowerCaseFilter)) {
                        return true;
                    }
                    if (asset.identificationMark && asset.identificationMark.toLowerCase().includes(lowerCaseFilter)) {
                        return true;
                    }
                    if (asset.assignee && asset.assignee.name && asset.assignee.name.toLowerCase().includes(lowerCaseFilter)) {
                        return true;
                    }
                    if (asset.groupName && asset.groupName.toLowerCase().includes(lowerCaseFilter)) {
                        return true;
                    }
                    if (asset.type && asset.type.toLowerCase().includes(lowerCaseFilter)) {
                        return true;
                    }
                }
                return false; // Return false for assets that don't match the filter
            });
        }

        switch (sort) {
            case AssetSortOptions.DRIVER_NAME:
                filteredAssets.sort(sortByDriverName);
                break;
            case AssetSortOptions.LAST_REPORTED:
                filteredAssets.sort(sortByLastEventTime);
                break;
            default:
                break;
        }

        return filteredAssets;
    }


    return <Grid container>
        <SelectorLabel
            title={t("report.asset_selection")}
            subtitle={t("report.asset_selection_message")}
        />
        <Grid item xs={12} md={8} lg={10} padding="20px">
            <Box sx={{
                paddingBottom: '10px'
            }}>
                <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    label={t("report.asset_search_box_label")}
                    variant="outlined"
                    color="info"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    value={assetFilter}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        onSearchChange(event.target.value);
                    }}
                />
            </Box>
            <Grid>
                <SelectAssetTable multiselect={!props.singleReport} loading={loading} assets={filterAssets(assets || [])} selectedRows={props.selectedAssets}
                                  setSelectedRows={props.setSelectedAssets} />
            </Grid>

        </Grid>
    </Grid>
}
