import {useEffect, useState} from "react";
import usePlatformFetch from "../shared/PlatformFetch";
import {VehicleEvent} from "../../enum/VehicleEvent";
import PropertiesLoader from "../../properties/PropertiesLoader";

const properties = PropertiesLoader();


export class EventAlert {
    id: number;
    vehicleEventName: VehicleEvent;
    startTime: string;
    endTime: string;
    vehicleId: number;
    emailAddress: String[];
    mon: boolean;
    tue: boolean;
    wed: boolean;
    thu: boolean;
    fri: boolean;
    sat: boolean;
    sun: boolean;

    constructor(props: {
        id: number;
        vehicleEventName: VehicleEvent;
        startTime: string;
        endTime: string;
        vehicleId: number;
        emailAddress: String[];
        mon: boolean;
        tue: boolean;
        wed: boolean;
        thu: boolean;
        fri: boolean;
        sat: boolean;
        sun: boolean;
    }) {
        this.id = props.id;
        this.vehicleEventName = props.vehicleEventName;
        this.startTime = props.startTime;
        this.endTime = props.endTime;
        this.vehicleId = props.vehicleId;
        this.emailAddress = props.emailAddress;
        this.mon = props.mon;
        this.tue = props.tue;
        this.wed = props.wed;
        this.thu = props.thu;
        this.fri = props.fri;
        this.sat = props.sat;
        this.sun = props.sun;
    }
}

interface EventAlertsApiOptions {
    shouldLoadAll?: boolean;
    shouldPoll?: boolean;
    pollFrequency?: number;
}
export function useEventAlertsApi(props: EventAlertsApiOptions = {}) {
    const {platformFetch, checkCreated, checkOk} = usePlatformFetch();

    const [eventAlerts, setEventAlerts] = useState<EventAlert[] | null>(null);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const getAllEventAlerts = async () => {
        return new Promise((resolve, reject) => {
            setLoading(true)
            platformFetch(
                properties.platformApiEndpoint + "tracking/v1/alerts/event-alerts",
                'get')
                .then(checkOk)
                .then(response => response.json())
                .then(alerts => {
                    setEventAlerts(alerts)
                    setLoading(false)
                    resolve(alerts)
                })
                .catch((error) => {
                    console.error("Error getting event alerts: " + error)
                    setError(error)
                    setLoading(false)
                    reject(error)
                });
        });
    }

    const createEventAlert = async (eventAlertRequest: EventAlert) => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + "tracking/v1/alerts/event-alerts",
                'post',
                JSON.stringify(eventAlertRequest))
                .then(checkCreated)
                .then(response => response.json())
                .then(alert => resolve(alert))
                .catch((error) => {
                    console.error("Error creating event alerts: " + error)
                    reject(error)
                });
        });
    }

    const updateEventAlerts = async (eventAlertRequest: EventAlert) => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `tracking/v1/alerts/event-alerts/${eventAlertRequest.id}`,
                'put',
                JSON.stringify(eventAlertRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(alert => resolve(alert))
                .catch((error) => {
                    console.error("Error updating event alerts: " + error)
                    reject(error)
                });
        });
    }

    const deleteEventAlert = async (eventAlertId: string) => {
        return new Promise<void>((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `tracking/v1/alerts/event-alerts/${eventAlertId}`,
                'delete')
                .then(checkOk)
                .then(alert => resolve())
                .catch((error) => {
                    console.error("Error deleting event alerts: " + error)
                    reject(error)
                });
        });
    }


    useEffect(() => {
        if (props.shouldLoadAll) {
            getAllEventAlerts();
            if (props.shouldPoll) {
                const interval = setInterval(getAllEventAlerts, props.pollFrequency || 30000);
                return () => clearInterval(interval);
            }
        }
    }, []);

    return {loading, error, eventAlerts, getAllEventAlerts, createEventAlert, updateEventAlerts, deleteEventAlert}
}
