import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Checkbox, Paper, Table, TablePagination, useTheme} from "@mui/material";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import {Account} from "../../../hooks/account/Account";
import {colors} from "../../../themes/AppTheme";

interface ImpersonateAccountTableProps {
    accounts: Account[];
    selectedRow: number | undefined;
    setSelectedRow: (selectedRow: number) => void;
    currentPage: number;
    totalElements: number;
    handleChangePage: (newPage: number) => void;
}

export default function ImpersonateAccountTable(props: ImpersonateAccountTableProps) {
    const {t} = useTranslation();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleRowSelect = (accountId: number) => {
        props.setSelectedRow(accountId);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        props.handleChangePage(newPage);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const theme = useTheme();

    //Directs to the first page to see the search made in the search bar
    useEffect(() => {
        setPage(0);
    }, [props.accounts]);

    return (
        <TableContainer id="impersonate-account-table-panel" component={Paper} sx={{height: '100%'}}>
            <Table aria-label="simple table" size={"small"}>
                <TableHead sx={{backgroundColor: colors.ram_light_blue}}>
                    <TableRow>
                        <TableCell/>
                        <TableCell>{t("impersonate_page.name")}</TableCell>
                        <TableCell>{t("impersonate_page.salesforce_id")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.accounts.slice(startIndex, endIndex).map((account) => (
                        <TableRow
                            key={account.id + "-selection-row"}
                            sx={{
                                backgroundColor: theme.palette.contentPage.listBackground,
                                '&:hover': {backgroundColor: theme.palette.contentPage.listHoverBackground,},
                                '&:last-child td, &:last-child th': {border: 0}
                            }}
                        >
                            <TableCell>
                                <Checkbox
                                    key={account + "-selection-checkbox"}
                                    checked={props.selectedRow === account.id}
                                    onChange={() => handleRowSelect(account.id)}
                                />
                            </TableCell>
                            <TableCell>{account.name}</TableCell>
                            <TableCell>{account.salesforceId}</TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination count={props.totalElements}
                             page={props.currentPage}
                             rowsPerPage={rowsPerPage}
                             onPageChange={handleChangePage}
                             rowsPerPageOptions={[10]}
                             onRowsPerPageChange={handleChangeRowsPerPage}
                             ActionsComponent={TablePaginationActions}/>
        </TableContainer>
    );
}
