
import { Box, Typography } from '@mui/material';

interface ListSectionHeadingProps {
  title: String;
}

function ListSectionHeading(props: ListSectionHeadingProps) {
  return (
    <Box
      sx={{
        backgroundColor: "tertiary.main",
        borderTop: 1,
        borderBottom: 1,
        borderColor: "divider",
        paddingLeft: "10px",
        paddingTop: "10px",
        paddingBottom: "10px",
      }}
    >
      <Typography
        sx={{ display: "block", width: "100%" }}
        component="div"
        variant="subtitle2"
        color="text.primary"
      >
        {props.title}
      </Typography>
    </Box>
  );
}

export default ListSectionHeading;