import React, {useEffect, useState} from 'react';
import {SearchResults, useSearchApi} from "../../../hooks/search/Search";
import SearchInput from "./components/SearchInput";
import SearchResultsPanel from "./components/SearchResultsPanel";
import {useTranslation} from "react-i18next";
import ReactGA from "react-ga4";

interface SearchBarProps {
}

const SearchBar = (props: SearchBarProps) => {
    const { t } = useTranslation();
    const [searchInput, setSearchInput] = useState('');
    const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined);
    const { performSearch } = useSearchApi();
    const [searchResults, setSearchResults] = useState<SearchResults | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(async () => {
            try {
                if(searchInput !== ''){
                    ReactGA.event("search",  {search_term: searchInput});
                    const results = await performSearch(searchInput);
                    setSearchResults(results);
                }
            } catch (e) {
                setSearchResults({assets: [], postcodes: [], geofences: []});
            } finally {
                setLoading(false);
            }
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [searchInput])

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            <SearchInput
                placeholder={t("search_bar.search_placeholder")}
                value={searchInput}
                onChange={(event) => {
                    if(!open) {
                        setAnchorEl(event.currentTarget);
                    }
                    setSearchInput(event.target.value)
                }}
                onClick={handleClick} />

            <SearchResultsPanel
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                searchEmpty={searchInput === ''}
                updateSearchInput={setSearchInput}
                loading={loading}
                results={searchResults}
                />
        </div>
    );
};

export default SearchBar;


