import { Box } from "@mui/material";

import { ReactComponentElement, useRef } from "react";
import { Map } from "leaflet";
import GeofencesPanel from "./components/panel/GeofencesPanel";
import { GeofenceWizardProvider } from "../../providers/GeofenceWizardProvider";
import GeofencesMapContainer from "./components/map/GeofencesMapContainer";
import { useGeofencesApi } from "../../hooks/geofences/Geofences";

interface GeofencesPageProps {
  subview?: ReactComponentElement<any>;
}

function GeofencesPage(this: any, props: GeofencesPageProps) {
  const mapRef = useRef<Map>(null);
  const { geofences, getAllGeofences, loading } = useGeofencesApi(false);

  return (
    <>
      <GeofenceWizardProvider>
        <Box sx={{ minWidth: "350px", padding: "0" }}>
          <GeofencesPanel mapref={mapRef} loading={loading} geofences={geofences || []} updateGeofences={getAllGeofences} />
        </Box>
        <GeofencesMapContainer mapref={mapRef} geofences={geofences || []} />
      </GeofenceWizardProvider>
    </>
  );
}

export default GeofencesPage; 
