import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { colors } from "../../../themes/AppTheme";
import { ReportDaysCovered } from "../../../enum/ReportDaysCovered";

interface ReportDaysCoveredSelectionProps {
    selectedReportDaysCovered: number;
    setSelectedReportDaysCovered: (selectedReportDaysCovered: ReportDaysCovered) => void;
}

export default function ReportDaysCoveredSelection(props: ReportDaysCoveredSelectionProps) {
    const { t } = useTranslation();

    function handleReportDaysCoveredChange(event: SelectChangeEvent<any>) {
        props.setSelectedReportDaysCovered(event.target.value);
    }

    const menuItems = [];
    for (let i = 1; i <= 31; i++) {
        menuItems.push(
            <MenuItem key={`event-type-${i}`} style={{ height: 25, fontSize: '12px' }} value={i}>
                {t(`scheduled_report.${i}_days`)}
            </MenuItem>
        );
    }

    return (
        <Select
            id="file_format"
            size="small"
            value={props.selectedReportDaysCovered}
            color="info"
            style={{ height: '100%', fontSize: '12px', color: colors.ram_purple }}
            onChange={handleReportDaysCoveredChange}
            fullWidth
        >
            {menuItems}
        </Select>
    );
}
