import { Box, Grid, Radio, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SelectorLabel } from "../../reports/shared/SelectorLabel";

export interface ChangeRegistrationStyle {
    name: string;
    description: string;
}

export const VEHICLE_FOR_FIRST_TIME = {
    name: "change_registration.vehicle_for_first_time",
    description: "change_registration.vehicle_for_first_time_info"
};

export const TRACKER_HAS_MOVED = {
    name: "change_registration.tracker_has_moved",
    description: "change_registration.tracker_has_moved_info"
};

export const REGISTRATION_HAS_CHANGED = {
    name: "change_registration.registration_has_changed",
    description: "change_registration.registration_has_changed_info"
};

export const REGISTRATION_REPORT_GROUPS = [VEHICLE_FOR_FIRST_TIME, TRACKER_HAS_MOVED, REGISTRATION_HAS_CHANGED];


interface ChangeRegistrationSelectorProps {
    availableChangeRegistrationGroup: ChangeRegistrationStyle[];
    selectedChangeRegistration: ChangeRegistrationStyle;
    setSelectedChangeRegistration: (selectedChangeRegistration: ChangeRegistrationStyle) => void;
}

export function ChangeRegistrationSelector(props: ChangeRegistrationSelectorProps) {

    const {t} = useTranslation();

    return <Grid container>
        <SelectorLabel
            title={t('change_registration.reason_for_change')}
            subtitle={t('change_registration.enter_reason_for_change')}
        />
        <Grid item xs={12} md={12} lg={10} padding="20px">
            <Grid container spacing={2} flexDirection="row" paddingTop={"10px"} paddingLeft={"15px"}>
                {props.availableChangeRegistrationGroup.map((group) => (
                    <Grid item xs={12} md={4} lg={4}>
                        <Box
                            border={2}
                            borderRadius={3}
                            borderColor={"divider"}
                            padding={2}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="space-between"
                            onClick={() => props.setSelectedChangeRegistration(group)}
                            sx={{
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <Box>
                                <Typography align={"center"} variant="title">
                                    {t(group.name)}
                                </Typography>
                                <Typography align={"center"} variant="body">
                                    {t(group.description)}
                                </Typography>
                            </Box>
                            <Box sx={{alignSelf: "center"}}>
                                <Radio
                                    checked={props.selectedChangeRegistration === group}
                                />
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    </Grid>
}