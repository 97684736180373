import {Box} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {RowDeleteButton, RowEditButton} from "../../../components/table/core/TableButtons";
import {DistanceAlert, DistanceAlertRequest, useDistanceAlertsApi} from "../../../hooks/alerts/DistanceAlerts";
import {useAssetApi} from "../../../hooks/assets/Assets";
import {useNavigate} from "react-router-dom";
import ConfirmationDialog from "../../../components/dialog/ConfirmationDialog";
import {Table, TableCell, TableRow} from "../../../components/table/core/Table";
import FilterBar from "../../../components/filterbar/FilterBar";

export default function DistanceAlertsPage() {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const {assets} = useAssetApi({shouldLoadAll: true});
    const {distanceAlerts, getAllDistanceAlerts, loading} = useDistanceAlertsApi({shouldLoadAll: true})
    const [searchInput, setSearchInput] = React.useState<string>("");

    const getIdentificationMark = (id: number): string => {
        const asset = assets?.find(asset => asset.id === id);
        if (asset) {
            if (asset.alias) {
                return asset.alias;
            } else if (asset.identificationMark) {
                return asset.identificationMark;
            }
        }
        return "";
    }

    function getFilteredAlerts() {
        if (distanceAlerts === null) {
            return [];
        } else if (searchInput === undefined || searchInput === "") {
            return distanceAlerts || [];
        } else {
            const lowerCaseFilter = searchInput.toLowerCase();
            return distanceAlerts.filter(alert =>
                alert.email.toLowerCase().includes(lowerCaseFilter) ||
                getIdentificationMark(alert.assetId).toLowerCase().includes(lowerCaseFilter)
            );

        }
    }

    const columns = [
        {id: 'asset', content: t('alert_table.asset')},
        {id: 'interval', content: t('alert_table.interval')},
        {id: 'notes', content: t('alert_table.notes')},
        {id: 'recipients', content: t('alert_table.recipients')},
        {id: 'action', content: t('alert_table.action')}
    ];

    const rows = getFilteredAlerts().map((alert) => {
        return <DistanceAlertRow key={alert.id} alert={alert} updateTrigger={getAllDistanceAlerts}
                                 getIdentificationMark={getIdentificationMark}/>
    })

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "space-between",
            margin: '30px',
            marginTop: '10px',
            height: '100%',
        }}>
            <FilterBar onSearchChange={setSearchInput} filter={searchInput} label={t('search_bar.search_for')}/>

            <Table
                id="distance-alerts"
                label="Distance Alerts"
                columns={columns}
                loading={loading}
                rows={rows}
                createAction={() => {
                    navigate('/alerts/create-distance-alerts')
                }}
                emptyLabel={t('alert_table.no_alerts') as string}
            />
        </Box>
    );
};

function DistanceAlertRow(props: { alert: DistanceAlert, updateTrigger: () => void, getIdentificationMark: (id: number) => string }) {
    const navigate = useNavigate()
    const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false)
    const [selectedAlertId, setSelectedAlertId] = React.useState<number | null>(null);
    const {t} = useTranslation();
    const {updateDistanceAlert} = useDistanceAlertsApi()

    async function handleDelete() {
        await updateDistanceAlert(new DistanceAlertRequest({
            assetId: selectedAlertId as number,
            alertInterval: 0,
            notes: '',
            email: ''
        }), selectedAlertId as number).catch((error) => {
            alert(t('alert_delete.failed'))
        })

        setSelectedAlertId(null);
        setShowDeleteDialog(false);
        props.updateTrigger();
    }

    return <TableRow>
        <TableCell>
            {props.getIdentificationMark(props.alert.assetId)}
        </TableCell>
        <TableCell>
            {props.alert.alertInterval.toString()}
        </TableCell>
        <TableCell maxWidth="max-w-[200px]" collapsible={false}>
            {props.alert.notes}
        </TableCell>
        <TableCell>
            {props.alert.email}
        </TableCell>

        <td className="px-6 py-4">
            <RowEditButton action={() => {
                const selectedAlert = props.alert;
                navigate('/alerts/update-distance-alerts', {state: {selectedAlert}});
            }}/>
            <RowDeleteButton action={() => {
                setSelectedAlertId(props.alert.id);
                setShowDeleteDialog(true);
            }}/>
            <ConfirmationDialog open={showDeleteDialog}
                                title={t('alerts_page.distance_alerts_page.delete_dialog.title')}
                                description={t('alerts_page.distance_alerts_page.delete_dialog.description')}
                                confirmText={t('button.delete')} cancelText={t('button.cancel')}
                                onConfirm={handleDelete}
                                onCancel={() => setShowDeleteDialog(false)}/>
        </td>
    </TableRow>
}
