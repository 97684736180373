import ReactLeafletRightClick, { useLeafletRightClick } from "react-leaflet-rightclick";
import {useTranslation} from "react-i18next";

interface MapContextMenuProps {
}

function MapContextMenu(props: MapContextMenuProps) {
    const eventContextMenu = useLeafletRightClick();
    const {t} = useTranslation();
    return (
        <ReactLeafletRightClick
            customComponent={
                <div
                    className={"bg-base-100 rounded-lg w-[120px]"}
                >
                    <button
                        className={"w-full p-2 text-left text-link hover:underline"}
                        onClick={() => {
                            window.open('http://maps.google.com/maps?q=&layer=c&cbll=' + eventContextMenu?.latlng.lat + ',' + eventContextMenu?.latlng.lng + '', 'name', 'width=800,height=600')
                            console.log(eventContextMenu)
                        }}>
                        {t('asset_card.btn_street_view')}
                    </button>
                    <button
                        className={"w-full p-2 text-left text-link hover:underline"}
                        onClick={() => {
                            window.open('https://www.google.com/maps/dir/?api=1&destination=' + eventContextMenu?.latlng.lat + ',' + eventContextMenu?.latlng.lng + '', 'name', 'width=800,height=600')
                            console.log(eventContextMenu)
                        }}>
                        {t('asset_card.btn_directions')}
                    </button>
                </div>
            }
        />
    );
}

export default MapContextMenu;
