import * as ReactDOMServer from 'react-dom/server';
import L from 'leaflet'
import {Marker, Tooltip} from 'react-leaflet';
import { Asset} from '../../../hooks/assets/dto/Asset';

import WarningIcon from '@mui/icons-material/Warning';

import './AssetMarkerTooltip.css'
import { Typography } from '@mui/material';
import AssetMarkerPopup from './AssetMarkerPopup';
import { doesAssetHaveProblem } from '../../../utils/AssetUtils';
import { SvgMarkerIcon } from '../../../components/svgmarkericon/SvgMarkerIcon';
import {Dispatch, memo, SetStateAction} from "react";

interface AssetMarkerProps {
    strokeColor: string;
    showLabel: boolean;
    labelText: String;
    asset: Asset;
    selectedAsset: string | undefined;
    setSelectedAsset: Dispatch<SetStateAction<string | undefined>>;
    disableClick?: boolean;
}

function AssetMarker(props: AssetMarkerProps) {
    const type = props.asset.type;
    const status = props.asset.status

    const latestEvent = props.asset.latestEvent!;
    const latitude = latestEvent.location.rawCoordinates.latitude;
    const longitude = latestEvent.location.rawCoordinates.longitude;
    const heading = latestEvent.heading;

    const svg = SvgMarkerIcon(props.strokeColor, type, status, heading)
    const customMarkerIcon = L.divIcon({
        html: ReactDOMServer.renderToString(svg),
        iconSize: [40, 40],
        iconAnchor: [20, 20],
        popupAnchor: [0, -20],
        className: "none"
    });

    return (
        <Marker
            key={props.asset.id + '-marker'}
            position={{ lat: latitude, lng: longitude }}
            icon={customMarkerIcon}
        >
            {props.showLabel ? (
                <Tooltip direction="top" offset={[0, -20]} opacity={1} permanent>
                    <Typography
                        component="span"
                        variant="cardbody"
                        color="text.primary"
                        sx={{ fontWeight: 'bold' }}>
                        {props.labelText}
                        {doesAssetHaveProblem(props.asset) ?
                            <WarningIcon fontSize='inherit' sx={{ paddingTop: '2px', marginLeft: '5px', color: 'orange' }} /> :
                            <></>}

                    </Typography>
                </Tooltip>
            ) : null}
            {!props.disableClick &&
                <AssetMarkerPopup
                    key={props.asset.id + '-popup-container'}
                    asset={props.asset}
                    selectedAsset={props.selectedAsset}
                    setSelectedAsset={props.setSelectedAsset}
                />
            }
        </Marker>
    );
}

export default memo(AssetMarker, (prevProps, nextProps) => {
    return prevProps.showLabel === nextProps.showLabel &&
        prevProps.strokeColor === nextProps.strokeColor &&
        prevProps.labelText === nextProps.labelText &&
        prevProps.asset === nextProps.asset;
});