import {Feature, isFeatureInPackage} from "../../utils/FeatureRestrictionUtils";
import {FeaturePackage} from "../../hooks/account/Account";
import * as React from "react";
import FeatureRestrictionPage from "../restrictions/FeatureRestrictionPage";
import {useTranslation} from "react-i18next";

interface PackageRestrictedContentProps {
    feature: Feature;
    featurePackage: FeaturePackage;
    page: React.ReactNode;
    restrictionPageTitleKey: string;
    restrictionPageDescriptionKey: string;
}

export function PackageRestrictedContent(props: PackageRestrictedContentProps) {
    const {t} = useTranslation();

    if (isFeatureInPackage(props.feature, props.featurePackage)) {
        return <>{props.page}</>;
    } else {
        return <FeatureRestrictionPage
            title={t(props.restrictionPageTitleKey)}
            description={t(props.restrictionPageDescriptionKey)}
        />
    }
}