import {AssetEvent, Speed} from "../hooks/assets/dto/Asset";
import {mapToVehicleEventTranslation} from "./TranslationMapper";

export function getTranslatedEventName(event: AssetEvent, t: (key: string) => string) {
    return event.customEventName ?
        event.customEventName : mapToVehicleEventTranslation(event.type.toString()) ?
            t('vehicle_event.' + mapToVehicleEventTranslation(event.type.toString())) : event.type
}


export function isWarningEvent(speed: Speed | undefined, speedLimit: Speed | undefined, eventType: String | undefined) {
    return isSpeeding(speed, speedLimit) || isHarshEvent(eventType);
}

function isSpeeding(speed: Speed | undefined, speedLimit: Speed | undefined) {
    return speed && speedLimit && speedLimit.value !== 0 && speed.value > speedLimit.value;
}

function isHarshEvent(eventType: String | undefined) {
    return eventType?.includes("HARSH");
}
