
import { ListItemButton, Typography } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

interface AssetsListSectionHeadingProps {
  title: String;
  titleColor: string;
  numberOfAssets: number;
  open: boolean;
  onClick: Function;
}

function AssetsListSectionHeading(props: AssetsListSectionHeadingProps) {
  return (
    <ListItemButton
      onClick={() => props.onClick()}
      sx={{
        backgroundColor: "tertiary.main",
        borderTop: 1,
        borderBottom: 1,
        borderColor: "divider",
        paddingLeft: "10px",
        paddingTop: "10px",
        paddingBottom: "10px",
        color: props.titleColor
      }}
      disabled={props.numberOfAssets === 0}
    >
      <Typography
        sx={{ color: props.titleColor, display: "block", width: "100%" }}
        component="div"
        variant="subtitle2"
      >
        {"● " + props.title + " (" + props.numberOfAssets + ")"}
      </Typography>
      {props.numberOfAssets !== 0 ? props.open ? <ExpandLess fontSize='small' /> : <ExpandMore /> : <></>}
    </ListItemButton>
  );
}

export default AssetsListSectionHeading;