import { useTranslation } from "react-i18next";
import {Checkbox} from "@mui/material";
import { Geofence } from "../../../../hooks/geofences/dto/Geofence";
import {Table, TableCell, TableRow} from "../../../../components/table/core/Table";

interface GeofenceAlertGeofenceNamesTableProps {
    loading: boolean;
    geofences: Geofence[] | null;
    selectedRows: number[];
    setSelectedRows: (selectedRows: number[]) => void;
}

export default function GeofenceAlertGeofenceNamesTable(props: GeofenceAlertGeofenceNamesTableProps) {
    const { t } = useTranslation();

    const handleRowSelect = (geofenceId: number) => {
        props.setSelectedRows([geofenceId]);
    };

     const columns = [
         { id:'select', content: <></> },
         { id:'geofence', content: t("event.geofence_name") }
     ]

    const rows = props.geofences?.map((value) => {
        return <TableRow key={`geofence=${value.id}`}>
            <TableCell>
                <Checkbox
                    id={`select-geofence-${value.id}`}
                    checked={props.selectedRows.includes(value.id!)}
                    onChange={() => handleRowSelect(value.id!)}
                />
            </TableCell>
            <TableCell>{value.name}</TableCell>
        </TableRow>
    });

     return (
         <Table
             loading={props.loading}
             id="geofence-alert-recipient-table-panel"
             label={t("event.geofence_name")}
             columns={columns}
             rows={rows || []}
             disableCreateButton={true}
         />
     );
}