import { Feature } from 'geojson';

export class Geofence {
    id?: number;
    name: string;
    category?: GeofenceCategory;
    feature: Feature;
    colour: string;
    deletedAt?: Date;

    constructor(props: {
        id?: number;
        name: string;
        category?: GeofenceCategory;
        feature: Feature,
        colour: string,
        deletedAt?: Date;
    }) {
        this.id = props.id;
        this.name = props.name;
        this.category = props.category;
        this.feature = props.feature;
        this.colour = props.colour;
        this.deletedAt = props.deletedAt;
    }
}

export class GeofenceCategory {
    id: number;
    name: string;

    constructor(props: {
        id: number;
        name: string;
    }) {
        this.id = props.id;
        this.name = props.name;
    }
}
