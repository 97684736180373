import {Checkbox, Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Table, TableCell, TableRow} from "../core/Table";

interface SelectStringTableProps {
    loading: boolean;
    header: string;
    values: string[];
    multiselect?: boolean;
    disableSelect?: boolean;
    inOrder?: boolean; // Optional flag to enable/disable sorting
    selectedRows: string[];
    setSelectedRows: (selectedRows: string[]) => void;
}

export default function SelectStringTable(props: Readonly<SelectStringTableProps>) {
    const {t} = useTranslation();

    const handleRowSelect = (value: string) => {
        if (props.multiselect) {
            if (props.selectedRows.includes(value)) {
                props.setSelectedRows(props.selectedRows.filter(selected => selected !== value));
            } else {
                props.setSelectedRows([...props.selectedRows, value]);
            }
        } else {
            props.setSelectedRows([value]);
        }
    };

    const handleSelectAllValues = () => {
        if (props.values.length === props.selectedRows.length) {
            props.setSelectedRows([]);
        } else {
            props.setSelectedRows(props.values);
        }
    };

    const selectHeader = props.multiselect ? (
        <Tooltip title={t("select.all")}>
            <Checkbox
                id="select"
                disabled={props.disableSelect}
                checked={props.values.length === props.selectedRows.length}
                onChange={() => handleSelectAllValues()}
            />
        </Tooltip>) : <></>;

    const columns = [
        {id: 'select', content: selectHeader},
        {id: 'string-value', content: props.header}
    ];

    // Optionally sort the values if the inOrder prop is true
    const sortedValues = props.inOrder
        ? props.values.slice().sort((a, b) => a.localeCompare(b)) // Sort only if inOrder is true
        : props.values;

    const rows = sortedValues.map((value) => (
        <TableRow key={value}>
            <TableCell>
                <Checkbox
                    id={`select-${props.header}-${value}`}
                    disabled={props.disableSelect}
                    checked={props.selectedRows.includes(value)}
                    onChange={() => handleRowSelect(value)}
                />
            </TableCell>
            <TableCell>{value}</TableCell>
        </TableRow>
    ));

    return (
        <Table
            id={`select-${props.header}-table`}
            label={`select-${props.header}-table`}
            columns={columns}
            disableCreateButton={true}
            rows={rows}
            loading={props.loading}
        />
    );
}
