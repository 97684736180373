
export class AssetGroupRequest {
    label: string;

    constructor(props: {
        label: string;
    }) {
        this.label = props.label;
    }
}
