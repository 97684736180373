import {useEffect, useState} from "react";
import usePlatformFetch from "../shared/PlatformFetch";
import PropertiesLoader from "../../properties/PropertiesLoader";
import {LiveShareLink} from "./dto/LiveShareLink";
import {LiveShareRequest} from "./dto/LiveShareRequest";
import {mapToLiveShareLink} from "./LiveShareMapper";

const properties = PropertiesLoader();


export function useLiveshareApi(autoLoad: boolean, shouldPoll?: boolean, pollFrequency?: number) {
    const {platformFetch, checkOk} = usePlatformFetch();

    const [links, setLinks] = useState<LiveShareLink[] | null>(null);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const getAllLinks = async () => {
        return new Promise((resolve, reject) => {
            setLoading(true)
            platformFetch(
                properties.platformApiEndpoint + "tracking/v1/liveshare/links/",
                'get')
                .then(checkOk)
                .then(response => response.json())
                .then(links => {
                    setLinks(links)
                    setLoading(false)
                    resolve(links)
                })
                .catch((error) => {
                    console.error("Error getting liveshare links: " + error)
                    setError(error)
                    setLoading(false)
                    reject(error)
                });
        });
    }

    const createLink = async (liveShareRequest: LiveShareRequest): Promise<LiveShareLink> => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + "liveshare/v1/links",
                'post',
                JSON.stringify(liveShareRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(json => mapToLiveShareLink(json))
                .then(reportResponse => resolve(reportResponse))
                .catch((error) => {
                    console.error("Failed to create liveshare link: " + error)
                    setError(error)
                    reject(error)
                });
        });
    }

    const updateLink = async (liveShareRequest: LiveShareRequest, livesharelinkId: number): Promise<LiveShareLink> => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `liveshare/v1/links/${livesharelinkId}`,
                'put',
                JSON.stringify(liveShareRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(json => mapToLiveShareLink(json))
                .then(reportResponse => resolve(reportResponse))
                .catch((error) => {
                    console.error("Failed to update liveshare link: " + error)
                    setError(error)
                    reject(error)
                });
        });
    }

    const deleteLink = async (linkId: number) => {
        return new Promise<void>((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + "tracking/v1/liveshare/links/" + linkId,
                'delete')
                .then(checkOk)
                .then(() => {
                    resolve()
                })
                .catch((error) => {
                    console.error("Error deleting liveshare link: " + error)
                    setError(error)
                    reject(error)
                });
        });
    }

    useEffect(() => {
        if(autoLoad){
            getAllLinks();
        }
        if (shouldPoll) {
            const interval = setInterval(getAllLinks, pollFrequency ?? 30000);
            return () => clearInterval(interval);
        }
    }, []);

    return {loading, error, links, getAllLinks, createLink, updateLink, deleteLink};
}
