import { Box, List, Typography } from "@mui/material";
import { Asset } from "../../../../hooks/assets/dto/Asset";
//Icons
import ListSectionHeading from "../../../../components/leftpanel/ListSectionHeading";

import React from "react";
import { Journey } from "../../../../hooks/assets/dto/Journey";
import RouteReplayJourneyCard from "./RouteReplayJourneyCard";
import LoadingSpinner from "../../../../components/loadingspinner/LoadingSpinner";
import LeftPanelButtonBar from "../../../../components/leftpanel/LeftPanelButtonBar";
import { useTranslation } from "react-i18next";
import { colors } from "../../../../themes/AppTheme";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useAssetApi } from "../../../../hooks/assets/Assets";
import ReactGA from "react-ga4";

interface RouteReplayJourneySelectProps {
    asset: Asset;
    onBackPressed: Function;
    setJourney: React.Dispatch<React.SetStateAction<Journey | null>>;
    selectedDate: Dayjs;
    setSelectedDate: React.Dispatch<React.SetStateAction<Dayjs>>;
}


function NoJourneysMessage() {

    return (
        <>
            <Typography
                sx={{ display: "block", width: "100%" }}
                component="span"
                variant="cardbody"
                color="text.primary"
                padding={"10px"}
            >
                No journeys found for this date. Please select another date.
            </Typography>
        </>

    )
}

export default function RouteReplayJourneySelect(props: RouteReplayJourneySelectProps) {
    const { t } = useTranslation();
    const { getAssetJourneysOnDay } = useAssetApi({shouldLoadAll:true});
    const [loaded, setLoaded] = React.useState<boolean>(false)
    const [journeys, setJourneys] = React.useState<Journey[]>([])

    function handleBackPress(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {

        ReactGA.event("RT2-RouteReplay-BacktoVehicles");
        props.setSelectedDate(dayjs().startOf('date'));
        props.onBackPressed();
    }

    async function updateJourneys(journeyDate: Date | null) {
        if (journeyDate != null) {

            let fetchedJourneys = await getAssetJourneysOnDay(props.asset.id, journeyDate);
            if (!fetchedJourneys) {
                fetchedJourneys = [];
            }
            setJourneys(fetchedJourneys as Array<Journey>);
            setLoaded(true);
        }
    }

    React.useEffect(() => {
      
        updateJourneys(props.selectedDate.toDate());
    }, [props.selectedDate]);

    return (
        <Box component="main" sx={{
            flexGrow: 1, p: 0,
            height: 'calc(-67px + 100vh)', overflow: "hidden",
            overflowY: "scroll",
        }}>
            <List
                dense={true}
                sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
                <LeftPanelButtonBar onBackPressed={handleBackPress} />
                <Box sx={{ width: '100%' }}>
                    <Typography
                        sx={{ display: "block", width: "100%" }}
                        component="div"
                        variant="header"
                        padding={"10px"}
                    >
                        {props.asset.alias || props.asset.identificationMark}
                    </Typography>
                </Box>


                <Box sx={{ width: '100%' }}>
                    <ListSectionHeading title="Journeys" />
                    <Box sx={{ width: '100%', padding: '10px' }}>
                        <DatePicker
                            sx={{

                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                color: colors.ram_purple,
                            }}

                            disableFuture
                            label={t('timeline.datepicker_label')}
                            value={props.selectedDate}
                            format="DD/MM/YYYY"
                            slotProps={{ textField: { size: 'small' } }}
                            onChange={(newDate) => {
                                if (newDate != null) {
                                    ReactGA.event("RT2-RouteReplay-SelectDate");
                                    props.setSelectedDate(newDate)
                                } else {
                                    props.setSelectedDate(dayjs().startOf('date'))
                                }
                            }}
                        />
                    </Box>

                    {!loaded ? (<LoadingSpinner />) : (<></>)}

                    {loaded && journeys.length === 0 ? <NoJourneysMessage />
                        : journeys.map((journey) => {
                            return (<RouteReplayJourneyCard key={journey.id} journey={journey} clickedJourney={(journey: Journey) => {
                                props.setJourney(journey)
                            }} />)
                        }
                        )}
                </Box>
            </List>
        </Box>

    );
}
