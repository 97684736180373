import {MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {useTranslation} from "react-i18next";
import {colors} from "../../../../themes/AppTheme";
import {EngineHourAlertType} from "./EngineHourAlertTypes";

interface EngineHourAlertTypeSelectionProps {
    selectedType: EngineHourAlertType;
    setSelectedType: (selectedEventType: EngineHourAlertType) => void;
}

export default function EngineHourAlertTypeSelection(props: EngineHourAlertTypeSelectionProps) {
    const {t} = useTranslation();

    const handleTypeChange = (event: SelectChangeEvent<EngineHourAlertType>) => {
        props.setSelectedType(event.target.value as EngineHourAlertType);
    };

    // Get the  types and sort them based on translated values
    const types = Object.values(EngineHourAlertType).sort((a, b) => {
        const translationA = t(`engine_hour_alert_type.${a.toLowerCase()}`);
        const translationB = t(`engine_hour_alert_type.${b.toLowerCase()}`);
        return translationA.localeCompare(translationB);
    });

    return (
        <Select
            id="engine_hour_alert_type"
            size="small"
            value={props.selectedType}
            color="info"
            style={{height: '100%', fontSize: '12px', color: colors.ram_purple}}
            onChange={handleTypeChange}
            fullWidth
        >
            {types.map((type) => (
                <MenuItem
                    key={type}
                    style={{height: 25, fontSize: '12px'}}
                    value={type}
                >
                    {t(`engine_hour_alert.admin.type.${type.toLowerCase()}`)}
                </MenuItem>
            ))}
        </Select>
    );
}
