import { Box } from '@mui/material';

interface StepContainerProps {
    children: string | JSX.Element | JSX.Element[];
}

export default function StepContainer(props: StepContainerProps) {
    return (

        <Box display="flex" flexDirection="column" alignItems="Center"
            sx={{
                width: "100%",
                paddingTop: "40px",
            }}>

            <Box sx={{
                width: "40%",
                minWidth: "300px",
            }}
                alignItems="Center"
                textAlign="center">
                {props.children}
            </Box>
        </Box>
    );
};





