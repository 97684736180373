import { Box, Typography } from '@mui/material';
import { colors } from '../../../../themes/AppTheme';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface ProgressPanelItemProps {
    currentStep: boolean;
    completedStep: boolean;
    stepName: string;
    stepDescription: string;
}



export default function ProgressPanelItem(props: ProgressPanelItemProps) {
    function getIcon(currentStep: boolean, completedStep: boolean) {
        if (currentStep) {
            return <RadioButtonCheckedIcon
                fontSize='small'
                sx={{
                    marginTop: "5px",
                    marginRight: "5px",
                    color: colors.ram_orange
                }} />;
        } else if (completedStep) {
            return <CheckCircleOutlineIcon
                fontSize='small'
                sx={{
                    marginTop: "5px",
                    marginRight: "5px",
                    color: colors.ram_green
                }} />;
        } else {
            return <RadioButtonUncheckedIcon
                fontSize='small'
                sx={{
                    marginTop: "5px",
                    marginRight: "5px",
                    color: colors.ram_gray
                }} />;
        }
    }

    return (
        <Box
            display="flex"
            flexDirection="row"
            sx={{
                paddingBottom: "20px"
            }}>
            {getIcon(props.currentStep, props.completedStep)}
            <Box display="flex" flexDirection="column" alignItems="Left"
                sx={{
                }}>
                <Typography
                    sx={{
                        display: "block",
                        width: "100%",
                        paddingBottom: "0px",
                        color: props.currentStep || props.completedStep ? colors.ram_purple : "disabled.main",
                    }}
                    component="div"
                    variant="title"
                >
                    {props.stepName}
                </Typography>
                <Typography
                    sx={{
                        display: "block",
                        width: "100%",
                        color: props.currentStep || props.completedStep ? colors.ram_purple : "disabled.main",
                    }}
                    component="div"
                    variant="body"
                >
                    {props.stepDescription}
                </Typography>

            </Box>


        </Box>
    );
};





