import React, { createContext, useState, ReactNode, useContext } from 'react';
import { Asset } from '../hooks/assets/dto/Asset';


interface FilterAssetContextProps {
  filteredAssets?: Asset[];
  setFilteredAssets: React.Dispatch<React.SetStateAction<Asset[]>>;
}

// Create the context
export const FilterAssetContext = createContext<FilterAssetContextProps>({
    filteredAssets: undefined,
    setFilteredAssets: () => { },
});

// Create the provider component
export const FilterAssetProvider = ({ children }: { children: ReactNode }) => {
  const [filteredAssets, setFilteredAssets] = useState<Asset[]>([]);

  return (
    <FilterAssetContext.Provider value={{ filteredAssets, setFilteredAssets }}>
      {children}
    </FilterAssetContext.Provider>
  );
};

// Custom hook for consuming the context
export const useFilterAsset = () => {
  const context = useContext(FilterAssetContext);
  if (!context) {
    throw new Error('useFilterAsset must be used within a FilterAssetProvider');
  }
  return context;
};
