import { useTranslation } from "react-i18next";
import { Asset } from "../../../hooks/assets/dto/Asset";
import { Table, TableCell, TableRow } from "../core/Table";
import { getAssetName } from "../../../utils/AssetUtils";

interface DisplayAssetTableProps {
    loading: boolean;
    assets: Asset[];
    disabled?: boolean;
}

export default function DisplayAssetTable(props: Readonly<DisplayAssetTableProps>) {
    const { t } = useTranslation();

    const columns = [
        { id: 'driver', content: t('asset_table.driver') },
        { id: 'asset', content: t('asset_table.asset') },
        { id: 'group', content: t('asset_table.group') },
        { id: 'type', content: t('asset_table.type') }
    ];

    const rows = props.assets?.map(asset => (
        <TableRow key={asset.id}>
            <TableCell>{asset.assignee?.name || "-"}</TableCell>
            <TableCell>{getAssetName(asset)}</TableCell>
            <TableCell>{asset.groupName || "-"}</TableCell>
            <TableCell>{asset.type || "-"}</TableCell>
        </TableRow>
    )) || [];

    return (
        <Table
            id="select-asset-table"
            label="select-asset-table"
            columns={columns}
            disableCreateButton={true}
            rows={rows}
            loading={props.loading}
            disablePagination={true}
        />
    );
}