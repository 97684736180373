import {ReportType} from "../../../enum/ReportType";

export class ReportTypeResponse {
    id?: number;
    reportCode: ReportType;
    description: string;
    constructor(props: {
        id?: number;
        reportCode: ReportType;
        description: string
    }) {
        this.id = props.id;
        this.reportCode = props.reportCode;
        this.description = props.description;
    }
}
