import { useEffect, useState } from "react";
import usePlatformFetch from "../shared/PlatformFetch";
import PropertiesLoader from "../../properties/PropertiesLoader";
import { Geofence } from "./dto/Geofence";
import { mapToGeofenceArray, mapToGeofence } from "./GeofenceMapper";
const properties = PropertiesLoader();


export function useGeofencesApi(shouldPoll?: boolean, pollFrequency?: number) {
    const { platformFetch, checkOk } = usePlatformFetch();

    const [geofences, setGeofences] = useState<Geofence[] | null>(null);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const getAllGeofences = async () => {
        return new Promise((resolve, reject) => {
            setLoading(true)
            platformFetch(
                properties.platformApiEndpoint + "tracking/v1/geofence/",
                'get')
                .then(checkOk)
                .then(response => response.json())
                .then(json => mapToGeofenceArray(json))
                .then(geofences => {
                    setGeofences(geofences)
                    setLoading(false)
                    resolve(geofences)
                })
                .catch((error) => {
                    console.error("Error getting geofences: " + error)
                    setError(error)
                    setLoading(false)
                    reject(error)
                });
        });
    }

    const getGeofenceById = async (geofenceId: string) => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + "tracking/v1/geofence/" + geofenceId,
                'get')
                .then(checkOk)
                .then(response => response.json())
                .then(json => mapToGeofence(json))
                .then(geofence => resolve(geofence))
                .catch((error) => {
                    console.error("Failed to get geofence: " + error)
                    reject(error)
                });
        });
    }

    const getGeofenceCategories = async () => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + "tracking/v1/geofence/categories",
                'get')
                .then(checkOk)
                .then(response => response.json())
                .then(categories => resolve(categories))
                .catch((error) => {
                    console.error("Failed to get geofence categiories: " + error)
                    reject(error)
                });
        });
    }

    const createGeofence = async (geofence: Geofence) => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + "tracking/v1/geofence/",
                'post',
                JSON.stringify(geofence))
                .then(checkOk)
                .then(response => response.json())
                .then(json => mapToGeofence(json))
                .then(geofence => resolve(geofence))
                .catch((error) => {
                    console.error("Failed to create geofence: " + error)
                    reject(error)
                });
        });
    };

    const updateGeofence = async (geofence: Geofence) => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + "tracking/v1/geofence/",
                'put',
                JSON.stringify(geofence))
                .then(checkOk)
                .then(response => response.json())
                .then(json => mapToGeofence(json))
                .then(geofence => resolve(geofence))
                .catch((error) => {
                    console.error("Failed to update geofence: " + error)
                    reject(error)
                });
        });
    };


    const deleteGeofence = async (geofenceId: string) => {
        return new Promise<void>((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + "tracking/v1/geofence/" + geofenceId,
                'delete')
                .then(checkOk)
                .then(() => resolve())
                .catch((error) => {
                    console.error("Error deleting event alerts: " + error)
                    reject(error)
                });
        });
    }


    useEffect(() => {
        getAllGeofences();
        if (shouldPoll) {
            const interval = setInterval(getAllGeofences, pollFrequency || 30000);
            return () => clearInterval(interval);
        }
    }, []);

    return { loading, error, geofences, getAllGeofences, getGeofenceById, getGeofenceCategories, createGeofence, updateGeofence, deleteGeofence }
}

