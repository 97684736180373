import React, {createContext, useMemo, useState} from 'react';
import { AssetSortOptions } from '../utils/AssetArraySorting';

interface FilterSortContextProps {
    sort?: AssetSortOptions;
    setSort: React.Dispatch<React.SetStateAction<AssetSortOptions>>;
    groupFilter?: string;
    setGroupFilter: React.Dispatch<React.SetStateAction<string>>;
    typeFilter?: string;
    setTypeFilter: React.Dispatch<React.SetStateAction<string>>;
}

export const FilterSortContext = createContext<FilterSortContextProps>({
    sort: undefined,
    setSort: () => { },
    groupFilter: undefined,
    setGroupFilter: () => { },
    typeFilter: undefined,
    setTypeFilter: () => { },
});

interface Props {
    children: React.ReactNode;
}

export const FilterSortProvider = ({ children }: Props) => {
    const [sort, setSort] = useState<AssetSortOptions>(AssetSortOptions.DEFAULT);
    const [groupFilter, setGroupFilter] = useState<string>("all");
    const [typeFilter, setTypeFilter] = useState<string>("all");
    const contextValue: FilterSortContextProps = useMemo(
        () => ({
            sort,
            setSort,
            groupFilter,
            setGroupFilter,
            typeFilter,
            setTypeFilter,
        }),
        [sort, setSort, groupFilter, setGroupFilter, typeFilter, setTypeFilter]
    );
    return (
        <FilterSortContext.Provider value={contextValue}>
            {children}
        </FilterSortContext.Provider>
    );
};