import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReportDeliveryDay } from "../../../enum/ReportDeliveryDay";
import { colors } from "../../../themes/AppTheme";

interface ReportDeliveryDaySelectionProps {
    selectedReportDeliveryDay: string;
    setSelectedReportDeliveryDay: (selectedReportDeliveryDay: ReportDeliveryDay) => void;
}

export default function ReportDeliveryDaySelection(props: ReportDeliveryDaySelectionProps) {
    const { t } = useTranslation();

    function handleReportDeliveryDayChange(event: SelectChangeEvent<any>) {
        props.setSelectedReportDeliveryDay(event.target.value);
    }

    return (
        <Select
            id="file_format"
            size="small"
            value={props.selectedReportDeliveryDay}
            color="info"
            style={{ height: '100%', fontSize: '12px', color: colors.ram_purple }}
            onChange={handleReportDeliveryDayChange}
            fullWidth
        >
            {Object.values(ReportDeliveryDay).map((day, index) => (
                <MenuItem
                    key={`delivery-day-${day}`}
                    style={{ height: 25, fontSize: '12px' }}
                    value={day}
                >
                    {t(`scheduled_report.${day.toLowerCase()}`)}
                </MenuItem>
            ))}
        </Select>
    );
}
