import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import { useTranslation } from 'react-i18next';
import AppBarButton from '../AppBarButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationItem from './NotificationItem';
import LoadingSpinner from '../../loadingspinner/LoadingSpinner';
import { Button } from '@mui/material';
import { Notification } from '../../../hooks/notifications/dto/Notification';
import NotificationsEmpty from './NotificationsEmpty';
import { colors } from "../../../themes/AppTheme";
import { useNotificationsApi } from '../../../hooks/notifications/Notifications';
import ReactGA from "react-ga4";

export default function NotificationMenu() {
    const { t } = useTranslation();
    const { deleteAllNotifications, deleteNotification, getNotifications, getNotificationCount } = useNotificationsApi();
    const [loading, setLoading] = React.useState<boolean>(true);
    const [notificationCount, setNotificationCount] = React.useState<number>(0);
    const [notifications, setNotifications] = React.useState<Notification[]>([])


    useEffect(() => {
        // Call the function initially when the component mounts
        loadNotificationCount();

        // Set up an interval to call the function every minute (60000 milliseconds)
        const intervalId = setInterval(loadNotificationCount, 60000);

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    async function loadNotificationCount() {
        try {
            const count: number = await getNotificationCount();
            setNotificationCount(count);
        } catch (error) {
            console.log("Failed to update notifications count: " + error);
        }
    }

    async function loadNotifications() {
        try {
            let loadedNotifications: Notification[] = await getNotifications() as Notification[];
            if (!loadedNotifications) {
                loadedNotifications = []
            }
            setNotifications(loadedNotifications)
            setNotificationCount(0)

            setLoading(false)
        } catch (error) {
            console.log("Failed to update notifications: " + error)
            setLoading(false)
        }
    }

    async function handleDeleteNotification(id: number) {
        setLoading(true)
        try {
            await deleteNotification(id);
            loadNotifications();
        } catch (error) {
            console.log("Failed to delete notification: " + error)
            setLoading(false)
        }
    }

    async function handleDeleteAllNotifications() {
        setLoading(true)
        try {
            await deleteAllNotifications();
            loadNotifications();
        } catch (error) {
            console.log("Failed to delete all notifications: " + error)
            setLoading(false)
        }
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {

        ReactGA.event("RT2-OpenNotifications");


        setAnchorEl(event.currentTarget);
        setLoading(true);
        loadNotifications();
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>

            <div style={{ position: 'relative' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center' }}>
                    <AppBarButton
                        id="notification-menu-button"
                        icon={<NotificationsIcon />}
                        onClick={handleClick}
                        ariaControls={open ? 'notification-menu' : undefined}
                        ariaHasPopup={true}
                        ariaExpanded={open ? true : undefined}
                    />
                </Box>

                {notificationCount > 0 &&
                    <div
                        style={{
                            position: 'absolute',
                            top: '-1px',
                            right: '-1px',
                            backgroundColor: 'red',
                            borderRadius: '50%',
                            width: '20px',
                            height: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: "2px solid",
                            borderColor: colors.ram_light_blue,
                        }}
                    >
                        <span
                            style={{
                                color: 'white',
                                fontSize: '8px',
                                fontWeight: 'bold',
                            }}
                        >
                            {notificationCount}
                        </span>
                    </div>}
            </div>


            <Menu
                anchorEl={anchorEl}
                id="notification-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        width: "450px",
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Box sx={{ width: '100%', paddingLeft: '10px', paddingRight: '10px', maxHeight: '300px', overflow: 'scroll' }}>
                    {loading && (
                        <LoadingSpinner />
                    )}

                    {!loading && notifications.length === 0 && (
                        <NotificationsEmpty />
                    )}

                    {!loading && notifications.length > 0 && (
                        notifications.map((notification, index) => (
                            <NotificationItem key={"notification-" + index} notification={notification} onClick={handleDeleteNotification} />
                        ))
                    )}


                </Box>
                {!loading && notifications.length > 0 && (
                    <Box sx={{ width: '100%', padding: '5px' }}>
                        <Button variant="contained" fullWidth size='small' color="secondary" onClick={handleDeleteAllNotifications}>
                            {t('notification_panel.clear_all')}
                        </Button>
                    </Box>
                )}
            </Menu>
        </React.Fragment>
    );
}
