export enum VehicleEvent {
    IGNITION_ON = 'IGNITION_ON',
    IGNITION_OFF = 'IGNITION_OFF',
    FIRST_IGNITION_OF_DAY = 'FIRST_IGNITION_OF_DAY',
    EXT_POWER_LOST = 'EXT_POWER_LOST',
    INT_BAT_LOW = 'INT_BAT_LOW',
    ZONE_IN = 'ZONE_IN',
    ZONE_OUT = 'ZONE_OUT',
    EXTENDED_IDLE_1 = 'EXTENDED_IDLE_1',
    EXTENDED_IDLE_2 = 'EXTENDED_IDLE_2',
    FATIGUE = 'FATIGUE',
    EXTENDED_STOP = 'EXTENDED_STOP',
    PRIVATE_MILEAGE = 'PRIVATE_MILEAGE',
    PRIVATE_MILEAGE_START = 'PRIVATE_MILEAGE_START',
    TOW_ALERT = 'TOW_ALERT',
    IMPACT_DETECTED = 'IMPACT_DETECTED',
    INPUT_2_HIGH = 'INPUT_2_HIGH',
    OVER_SPEED = 'OVER_SPEED'
}
