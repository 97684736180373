import React from 'react';
import {useTranslation} from 'react-i18next';
import FullView from "../../layouts/FullView";
import {Table, TableCell, TableRow} from "../../components/table/core/Table";
import {RowDeleteButton, RowEditButton} from "../../components/table/core/TableButtons";
import {useNavigate} from "react-router-dom";
import {AssetGroup} from "../../hooks/assetGroups/dto/AssetGroup";
import {useAssetGroupApi} from "../../hooks/assetGroups/AssetGroups";
import {useAssetApi} from "../../hooks/assets/Assets";
import {findAsset, getAssetName} from "../../utils/AssetUtils";
import ConfirmationDialog from "../../components/dialog/ConfirmationDialog";
import FilterBar from "../../components/filterbar/FilterBar";
import {t} from "i18next";

export default function AssetGroupsPage() {
    return (
        <FullView content={<AssetGroupPageContent/>}/>
    );
}

function AssetGroupPageContent() {
    const {assetGroups, getAllAssetGroups, loading} = useAssetGroupApi({shouldLoadAll: true});
    const [searchInput, setSearchInput] = React.useState<string>("");

    function getFilteredAssetGroups() {
        if (assetGroups === null) {
            return [];
        } else if (searchInput === undefined || searchInput === "") {
            return assetGroups || [];
        } else {
            const lowerCaseFilter = searchInput.toLowerCase();
            return assetGroups.filter(assetGroup =>
                assetGroup.labelText.toLowerCase().includes(lowerCaseFilter)
            );
        }
    }

    return <div className="bg-base-100 w-full min-h-full rounded-md shadow-md p-4">
        <FilterBar onSearchChange={setSearchInput} filter={searchInput} label={t('asset_group_page.search_box')}/>
        <AssetGroupTable assetGroups={getFilteredAssetGroups()} loading={loading} updateTrigger={getAllAssetGroups}/>
    </div>
}

function AssetGroupTable(props: Readonly<{ assetGroups?: AssetGroup[], loading: boolean, updateTrigger: () => void }>) {
    const navigate = useNavigate()
    const {assets} = useAssetApi({shouldLoadAll: true});
    const {deleteAssetGroup} = useAssetGroupApi();

    const [selectedAssetGroupId, setSelectedAssetGroupId] = React.useState<number | null>(null);
    const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false)

    const {t} = useTranslation();

    const columns = [
        {id: 'group', content: t('asset_group_page.asset_group_table.group')},
        {id: 'asset', content: t('asset_group_page.asset_group_table.asset')},
        {id: 'actions', content: t('asset_group_page.asset_group_table.actions')}
    ];

    async function handleDelete() {
        await deleteAssetGroup(selectedAssetGroupId?.toString() as string)
            .catch((error) => {
                alert(t('asset_group_page.asset_group_messages.asset_group_delete_failed'))
            })

        setShowDeleteDialog(false);
        props.updateTrigger();
    }

    return (
        <Table
            id="asset-group-table"
            label="asset-group-table"
            columns={columns}
            rows={props.assetGroups?.map((assetGroup) => {
                return <TableRow key={assetGroup.id}>
                    <TableCell>{assetGroup.labelText}</TableCell>
                    <TableCell>
                        {assetGroup.assetIds.length > 3 ? (
                            <div>{`${assetGroup.assetIds.length} ${t('asset_group_page.asset_group_table.asset')}`}</div>
                        ) : (
                            assetGroup.assetIds.map(assetId => {
                                const asset = findAsset(assets, assetId); // Assuming findAsset is a function to locate the asset
                                return asset ? (
                                    <div
                                        key={`${assetId}_vehicles_${assetId}`}
                                        id={`${assetId}_vehicles_${assetId}`}>
                                        {getAssetName(asset)} <br/>
                                    </div>
                                ) : null;
                            })
                        )}
                    </TableCell>

                    <TableCell>
                        <RowEditButton action={() => {
                            const selectedAssetGroup = assetGroup
                            navigate('/update-asset-groups', {state: {selectedAssetGroup: selectedAssetGroup}});
                        }}/>
                        <RowDeleteButton action={() => {
                            setSelectedAssetGroupId(assetGroup.id);
                            setShowDeleteDialog(true);
                        }}/>
                        <ConfirmationDialog open={showDeleteDialog}
                                            title={t('asset_group_page.delete_dialog.title')}
                                            description={t('asset_group_page.delete_dialog.description')}
                                            confirmText={t('button.delete')} cancelText={t('button.cancel')}
                                            onConfirm={handleDelete}
                                            onCancel={() => setShowDeleteDialog(false)}/>
                    </TableCell>
                </TableRow>
            }) || []}
            loading={props.loading}
            createAction={() => {
                navigate('/create-asset-groups')
            }}/>
    )
}
