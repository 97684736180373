import { Box, Button, FormControl, FormLabel, RadioGroup, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import RadioButton from '../../../../components/form/RadioButton';
import { UserPreferencesContext } from '../../../../providers/UserPreferencesProvider';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import StepHeader from '../shared/StepHeader';
import StepContainer from '../shared/StepContainer';

interface AccessibilityStepProps {
    onContinue: () => void;
    onBack: () => void;
}

export default function AccessibilityStep(props: AccessibilityStepProps) {
    const { t } = useTranslation();

    const { colorPalette, setColorPalette, largeText, setLargeText } = useContext(UserPreferencesContext);

    return (

        <StepContainer>

                <StepHeader
                    icon={<AccessibilityIcon />}
                    title={t('setup_wizard.accessibility_step.title')}
                    description={t('setup_wizard.accessibility_step.description')}
                />

                <FormControl fullWidth
                    sx={{
                        textAlign: "left"
                    }}>
                    <FormLabel id="demo-radio-buttons-group-label">
                        <Typography variant="smallTitle">
                            {t('setup_wizard.accessibility_step.color_palette.label')}
                        </Typography>
                    </FormLabel>
                    <RadioGroup
                        name="radio-buttons-group"
                    >
                        <RadioButton mainText={t('setup_wizard.accessibility_step.color_palette.options.default.label')} subText={t('setup_wizard.accessibility_step.color_palette.options.default.description') as string} selected={colorPalette === 'default'} onClick={() => { setColorPalette('default') }} />
                        <RadioButton mainText={t('setup_wizard.accessibility_step.color_palette.options.protanopia.label')} subText={t('setup_wizard.accessibility_step.color_palette.options.protanopia.description') as string} selected={colorPalette === 'protanopia'} onClick={() => { setColorPalette('protanopia') }} />
                        <RadioButton mainText={t('setup_wizard.accessibility_step.color_palette.options.deuteranopia.label')} subText={t('setup_wizard.accessibility_step.color_palette.options.deuteranopia.description') as string} selected={colorPalette === 'deuteranopia'} onClick={() => { setColorPalette('deuteranopia') }} />
                        <RadioButton mainText={t('setup_wizard.accessibility_step.color_palette.options.tritanopia.label')} subText={t('setup_wizard.accessibility_step.color_palette.options.tritanopia.description') as string} selected={colorPalette === 'tritanopia'} onClick={() => { setColorPalette('tritanopia') }} />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth
                    sx={{
                        textAlign: "left"
                    }}>
                    <FormLabel id="demo-radio-buttons-group-label">
                        <Typography variant="smallTitle">
                            {t('setup_wizard.accessibility_step.font_size.label')}
                        </Typography>
                    </FormLabel>
                    <RadioGroup
                        name="radio-buttons-group"
                    >
                        <RadioButton mainText={t('setup_wizard.accessibility_step.font_size.options.default')} selected={largeText === false} onClick={() => { setLargeText(false) }} />
                        <RadioButton mainText={t('setup_wizard.accessibility_step.font_size.options.large')} selected={largeText === true} onClick={() => { setLargeText(true) }} />
                    </RadioGroup>
                </FormControl>

                <Box>
                    <Button color='primary' sx={{ marginBottom: "5px" }} fullWidth variant="contained" onClick={props.onContinue}>{t('button.complete_setup')}</Button>
                    <Button color='secondary' fullWidth variant="contained" onClick={props.onBack}>{t('button.back')}</Button>
                </Box>

        </StepContainer>
    );
};





