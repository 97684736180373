import {VehicleEvent} from "../../../enum/VehicleEvent";

export class WebhookRequest {
    name: string;
    url: string;
    authHeader: string;
    events: VehicleEvent[];

    constructor(props: {
        name: string;
        url: string;
        authHeader: string;
        events: VehicleEvent[];
    }) {
        this.name = props.name;
        this.url = props.url;
        this.authHeader = props.authHeader;
        this.events = props.events;
    }
}
