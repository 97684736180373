import { FileFormat } from "../../../enum/FileFormat";
import { SummarisedReportOptions } from "../../../pages/reports/shared/SummarisedReportOptions";

export class ScheduledReportRequest {
    id: number;
    name: string;
    reportType: string;
    vehicleIds: number[];
    deliveryDay: string;
    daysCovered: number;
    emailAddress1: string;
    emailAddress2: string;
    emailAddress3: string;
    splitReport: boolean;
    options: SummarisedReportOptions | null;
    period: string;
    tabId: number;
    reportFormat: FileFormat | null;


    constructor(props: {
        id: number;
        name: string;
        reportType: string;
        vehicleIds: number[];
        deliveryDay: string;
        daysCovered: number;
        emailAddress1: string;
        emailAddress2: string;
        emailAddress3: string;
        splitReport: boolean;
        options: SummarisedReportOptions | null;
        period: string;
        tabId: number;
        reportFormat: FileFormat | null;
    }) {
        this.id = props.id;
        this.name = props.name;
        this.reportType = props.reportType;
        this.vehicleIds = props.vehicleIds;
        this.deliveryDay = props.deliveryDay;
        this.daysCovered = props.daysCovered;
        this.emailAddress1 = props.emailAddress1;
        this.emailAddress2 = props.emailAddress2;
        this.emailAddress3 = props.emailAddress3;
        this.splitReport = props.splitReport;
        this.options = props.options;
        this.period = props.period;
        this.tabId = props.tabId;
        this.reportFormat = props.reportFormat;
    }
}