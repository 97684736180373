import {Asset} from "../../assets/dto/Asset";

export class LiveShareLink {

    id: number;
    uuid: string;
    url: string;
    availabilityType: LiveShareLinkAvailabilityType;
    expiresAt: string;
    linkPeriod: LiveShareLinksPeriod;
    startTime: string;
    endTime: string;
    assets: Asset[];


    constructor(props: {
        id: number;
        uuid: string;
        url: string;
        availabilityType: LiveShareLinkAvailabilityType;
        expiresAt: string;
        linkPeriod: LiveShareLinksPeriod;
        startTime: string;
        endTime: string;
        assets: Asset[];
    }) {
        this.id = props.id;
        this.uuid = props.uuid;
        this.url = props.url;
        this.availabilityType = props.availabilityType;
        this.expiresAt = props.expiresAt;
        this.linkPeriod = props.linkPeriod;
        this.startTime = props.startTime;
        this.endTime = props.endTime;
        this.assets = props.assets;
    }
}

export enum LiveShareLinkAvailabilityType {
    SCHEDULE, EXPIRY
}

export enum LiveShareLinksPeriod {
    MONDAY = "MONDAY",
    TUESDAY = "TUESDAY",
    WEDNESDAY = "WEDNESDAY",
    THURSDAY = "THURSDAY",
    FRIDAY = "FRIDAY",
    SATURDAY = "SATURDAY",
    SUNDAY = "SUNDAY",
    WEEKDAYS = "WEEKDAYS",
    WEEKENDS = "WEEKENDS",
    ALL = "ALL"
}
