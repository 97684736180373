import {Box,  Button,  Divider,  Grid, Paper, Tooltip, Typography, useTheme} from "@mui/material";
import FullView from "../../layouts/FullView";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { useState } from "react";
import TextInput from "../../components/form/TextInput";
import { ChangeRegistrationSelector, ChangeRegistrationStyle, REGISTRATION_REPORT_GROUPS, TRACKER_HAS_MOVED, VEHICLE_FOR_FIRST_TIME } from "./shared/ChangeRegistrationSelector";
import { ChangeRegistrationRequest, useAssetApi } from "../../hooks/assets/Assets";

export default function ChangeRegistrationAssetPage() {
    return (
        <FullView content={<ChangeRegistrationAssetPageContent/>}/>
    );
}

function ChangeRegistrationAssetPageContent() {

    const location = useLocation();
    const selectedAsset = location.state.selectedAsset;

    const theme = useTheme();
    const navigate = useNavigate()
    const {t} = useTranslation();
    const [selectedChangeRegistration, setSelectedChangeRegistration] = useState<ChangeRegistrationStyle>(VEHICLE_FOR_FIRST_TIME);
    const [newRegistration, setNewRegistration] = useState<string>('')


    const {changeRegistration} = useAssetApi();
    const [warningMessage, setWarningMessage] = useState<string>(''); // State for warning message

    const handleInputChange = (value: string) => {
        // Check if the input exceeds 25 characters
        if (value.length > 25) {
            setWarningMessage('Registration cannot exceed 25 characters'); // Set the warning message
        } else {
            setWarningMessage(''); // Clear the warning if input is valid
            setNewRegistration(value); // Update state if within limit
        }
    };

    const handleChangeRegistration = async () => {

        const vehicleId = selectedAsset.id;
        const registration= newRegistration;
        const isNewVehicle = selectedChangeRegistration === TRACKER_HAS_MOVED; //it is true when and only button 2 is selected

        try {
            const asset = await changeRegistration(new ChangeRegistrationRequest({
                vehicleId: vehicleId,
                registration: registration,
                isNewVehicle: isNewVehicle,
            }));
            
            // If the response was successful, navigate to /assets
            navigate('/assets');
        } catch (error) {
            // Handle the error if the request fails
            alert(t('asset_messages.changing_registration_failed'));
        }

    }

    const handleCancel = () => {
        navigate('/assets')
    }

    return <Paper elevation={3} style={{display: 'flex', flexDirection: 'column', height: '100%'}}>

        <Grid container>
            <Grid item xs={12} md={4} lg={2}>
                <Typography sx={{marginLeft: "20px", marginTop: "10px"}}
                            variant="title">{t("change_registration.registration")}</Typography>
                <Typography sx={{marginLeft: "20px"}} variant="body">{t("change_registration.current_registration")}</Typography>

            </Grid>
            <Grid item xs={12} md={8} lg={10} padding="20px">
                <TextInput editMode={false} isEmail={false}
                            label={''} required={false} placeholder={''}
                            value={selectedAsset.identificationMark} onChange={selectedAsset.identificationMark}/>
            </Grid>
        </Grid>

        <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

        <ChangeRegistrationSelector
            availableChangeRegistrationGroup={REGISTRATION_REPORT_GROUPS}
            selectedChangeRegistration={selectedChangeRegistration}
            setSelectedChangeRegistration={setSelectedChangeRegistration}
        />

        <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>
            
        <Grid container>
            <Grid item xs={12} md={4} lg={2}>
                <Typography sx={{marginLeft: "20px", marginTop: "10px"}}
                            variant="title">{t("change_registration.new_registration")}</Typography>
                <Typography sx={{marginLeft: "20px"}} variant="body">{t("change_registration.please_enter_new_registration")}</Typography>

            </Grid>
            <Grid item xs={12} md={8} lg={10} padding="20px">
                <TextInput
                    editMode={true}
                    isEmail={false}
                    label={''}
                    required={true}
                    placeholder={''}
                    value={newRegistration}
                    onChange={handleInputChange} // Call handleInputChange to manage input length
                />
                {warningMessage && ( // Conditionally display the warning message
                    <Typography color="error" variant="body2">
                        {warningMessage}
                    </Typography>
                )}
            </Grid>
        </Grid>

        <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

        <Grid container>
            <Grid item xs={12} md={12} lg={12}>
                <Box
                    bottom="0px"
                    sx={{
                        padding: '10px',
                        display: 'flex', flexDirection: "row", justifyContent: "space-between"
                    }}>
                    <Button
                        variant="contained"
                        size='small'
                        color="secondary"
                        onClick={() => {
                            handleCancel()
                        }}>
                        {t('button.cancel')}
                    </Button>
                    <Tooltip title={!newRegistration.trim() ? t("change_registration.please_enter_new_registration") : ""} disableHoverListener={!!newRegistration.trim()}>
                        <span style={{ cursor: !newRegistration.trim() ? 'pointer' : 'default' }}>
                            <Button
                                variant="contained"
                                size='small'
                                color="primary"
                                onClick={handleChangeRegistration}
                                disabled={!newRegistration.trim()} // Disable when newRegistration is empty or only contains spaces
                            >
                                {t("button.change_registration")}
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
            </Grid>
        </Grid>


        </Paper>
};
