import { Feature, Polygon } from 'geojson';
import L from "leaflet";

export function calculatePolygonBounds(polygon: Polygon) {
    const coords = polygon.coordinates[0] as [number, number][]
    const updatedCoords: [number, number][] = []
    coords.forEach(coord => {
        updatedCoords.push([coord[1], coord[0]])
    })
    return new L.LatLngBounds(updatedCoords as L.LatLngBoundsLiteral);
}

export function calculateBoundsOfAllFeatures(features: Array<Feature>) {
    let bounds: L.LatLngBounds | undefined = undefined;
    features.forEach(feature => {
        switch (feature.geometry.type) {
            case "Point":
                if (bounds === undefined) {
                    bounds = new L.LatLngBounds([[feature.geometry.coordinates[1], feature.geometry.coordinates[0]], [feature.geometry.coordinates[1], feature.geometry.coordinates[0]]])
                } else {
                    bounds.extend([feature.geometry.coordinates[1], feature.geometry.coordinates[0]])
                }
                break;
            case "Polygon":
                if (bounds === undefined) {
                    bounds = calculatePolygonBounds(feature.geometry as Polygon)
                } else {
                    bounds.extend(calculatePolygonBounds(feature.geometry as Polygon))
                }
                break;
            default:
                break;
        }
    })
    return bounds !== undefined ? bounds : new L.LatLngBounds([[0, 0], [0, 0]]);
}