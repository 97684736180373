export class ReportResponse {
    success: boolean;
    link: string;
    errorMessage: string;
    constructor(props: {
        success: boolean;
        link: string;
        errorMessage: string
    }) {
        this.success = props.success;
        this.link = props.link;
        this.errorMessage = props.errorMessage;
    }
}
