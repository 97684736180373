import React, { useState } from "react";

import RouteReplayAssetsList from "./RouteReplayAssetsList";
import { Map } from "leaflet";
import L from "leaflet";
import RouteReplayJourneySelect from "./RouteReplayJourneySelect";
import RouteReplayJourneyDetail from "./RouteReplayJourneyDetail";
import { Asset } from "../../../../hooks/assets/dto/Asset";
import dayjs, { Dayjs } from "dayjs";
import { Journey } from "../../../../hooks/assets/dto/Journey";

interface RouteReplayLeftPanelProps {
    mapref: React.RefObject<Map>;
    setMarkers: Function;
    selectedAsset: string | undefined;
    setSelectedAsset: React.Dispatch<React.SetStateAction<string | undefined>>;
    loading: boolean;
    assets: Asset[];
}

function RouteReplayLeftPanel(props: RouteReplayLeftPanelProps) {

    const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs().startOf('date'));
    const [journey, setJourney] = useState<Journey | null>(null);

    function onBackPressed() {
        if (props.mapref.current !== null) {
            const assetBounds = props.assets
                .filter(asset => asset.latestEvent !== undefined)
                .map(asset => asset.latestEvent!.location.rawCoordinates)
                .map(coordinate => [coordinate.latitude, coordinate.longitude]);

            var bounds = new L.LatLngBounds(assetBounds as L.LatLngBoundsLiteral);
            props.mapref.current.fitBounds(bounds);
        }

        props.setSelectedAsset(undefined)
    }

    function LeftPanelContent() {
        if (props.selectedAsset === undefined && journey === null) {
            return (<RouteReplayAssetsList loading={props.loading} assets={props.assets} setSelectedAsset={props.setSelectedAsset} />)
        } else if (props.selectedAsset !== undefined && journey === null) {
            let asset = props.assets.find((asset) => asset.id.toString() === props.selectedAsset);
            if (asset !== undefined) {
                return (<RouteReplayJourneySelect
                    asset={asset}
                    onBackPressed={onBackPressed}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    setJourney={setJourney} />)
            } else {
                return (<RouteReplayAssetsList loading={props.loading} assets={props.assets} setSelectedAsset={props.setSelectedAsset} />)
            }
        } else if (props.selectedAsset !== undefined && journey !== null) {
            let asset = props.assets.find((asset) => asset.id.toString() === props.selectedAsset);
            if (asset !== undefined) {
                return (<RouteReplayJourneyDetail mapref={props.mapref} setMarkers={props.setMarkers} asset={asset} journey={journey} onBackPressed={() => { setJourney(null) }} />)
            } else {
                return (<RouteReplayAssetsList loading={props.loading} assets={props.assets} setSelectedAsset={props.setSelectedAsset} />)
            }
        } else {
            return (<RouteReplayAssetsList loading={props.loading} assets={props.assets} setSelectedAsset={props.setSelectedAsset} />)
        }
    }


    return LeftPanelContent()
}

export default RouteReplayLeftPanel;
