import React from 'react';
import {useTranslation} from 'react-i18next';
import FullView from "../../layouts/FullView";
import {Table, TableCell, TableRow} from "../../components/table/core/Table";
import {RowDeleteButton, RowEditButton} from "../../components/table/core/TableButtons";
import {useNavigate} from "react-router-dom";
import {Webhook} from "../../hooks/webhooks/dto/Webhook";
import ConfirmationDialog from "../../components/dialog/ConfirmationDialog";
import FilterBar from "../../components/filterbar/FilterBar";
import {useWebhookApi} from "../../hooks/webhooks/Webhooks";
import {t} from "i18next";

export default function WebhooksPage() {
    return (
        <FullView content={<WebhooksPageContent/>}/>
    );
}

function WebhooksPageContent() {
    const {webhooks, getAllWebhooks, loading} = useWebhookApi({shouldLoadAll: true});
    const [searchInput, setSearchInput] = React.useState<string>("");

    function getFilteredWebhooks() {
        if (webhooks === null) {
            return [];
        } else if (searchInput === undefined || searchInput === "") {
            return webhooks || [];
        } else {
            const lowerCaseFilter = searchInput.toLowerCase();
            return webhooks.filter(webhook =>
                webhook.name.toLowerCase().includes(lowerCaseFilter)
            );
        }
    }

    return <div className="bg-base-100 w-full min-h-full rounded-md shadow-md p-4">
        <FilterBar onSearchChange={setSearchInput} filter={searchInput} label={t("webhook_page.search_box")}/>
        <WebhookTable webhooks={getFilteredWebhooks()} loading={loading} updateTrigger={getAllWebhooks}/>
    </div>
}

function WebhookTable(props: Readonly<{ webhooks?: Webhook[], loading: boolean, updateTrigger: () => void }>) {
    const navigate = useNavigate()
    const {deleteWebhook} = useWebhookApi();

    const [selectedWebhookId, setSelectedWebhookId] = React.useState<number | null>(null);
    const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false)

    const {t} = useTranslation();

    const columns = [
        {id: 'webhook-name', content: t('webhook_page.webhook_table.name')},
        {id: 'webhook-events', content: t('webhook_page.webhook_table.events')},
        {id: 'webhook-auth-header', content: t('webhook_page.webhook_table.auth_header')},
        {id: 'webhook-link', content: t('webhook_page.webhook_table.link')},
        {id: 'webhook-actions', content: t('webhook_page.webhook_table.actions')}
    ];

    async function handleDelete() {
        await deleteWebhook(selectedWebhookId?.toString() as string)
            .catch((error) => {
                alert(t('webhook_page.webhook_messages.webhook_delete_failed'))
            })

        setShowDeleteDialog(false);
        props.updateTrigger();
    }

    return (
        <Table
            id="webhook-table"
            label="webhook-table"
            columns={columns}
            rows={props.webhooks?.map((webhook) => {
                return <TableRow key={webhook.id}>
                    <TableCell>{webhook.name}</TableCell>
                    <TableCell>
                        {webhook.events.length > 3 ? (
                            <div>{`${webhook.events.length} ${t('webhook_page.webhook_table.events')}`}</div>
                        ) : (
                            webhook.events.map(event => {
                                return (
                                    <div
                                        key={`${event}_events_${event}`}
                                        id={`${event}_events_${event}`}>
                                        {t(`vehicle_event.${event.toLowerCase()}`)}<br/>
                                    </div>
                                );
                            })
                        )}
                    </TableCell>
                    <TableCell>{webhook.authHeader}</TableCell>
                    <TableCell>{webhook.url}</TableCell>

                    <TableCell>
                        <RowEditButton action={() => {
                            const selectedWebhook = webhook
                            navigate('/update-webhooks', {state: {selectedWebhook: selectedWebhook}});
                        }}/>
                        <RowDeleteButton action={() => {
                            setSelectedWebhookId(webhook.id);
                            setShowDeleteDialog(true);
                        }}/>
                        <ConfirmationDialog open={showDeleteDialog}
                                            title={t('webhook_page.delete_dialog.title')}
                                            description={t('webhook_page.delete_dialog.description')}
                                            confirmText={t('button.delete')} cancelText={t('button.cancel')}
                                            onConfirm={handleDelete}
                                            onCancel={() => setShowDeleteDialog(false)}/>
                    </TableCell>
                </TableRow>
            }) || []}
            loading={props.loading}
            createAction={() => {
                navigate('/create-webhooks')
            }}
            disableCreateButton={props.webhooks ? props.webhooks.length > 0 : false}
        />
    )
}
