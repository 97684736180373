import {useEffect, useState} from "react";
import usePlatformFetch from "../shared/PlatformFetch";
import PropertiesLoader from "../../properties/PropertiesLoader";
import {ApiKey} from "./dto/ApiKey";

const properties = PropertiesLoader();

interface ApiKeyApiOptions {
    shouldLoadAll?: boolean;
    shouldPoll?: boolean;
    pollFrequency?: number;
}

export function useApiKeyApi(props: ApiKeyApiOptions = {}) {
    const {platformFetch, checkOk} = usePlatformFetch();

    const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const getAllApiKeys = async () => {
        return new Promise((resolve, reject) => {
            setLoading(true)
            platformFetch(
                properties.platformApiEndpoint + "tracking/v1/api-keys",
                'get')
                .then(checkOk)
                .then(response => response.json())
                .then(apiKeys => {
                    setApiKeys(apiKeys)
                    setLoading(false)
                    resolve(apiKeys)
                })
                .catch((error) => {
                    console.error("Error getting api keys: " + error)
                    setError(error)
                    setLoading(false)
                    reject(error)
                });
        });
    }

    const createApiKey = async () => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `tracking/v1/api-keys`,
                'post')
                .then(checkOk)
                .then(response => response.json())
                .then(apiKeys => resolve(apiKeys))
                .catch((error) => {
                    console.error("Error creating api key: " + error)
                    reject(error)
                });
        });
    }

    const deleteApiKey = async () => {
        return new Promise<void>((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `tracking/v1/api-keys`,
                'delete')
                .then(checkOk)
                .then(() => resolve())
                .catch((error) => {
                    console.error("Error deleting api key: " + error)
                    reject(error)
                });
        });
    }

    useEffect(() => {
        if (props.shouldLoadAll) {
            getAllApiKeys();
            if (props.shouldPoll) {
                const interval = setInterval(getAllApiKeys, props.pollFrequency ?? 30000);
                return () => clearInterval(interval);
            }
        }
    }, []);

    return {
        loading, error, apiKeys, getAllApiKeys, createApiKey, deleteApiKey
    }
}
