export class ServiceRecordRequest {
    vehicleId: number;
    notes: string | null;
    engineHours: number | null;
    miles: number | null;
    completedAt: Date;
    url: string | null;

    constructor(props: {
        vehicleId: number;
        notes?: string | null;
        engineHours?: number | null;
        miles?: number | null;
        completedAt: Date;
        url?: string | null;
    }) {
        this.vehicleId = props.vehicleId;
        this.notes = props.notes || null;
        this.engineHours = props.engineHours || null;
        this.miles = props.miles || null;
        this.completedAt = props.completedAt;
        this.url = props.url || null;
    };
}
