import { SsoUser } from "./dto/SsoUser";
import usePlatformFetch from "../shared/PlatformFetch";
import PropertiesLoader from "../../properties/PropertiesLoader";
import { SsoUserUpdate } from "./dto/SsoUserUpdate";
import { UserPreferences } from "./dto/UserPreferences";
import { MapLayer } from "../../components/map/MapTileLayer";
import { DEFAULT_LABEL_LAYER, LabelLayerType } from "../../components/map/LabelLayer";
const properties = PropertiesLoader();

export default function useUserApi() {

    const { platformFetch, checkOk } = usePlatformFetch();

    const getUserProfile = async (): Promise<SsoUser> => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + 'account/v1/users/me',
                "get")
                .then(checkOk)
                .then((response) => response.json().then((data) => data as SsoUser))
                .then((ssoUser) => resolve(ssoUser))
                .catch((error) => reject(error));
        });
    }

    const updateUserProfile = async (updateValues: SsoUserUpdate) => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + 'account/v1/users/me',
                "put",
                JSON.stringify(updateValues))
                .then(checkOk)
                .then((response) => response.json().then((data) => data as SsoUser))
                .then((ssoUser) => resolve(ssoUser))
                .catch((error) => reject(error));
        });
    }

    const getUserPreferences = async () => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + 'account/v1/users/me/preferences',
                "get")
                .then(checkOk)
                .then((response) => response.json().then((data) => new UserPreferences({
                    colorPalette: data.colorPalette,
                    largeText: data.largeText,
                    openDrawer: data.openDrawer,
                    languageCode: data.languageCode,
                    clusterEnabled: data.clusterEnabled,
                    labelsEnabled: data.labelsEnabled,
                    mapType: getMapTypeFromResponseData(data.mapType),
                    labelType: getLabelTypeFromResponseData(data.labelType),
                    twentyFourHourEnabled: data.twentyFourHourEnabled
                })))
                .then((preferences) => resolve(preferences))
                .catch((error) => resolve(undefined));

        });
    }

    const setUserPreferences = async (userPreferences: UserPreferences) => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + 'account/v1/users/me/preferences',
                "post",
                JSON.stringify(userPreferences))
                .then(checkOk)
                .then((response) => response.json().then((data) => new UserPreferences({
                    colorPalette: data.colorPalette,
                    largeText: data.largeText,
                    openDrawer: data.openDrawer,
                    languageCode: data.languageCode,
                    clusterEnabled: data.clusterEnabled,
                    labelsEnabled: data.labelsEnabled,
                    mapType: getMapTypeFromResponseData(data.mapType),
                    labelType: getLabelTypeFromResponseData(data.labelType),
                    twentyFourHourEnabled: data.twentyFourHourEnabled
                })))
                .then((preferences) => resolve(preferences))
                .catch((error) => reject(error));
        });
    }

    return { getUserProfile, updateUserProfile, getUserPreferences, setUserPreferences };
}

function getMapTypeFromResponseData(mapType: any): MapLayer {
    switch (mapType) {
        case 'GOOGLE_ROAD_MAP':
            return MapLayer.GOOGLE_ROAD_MAP;
        case 'GOOGLE_SAT_MAP':
            return MapLayer.GOOGLE_SAT_MAP;
        case 'GOOGLE_HYBRID_MAP':
            return MapLayer.GOOGLE_HYBRID_MAP;
        case 'GOOGLE_TRAFFIC_MAP':
            return MapLayer.GOOGLE_TRAFFIC_MAP;
        case 'OSM_DEFAULT':
            return MapLayer.OSM_DEFAULT;
        default:
            return MapLayer.GOOGLE_ROAD_MAP;
    }
}

function getLabelTypeFromResponseData(labelType: any): LabelLayerType {
    switch (labelType) {
        case 'REGISTRATION':
            return LabelLayerType.REGISTRATION;
        case 'ALIAS':
            return LabelLayerType.ALIAS;
        case 'DRIVER_NAME':
            return LabelLayerType.DRIVER_NAME;
        default:
            return DEFAULT_LABEL_LAYER;
    }
}