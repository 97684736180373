import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Table, TableCell, TableRow } from "../../../components/table/core/Table";
import { GeneratedReport } from "../../../hooks/scheduledReport/dto/GeneratedReport"; // Assuming this is where your type is defined
import FullView from "../../../layouts/FullView";

export default function ViewGeneratedReportsPage() {
    return (
        <FullView content={<GeneratedReportsTable/>}/>
    );
}

function GeneratedReportsTable() {
    
    const { t } = useTranslation();
    const location = useLocation();

    // Accessing the selected report data from location state
    const selectedScheduledReport = location.state?.selectedScheduledReport;
    const generatedReports: GeneratedReport[] = selectedScheduledReport?.generatedReports || [];

    // Define columns
    const columns = [
        { id: 'fileName', content: t('report_page.scheduled_report_table.generated_file_name')},
        { id: 'url', content: t('report_page.scheduled_report_table.generated_file_url') }
    ];

    // Map over `generatedReports` to create rows
    const rows = generatedReports?.map((report: GeneratedReport) => (
        <TableRow key={report.id}>
            <TableCell>{report.fileName}</TableCell>
            <TableCell>
            <a href={report.url.startsWith('http') ? report.url : `https://${report.url}`} target="_blank" rel="noopener noreferrer">
        {t('report_page.scheduled_report_table.click_to_see_report')}
    </a>
            </TableCell>
        </TableRow>
    ));

    return (
        <Table
            id="view-generated-report-table"
            label="view-generated-report-table"
            columns={columns}
            rows={rows}
            loading={false}       
            disableCreateButton={true}
        />
    );
}