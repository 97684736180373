export enum Country {
    GB = "GB",
    US = "US",
    CA = "CA",
    FR = "FR"
}

export function getCountry(input: string | undefined): Country | undefined {
    switch (input?.toUpperCase()) {
        case "GB":
            return Country.GB;
        case "US":
            return Country.US;
        case "CA":
            return Country.CA;
        case "FR":
            return Country.FR;
        default:
            return Country.GB; // Return undefined for unrecognized values
    }
}
