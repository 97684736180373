import {Grid, useTheme} from "@mui/material";
import FeatureListPanel from "./widgets/FeatureListPanel";

import {useAssetApi} from "../../hooks/assets/Assets";
import MapWidget from "./widgets/MapWidget";
import QuickActionsWidget from "./widgets/QuickActionsWidget";
import FeaturedProductWidget from "./widgets/FeaturedProductWidget";
import FleetOverviewWidget from "./widgets/AssetOverviewWidget";
import { useContext } from "react";
import { UserPreferencesContext } from "../../providers/UserPreferencesProvider";
import LoadingSpinner from "../../components/loadingspinner/LoadingSpinner";

interface HomePageProps {
}

const ramAssistWidget = <FeaturedProductWidget
    id='ram_assist_panel'
    nameKey='ram_assist_panel.title'
    descriptionKey='ram_assist_panel.description'
    background='/images/products/assistbg.png'
    image='/images/products/assist_white.png'
    link='https://ramassist.com/portal'
/>

const ramAppWidget = <FeaturedProductWidget
    id='ram_app_panel'
    nameKey='ram_app_panel.title'
    descriptionKey='ram_app_panel.description'
    background='/images/products/app_bg.png'
    image='/images/products/app.png'
    link='https://www.ramtracking.com/vehicle-tracking/app/'
/>

const ramJobAssistWidget = <FeaturedProductWidget
    id='ram_job_assist_panel'
    nameKey='ram_job_assist_panel.title'
    descriptionKey='ram_job_assist_panel.description'
    background='/images/products/job_assist_bg.png'
    image='/images/products/job_assist_white.png'
    link='https://www.ramtracking.com/job-management/'
/>

function HomePage(props: HomePageProps) {
    const theme = useTheme();
    const {assets, loading} = useAssetApi({shouldLoadAll: true, shouldPoll: true});
    const { labelType } = useContext(UserPreferencesContext);

    if (loading) {
        return <div
            className={"flex flex-col justify-center items-center h-[calc(100vh-48px)] w-full text-center"}>
            <LoadingSpinner />
        </div>
    }

    return (
        <div
            id="home-page"
            style={{
                width: '100%',
                height: '100%',
                minHeight: 'calc(100vh - 48px)',
                backgroundColor: theme.palette.contentPage.background,
                padding: '10px'
            }}>
            <Grid container padding="10px" spacing={2.5}>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid container spacing={2}>

                        <Grid item xs={12} md={6} lg={3}>
                            <QuickActionsWidget/>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <MapWidget assets={assets || []} labelLayer={labelType}/>
                        </Grid>
                        <Grid item xs={12} md={12} lg={3}>
                            <FeatureListPanel color={theme.palette.contentPage.backgroundPanel}/>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <FleetOverviewWidget assets={assets || []}/>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            {ramAssistWidget}
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            {ramAppWidget}
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            {ramJobAssistWidget}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default HomePage;
