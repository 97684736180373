import { FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Tooltip, Typography, useTheme } from '@mui/material';
import Control from 'react-leaflet-custom-control';
import LayersIcon from '@mui/icons-material/Layers';
import { MapLayer } from '../../../components/map/MapTileLayer';
import { useTranslation } from 'react-i18next';
import {LayerControlType} from "../MapPage";

interface MapLayerControlProps {
    placement: 'topleft' | 'topright' | 'bottomleft' | 'bottomright';
    selectedLayer: MapLayer;
    setSelectedLayer: React.Dispatch<React.SetStateAction<MapLayer>>;
    open: boolean;
    handleClickOperation: Function;
}

function MapLayerControl(props: MapLayerControlProps) {
    const theme = useTheme()
    const { t } = useTranslation();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        props.handleClickOperation(LayerControlType.MAP);
    };

    const handleSelectLayer = (layer: MapLayer) => {
        props.setSelectedLayer(layer);
    }

    return (
        <>
            <Control position={props.placement} prepend={false}>
                <Tooltip placement="right" title={t('map_controls.select_map_style')}>
                    <IconButton
                        size='small'
                        disableRipple
                        onClick={handleClick}
                        sx={{
                            backgroundColor: theme.palette.mapcontrol.background,
                            color: theme.palette.mapcontrol.foreground,
                            borderRadius: '5px',
                            borderColor: "#000000",
                            borderWidth: '6px',
                            margin: '5px',
                            '&:hover': {
                                backgroundColor: theme.palette.mapcontrol.backgroundHover,
                            },
                        }}
                    ><LayersIcon /></IconButton>
                </Tooltip>

                <FormControl
                    id="layer-menu"
                    sx={{
                        visibility: props.open ? "visible" : "hidden",
                        width: "200px",
                        left: "50px",
                        top: "0px",
                        borderRadius: "5px",
                        padding: "10px",
                        position: "absolute",
                        backgroundColor: theme.palette.mapcontrol.background,
                    }}>
                    <FormLabel>{t('map_controls.map_style')}</FormLabel>
                    <RadioGroup
                    >
                        <FormControlLabel value="google-road" control={<Radio size='small' color='secondary' />} label={<Typography variant='cardsubtitle'>Google Road</Typography>} checked={props.selectedLayer === MapLayer.GOOGLE_ROAD_MAP} onChange={e => handleSelectLayer(MapLayer.GOOGLE_ROAD_MAP)} />
                        <FormControlLabel value="google-traffic" control={<Radio size='small' color='secondary' />} label={<Typography variant='cardsubtitle'>Google Traffic</Typography>} checked={props.selectedLayer === MapLayer.GOOGLE_TRAFFIC_MAP} onChange={e => handleSelectLayer(MapLayer.GOOGLE_TRAFFIC_MAP)} />
                        <FormControlLabel value="google-hybrid" control={<Radio size='small' color='secondary' />} label={<Typography variant='cardsubtitle'>Google Hybrid</Typography>} checked={props.selectedLayer === MapLayer.GOOGLE_HYBRID_MAP} onChange={e => handleSelectLayer(MapLayer.GOOGLE_HYBRID_MAP)} />
                        <FormControlLabel value="osm" control={<Radio size='small' color='secondary' />} label={<Typography variant='cardsubtitle'>OpenStreetMaps</Typography>} checked={props.selectedLayer === MapLayer.OSM_DEFAULT} onChange={e => handleSelectLayer(MapLayer.OSM_DEFAULT)} />
                    </RadioGroup>
                </FormControl>
            </Control>
        </>
    );
}

export default MapLayerControl;
