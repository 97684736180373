export enum ReportType {
    TRAVEL_REPORT = "TRAVEL_REPORT",
    PRIVATE_REPORT = "PRIVATE_REPORT",
    BUSINESS_REPORT = "BUSINESS_REPORT",
    STOP_REPORT = "STOP_REPORT",
    DETAIL_REPORT = "DETAIL_REPORT",
    GENERATOR_DETAIL_REPORT = "GENERATOR_DETAIL_REPORT",
    IDLE_REPORT = "IDLE_REPORT",
    SUMMARY_REPORT = "SUMMARY_REPORT",
    GREEN_REPORT = "GREEN_REPORT",
    SUMMARIZED_DETAIL_REPORT = "SUMMARIZED_DETAIL_REPORT",
    TRAVEL_SUMMARY_REPORT = "TRAVEL_SUMMARY_REPORT",
    CONGESTION_AND_EMISSION_ZONE_REPORT = "CONGESTION_AND_EMISSION_ZONE_REPORT",
    SPEED_ANALYSIS_REPORT = "SPEED_ANALYSIS_REPORT",
    LOCATION_STOP_REPORT = "LOCATION_STOP_REPORT",
    STOP_LOCATION_FINANCE_REPORT = "STOP_LOCATION_FINANCE_REPORT",
    TRAVEL_REPORT_FINANCE = "TRAVEL_REPORT_FINANCE",
    PRIVATE_REPORT_FINANCE = "PRIVATE_REPORT_FINANCE",
    BUSINESS_REPORT_FINANCE = "BUSINESS_REPORT_FINANCE",
    STOP_REPORT_FINANCE = "STOP_REPORT_FINANCE",
    PRIVACY_BUTTON_REPORT = "PRIVACY_BUTTON_REPORT",
    PRIVATE_REPORT_DISTANCE_ONLY = "PRIVATE_REPORT_DISTANCE_ONLY",
    LOCATION_STOP_REPORT_FINANCE = "LOCATION_STOP_REPORT_FINANCE",
    DRIVER_BEHAVIOUR_REPORT = "DRIVER_BEHAVIOUR",
    TIMESHEET_REPORT_FINANCE = "TIMESHEET_REPORT_FINANCE"
}

export function getTranslatedName(reportType: ReportType | undefined, t: (key: string) => string) {
    const reportTypeLowerCase = reportType!.toLowerCase();
    const translationKey = `report_type.${reportTypeLowerCase}.name`;
    return t(translationKey);
}

export function getTranslatedDescription(reportType: ReportType, t: (key: string) => string) {
    const reportTypeLowerCase = reportType.toLowerCase();
    const translationKey = `report_type.${reportTypeLowerCase}.description`;
    return t(translationKey);
}
