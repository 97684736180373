import {Grid, InputAdornment, TextField} from "@mui/material";
import React from "react";
import SearchIcon from '@mui/icons-material/Search';

interface FilterBarProps {
    onSearchChange: (search: string) => void;
    filter: string;
    label: string;
}

export default function FilterBar(props: FilterBarProps) {

    return (
        <Grid container paddingBottom="10px" paddingTop="10px" spacing={1.5}>
            <Grid item xs={12} md={12} lg={12}>
                <TextField
                    fullWidth={true}
                    id="outlined-basic"
                    size="small"
                    label={props.label}
                    variant="outlined"
                    color="info"
                    value={props.filter}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon/>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (event.target.value === undefined || event.target.value === "") {
                            props.onSearchChange("");
                        } else {
                            props.onSearchChange(event.target.value);
                        }
                    }}
                />
            </Grid>
        </Grid>
    );
}
