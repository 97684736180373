import Paper from "@mui/material/Paper";
import LoadingSpinner from "../../../components/loadingspinner/LoadingSpinner";
import {Box, Button, Link, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {getTranslatedName, ReportType} from "../../../enum/ReportType";

interface ReportReadyPageProps {
    isGenerating: boolean;
    handleBackToReports: () => void;
    reportLink?: string;
    reportType?: ReportType;
    reportFormat?: string;
    reportDateRange?: string;
}

export function ReportReadyPage(props: ReportReadyPageProps) {
    const { t } = useTranslation();
    return (
        <Paper elevation={3} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            {props.isGenerating ?
                <LoadingContent /> :
                <ReportReadyContent
                    handleBackToReports={props.handleBackToReports}
                    reportType={getTranslatedName(props.reportType, t)}
                    reportLink={props.reportLink!}
                    reportFormat={props.reportFormat!}
                    reportDateRange={props.reportDateRange!}
                />}
        </Paper>
    );
}

function LoadingContent() {
    return <div style={{width: '100%', height: '100%'}}>
        <div style={{textAlign: 'center', top: '50%', left: '50%', position: 'absolute'}}>
        <LoadingSpinner />
        </div>
    </div>
}

interface ReportReadyContentProps {
    handleBackToReports: () => void;
    reportLink: string;
    reportType: string;
    reportFormat: string;
    reportDateRange: string;
}
function ReportReadyContent(props: ReportReadyContentProps) {
    const { t } = useTranslation();
    return <div style={{width: '100%', height: '100%', padding: '10px', paddingTop: '0px', display: 'flex', flexDirection: 'column'}}>
        <Typography variant="smallHeader" style={{paddingBottom: '0px'}}>
            {t('report_ready.title')}
        </Typography>
        <Typography variant="subtitle" style={{paddingTop: '0px'}}>
            {t('report_ready.description')}
        </Typography>

        <Box sx={{
            backgroundColor: "tertiary.main",
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            padding: '10px',
            marginTop: '10px',
            marginBottom: '10px',
            borderRadius: '5px',
        }
        }>
            <div>
                <Typography variant="subtitle" style={{paddingTop: '0px'}}>
                    {t('report_ready.headers.report_name')}
                </Typography>
                <Typography variant="body" style={{paddingTop: '0px'}}>
                    {props.reportType}
                </Typography>
            </div>
            <div>
                <Typography variant="subtitle" style={{paddingTop: '0px'}}>
                    {t('report_ready.headers.date_range')}
                </Typography>
                <Typography variant="body" style={{paddingTop: '0px'}}>
                    {props.reportDateRange}
                </Typography>
            </div>
            <div>
                <Typography variant="subtitle" style={{paddingTop: '0px'}}>
                    {t('report_ready.headers.file_format')}
                </Typography>
                <Typography variant="body" style={{paddingTop: '0px'}}>
                    {props.reportFormat}
                </Typography>
            </div>
            <div>
                <Typography variant="subtitle" style={{paddingTop: '0px'}}>
                    {t('report_ready.headers.link')}
                </Typography>

                <Typography component={Link} href={props.reportLink} variant="body" style={{paddingTop: '0px'}} download={true}>
                    {t('report_ready.click_to_download')}
                </Typography>
            </div>

        </Box>
        {props.reportFormat === "PDF" && props.reportLink &&
        <iframe
            title={"Report"}
            src={props.reportLink}
            style={{flexGrow: '1', borderRadius: '5px',}}></iframe>
        }

        <Box
            bottom="0px"
            sx={{
                padding: '10px',
                borderTop: '1px solid #808080',
                display: 'flex', flexDirection: "row", justifyContent: "space-between"
            }}>
            <Button
                variant="contained"
                size='small'
                color="secondary"
                onClick={() => {
                    props.handleBackToReports()
                }}>
                {t('button.back')}
            </Button>
        </Box>


    </div>
}
