import Box from '@mui/material/Box';
import { Divider, IconButton, Tooltip, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import DeleteIcon from '@mui/icons-material/Delete';
import { Notification } from '../../../hooks/notifications/dto/Notification';
import { useTranslation } from 'react-i18next';

interface NotificationItemProps {
    notification: Notification;
    onClick: Function;
}

export default function NotificationItem(props: NotificationItemProps) {

    const { t } = useTranslation();

    return (<><Box component="div"
        sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '5px',
            padding: '5px',
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', padding: '5px' }}>
            <WarningIcon color='secondary' />
        </Box>

        <Box sx={{ textAlign: 'left' }}>

            <Typography variant="dropdownBody" sx={{ fontWeight: 'bold' }}>
                {props.notification.title}
            </Typography>
            <Typography variant="dropdownBody" component='span'>
                {props.notification.description}
            </Typography>
            <Typography variant="dropdownCaption" component='span'>
                {getTimeElapsedSinceString(props.notification.createdAt)}
            </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', verticalAlign: 'center', flexWrap: 'wrap', marginLeft: 'auto' }}>
            <Tooltip title={t('button.delete')}>
                <IconButton onClick={() => props.onClick(props.notification.id)}>
                    <DeleteIcon color='disabled' />
                </IconButton>
            </Tooltip>
        </Box>
    </Box>
        <Divider component="li" />
    </>);
}



/*
function to get a string representing the time elapsed since a given date following the format:

< 60 mins = "x minutes ago"
> 60 mins but less than 120 mins = "1 hour ago"
> 120 mins but less than 1440 mins = "x hours ago"
> 1440 mins but less than 2880 mins = "1 day ago"
> 2880 mins but less than 10080 mins = "x days ago"
> 10080 mins but less than 20160 mins = "1 week ago"
> 20160 mins but less than 40320 mins = "x weeks ago"
> 40320 mins but less than 525600 mins = "x months ago"
> 525600 mins but less than 1051200 mins = "1 year ago"
> 1051200 mins = "x years ago"

*/

function getTimeElapsedSinceString(date: Date) {
    const now = new Date();
    const diff = Math.round((now.getTime() - date.getTime()) / 60000);
    if (diff < 60) {
        return diff + " minutes ago";
    } else if (diff < 120) {
        return "1 hour ago";
    } else if (diff < 1440) {
        return Math.round(diff / 60) + " hours ago";
    } else if (diff < 2880) {
        return "1 day ago";
    } else if (diff < 10080) {
        return Math.round(diff / 1440) + " days ago";
    } else if (diff < 20160) {
        return "1 week ago";
    } else if (diff < 40320) {
        return Math.round(diff / 10080) + " weeks ago";
    } else if (diff < 525600) {
        return Math.round(diff / 40320) + " months ago";
    } else if (diff < 1051200) {
        return "1 year ago";
    } else {
        return Math.round(diff / 525600) + " years ago";
    }
}


