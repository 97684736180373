import { Box, Divider, Typography } from "@mui/material";
import {colors} from "../../../themes/AppTheme";

interface TimelineChartHeaderProps {
    // assetLabel: string;
}

export default function TimelineChartHeader(props: TimelineChartHeaderProps) {
    return (
        <>
            <Box
                component={'div'}
                sx={{
                    width: '100%',
                    height: '50px',
                    paddingTop: '10px',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                <Typography
                    variant="subtitle"
                    sx={{
                        height: '30px',
                        width: '5%',
                        textAlign: 'left',
                        color: colors.ram_purple
                    }}>
                    00:00
                </Typography>
                <Typography
                    variant="subtitle"
                    sx={{
                        height: '30px',
                        width: '40%',
                        textAlign: 'center',
                        color: colors.ram_purple
                    }}>
                    06:00
                </Typography>
                <Typography
                    variant="subtitle"
                    sx={{
                        height: '30px',
                        width: '10%',
                        textAlign: 'center',
                        color: colors.ram_purple
                    }}>
                    12:00
                </Typography>
                <Typography
                    variant="subtitle"
                    sx={{
                        height: '30px',
                        width: '40%',
                        textAlign: 'center',
                        color: colors.ram_purple
                    }}>
                    18:00
                </Typography>
                <Typography
                    variant="subtitle"
                    sx={{
                        height: '30px',
                        width: '5%',
                        textAlign: 'right',
                        color: colors.ram_purple
                    }}>
                    23:59
                </Typography>
            </Box>
            <Divider />
        </>
    );
}