import {Grid} from "@mui/material";
import FileFormatSelection from "../FileFormatSelection";
import React from "react";
import {useTranslation} from "react-i18next";
import {FileFormat} from "../../../enum/FileFormat";
import {SelectorLabel} from "./SelectorLabel";

interface FileFormatSelectorProps {
    selectedFileFormat: FileFormat;
    setSelectedFileFormat: (selectedFileFormat: FileFormat) => void;
}
export function FileFormatSelector(props: FileFormatSelectorProps) {

    const { t } = useTranslation();

    return <Grid container>
        <SelectorLabel
            title={t("report.file_format")}
            subtitle={t("report.select_file_format")}
            />
        <Grid item xs={12} md={8} lg={10} padding="20px">
            <FileFormatSelection selectedFileFormat={props.selectedFileFormat} setSelectedFileFormat={props.setSelectedFileFormat} />
        </Grid>
    </Grid>
}