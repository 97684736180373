import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function NotificationsEmpty() {

    const { t } = useTranslation();

    return (
        <Box sx={{ width: '100%', padding: '10px', textAlign: 'center' }}>
            <NotificationsNoneIcon fontSize='large' color='disabled' />
            <Typography variant="dropdownTitle">
                {t('notification_panel.no_notifications.title')}
            </Typography>
            <Typography variant="dropdownBody">
                {t('notification_panel.no_notifications.description')}
            </Typography>
        </Box>
    )
}