import {Box} from "@mui/material";
import {ReactComponentElement, useContext, useEffect, useRef, useState,} from "react";

//Map Components
import {AttributionControl, MapContainer, useMapEvents} from "react-leaflet";
import L, {Map} from "leaflet";
import MapContextMenu from "./components/MapContextMenu";
import AssetMarkerLayer from "./components/AssetMarkerLayer";
import MapTileLayer from "../../components/map/MapTileLayer";
import AssetPanel from "../../components/assetpanel/AssetPanel";
import MapButtonControl from "./components/MapButtonControl";
import ZoomMapControl from "./components/ZoomMapControl";
import MapLayerControl from "./components/MapLayerControl";

// Icons
import CropFreeIcon from '@mui/icons-material/CropFree';
import LabelIcon from '@mui/icons-material/Label';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import HexagonOutlinedIcon from '@mui/icons-material/HexagonOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import {UserPreferencesContext} from "../../providers/UserPreferencesProvider";
import CongestionZoneLayer from "../../components/map/CongestionZoneLayer";
import GeofenceLayer from "../../components/map/GeofenceLayer";
import {useTranslation} from "react-i18next";
import {resizeMap} from "../../utils/MapUtils";
import {useAssetApi} from "../../hooks/assets/Assets";
import {useCongestionZonesApi} from "../../hooks/zones/CongestionZones";
import {useGeofencesApi} from "../../hooks/geofences/Geofences";
import {useSearchParams} from "react-router-dom";
import {Geofence} from "../../hooks/geofences/dto/Geofence";
import LabelLayer from "../../components/map/LabelLayer";
import LabelLayerControl from "./components/LabelLayerControl";
import { FilterAssetContext } from "../../providers/FilterAssetProvider";

const containerStyle = {
    width: "100%",
    height: "calc(100vh - 48px)",
    margin: "0 auto",
};

export enum LayerControlType {
    MAP = 'MAP',
    LABEL = 'LABEL',
}

interface MapPageProps {
    subview?: ReactComponentElement<any>;
}

function MapPage(this: any, props: MapPageProps) {
    const {t} = useTranslation();
    const mapRef = useRef<Map>(null);

    const {assets, setAssets, loading} = useAssetApi({shouldLoadAll: true, shouldPoll: true});
    const {zones} = useCongestionZonesApi(true);
    const {geofences, getAllGeofences} = useGeofencesApi(true);

    const [selectedAsset, setSelectedAsset] = useState<string | undefined>(undefined);
    const [keepAssetsInBounds, setKeepAssetsInBounds] = useState<boolean>(true);
    const {clusterEnabled, setClusterEnabled, labelsEnabled, setLabelsEnabled, mapType, setMapType, labelType, setLabelType} = useContext(UserPreferencesContext);
    const [congestionZonesEnabled, setCongestionZonesEnabled] = useState(false);
    const [geofenceEnabled, setGeofenceEnabled] = useState(false);
    const [programmaticAction, setProgrammaticAction] = useState(false);

    const [mapLayerControllerOpen, setMapLayerControllerOpen] = useState(false);
    const [labelLayerControllerOpen, setLabelLayerControllerOpen] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    const { filteredAssets, setFilteredAssets } = useContext(FilterAssetContext);


    useEffect(() => {
        if(assets?.length){
            setFilteredAssets(assets);
        }
    }, [assets]);

    useEffect(() => {
        let selectedId = searchParams.get("selected");
        let locationName = searchParams.get("locationName");
        let locationLatitude = searchParams.get("locationLatitude");
        let locationLongitude = searchParams.get("locationLongitude");
        let selectedGeofenceId = searchParams.get("selectedGeofence");

        if (mapRef.current === null) {
            return;
        }

        if (selectedId !== null) {
            setSelectedAsset(selectedId);
            searchParams.delete("selected");
            setSearchParams(searchParams);
        } else if (locationName !== null && locationLatitude !== null && locationLongitude !== null) {
            const lat = parseFloat(locationLatitude);
            const lng = parseFloat(locationLongitude);
            mapRef.current?.setView([lat, lng], 15);
            searchParams.delete("locationName");
            searchParams.delete("locationLatitude");
            searchParams.delete("locationLongitude");
            setSearchParams(searchParams);
        } else if (selectedGeofenceId !== null) {
            setGeofenceEnabled(true);
            zoomToGeofence(selectedGeofenceId);
            searchParams.delete("selectedGeofence");
            setSearchParams(searchParams);
        }
    }, [searchParams, mapRef.current]);

    function MapEventHandler() {
        useMapEvents({
            zoomend: () => disableKeepAssetsInBounds(),
            moveend: () => disableKeepAssetsInBounds(),
            click: (event) => {
                setLabelLayerControllerOpen(false);
                setMapLayerControllerOpen(false);
            }
        })

        function disableKeepAssetsInBounds() {
            if (selectedAsset === undefined && !programmaticAction && keepAssetsInBounds) {
                setKeepAssetsInBounds(false);
            }
            if (programmaticAction) {
                setProgrammaticAction(false);
            }
        }

        return <></>
    }

    function handleAutomaticAssetZoomAndPan(forceZoomToAllAssets?: boolean) {

        if (forceZoomToAllAssets) {
            zoomToAllAssets(forceZoomToAllAssets);
        } else if (selectedAsset !== undefined) {
            zoomToAsset(selectedAsset);
        } else if (keepAssetsInBounds) {
            zoomToAllAssets(forceZoomToAllAssets);
        }
    }

    function zoomToAllAssets(force?: boolean) {
        setProgrammaticAction(true);
        if (filteredAssets) {

            const filteredAssetsWithLocation = filteredAssets.filter(asset => asset.latestEvent !== undefined);
            if (filteredAssetsWithLocation.length === 0) {
                return;
            }

            const assetBounds = filteredAssetsWithLocation
                .map(asset => asset.latestEvent!.location.rawCoordinates)
                .map(coordinate => [coordinate.latitude, coordinate.longitude]);

            const rawBounds = new L.LatLngBounds(assetBounds as L.LatLngBoundsLiteral);
            const paddedBounds = addPaddingToBounds(rawBounds);
            mapRef.current?.fitBounds(paddedBounds);
        }
    }

    function zoomToAsset(assetId: string) {
        setProgrammaticAction(true);
        const asset = assets?.find(asset => asset.id.toString() === assetId);
        if (asset !== undefined && asset.latestEvent !== undefined) {
            const assetLocation = asset.latestEvent.location.rawCoordinates;
            const assetLatLng = new L.LatLng(assetLocation.latitude, assetLocation.longitude);
            mapRef.current?.setView(assetLatLng, 15);
        }
    }

    async function zoomToGeofence(geofenceId: string) {
        const loadedGeofences = await getAllGeofences() as Geofence[];
        const geofence = loadedGeofences?.find(geofence => geofence.id?.toString() === geofenceId);
        if (geofence !== undefined) {
            if (geofence.feature.geometry.type === "Point") {
                const geofenceLocation = new L.LatLng(geofence.feature.geometry.coordinates[1], geofence.feature.geometry.coordinates[0]);
                mapRef.current?.setView(geofenceLocation, 15);
            } else if (geofence.feature.geometry.type === "Polygon") {
                const geofenceBounds = geofence.feature.geometry.coordinates[0].map((coord) => [coord[1], coord[0]]);
                const rawBounds = new L.LatLngBounds(geofenceBounds as L.LatLngBoundsLiteral);
                mapRef.current?.fitBounds(rawBounds, {padding: [50, 50]});
            }
        }
    }

    function addPaddingToBounds(bounds: L.LatLngBounds) {
        const padding = 0.130;
        return new L.LatLngBounds(
            [bounds.getSouth() - padding, bounds.getWest() - padding],
            [bounds.getNorth() + padding, bounds.getEast() + padding]
        );
    }

    function enableKeepInView() {
        setKeepAssetsInBounds(true);
        setSelectedAsset(undefined);
        zoomToAllAssets(true);
    }

    const handleClickOperation = (controlType: LayerControlType) => {
        if (controlType === LayerControlType.MAP) {
            setMapLayerControllerOpen(!mapLayerControllerOpen);
            setLabelLayerControllerOpen(false);
        } else if (controlType === LayerControlType.LABEL) {
            setMapLayerControllerOpen(false);
            setLabelLayerControllerOpen(!labelLayerControllerOpen);
        }
    };

    const mapContainer = (
        <div
            style={containerStyle}
        >

            <MapContainer
                ref={mapRef}
                attributionControl={false}
                style={containerStyle}
                center={[53.801277, -1.548567]}
                zoom={7}
                scrollWheelZoom={true}
                zoomControl={false}
                id="leaflet-container"
                whenReady={() => {
                    resizeMap(mapRef)
                }}
            >
                <AttributionControl position="bottomright" prefix=""/>

                {/* Event handler for things like pan and zoom. This is what disconnects the keep assets in view if a user pans / zooms */}
                <MapEventHandler/>

                {/* Map Tile Layer - Selected by the MapLayerControl */}
                <MapTileLayer mapLayer={mapType}/>

                {/* Label Layer - Selected by the MapLayerControl */}
                <LabelLayer labelLayer={labelType}/>

                {/* Context Menu - Currently only used for street view */}
                <MapContextMenu/>

                {/* Map Controls */}
                <ZoomMapControl onZoomInClick={() => {
                    mapRef.current?.zoomIn()
                }} onZoomOutClick={() => {
                    mapRef.current?.zoomOut()
                }}/>
                <MapButtonControl placement="topleft" tooltip={t('map_controls.show_all_assets')} toggled={keepAssetsInBounds}
                                  tooltipPlacement="right" icon={<CropFreeIcon/>} onClick={() => enableKeepInView()}/>
                <MapButtonControl placement="topleft" tooltip={t('map_controls.toggle_labels')} toggled={labelsEnabled} tooltipPlacement="right"
                                  icon={<LabelIcon/>} onClick={() => setLabelsEnabled(!labelsEnabled)}/>
                <MapButtonControl placement="topleft" tooltip={t('map_controls.toggle_clustering')} toggled={clusterEnabled}
                                  tooltipPlacement="right" icon={<GroupWorkIcon/>} onClick={() => setClusterEnabled(!clusterEnabled)}/>
                <MapButtonControl placement="topleft" tooltip={t('map_controls.toggle_congestion_zones')} toggled={congestionZonesEnabled}
                                  tooltipPlacement="right" icon={<HexagonOutlinedIcon/>}
                                  onClick={() => setCongestionZonesEnabled(!congestionZonesEnabled)}/>
                <MapButtonControl placement="topleft" tooltip={t('map_controls.toggle_geofences')} toggled={geofenceEnabled}
                                  tooltipPlacement="right" icon={<LocationOnOutlinedIcon/>}
                                  onClick={() => setGeofenceEnabled(!geofenceEnabled)}/>
                <MapLayerControl placement="topleft" selectedLayer={mapType} setSelectedLayer={setMapType} open={mapLayerControllerOpen} handleClickOperation={handleClickOperation}/>
                <LabelLayerControl placement="topleft" selectedLayer={labelType} setSelectedLayer={setLabelType} open={labelLayerControllerOpen} handleClickOperation={handleClickOperation}/>


                {/* Asset Markers */}
                {filteredAssets && filteredAssets.length > 0 &&
                    (<AssetMarkerLayer
                        handleAutomaticAssetZoomAndPan={handleAutomaticAssetZoomAndPan}
                        assets={filteredAssets}
                        clusteringEnabled={clusterEnabled}
                        labelsEnabled={labelsEnabled}
                        selectedAsset={selectedAsset}
                        setSelectedAsset={setSelectedAsset}
                        labelLayer={labelType} 
                    />)}
                {/* Congestion Zones */}
                {zones && zones.length > 0 &&
                    (<CongestionZoneLayer zones={zones || []} labelsEnabled={labelsEnabled} congestionZonesEnabled={congestionZonesEnabled}/>)}
                {/* Geofences */}
                {geofences && geofences.length > 0 &&
                    (<GeofenceLayer geofences={geofences} labelsEnabled={labelsEnabled} geofenceEnabled={geofenceEnabled}/>)}

            </MapContainer>
        </div>
    );

    return (
        <>
            <Box sx={{minWidth: "350px", padding: "0"}}>
                <AssetPanel mapref={mapRef} loading={loading} assets={assets || []} selectedAsset={selectedAsset}
                            setSelectedAsset={setSelectedAsset}/>
            </Box>
            {mapContainer}
        </>
    );
}

export default MapPage;
