import { IconButton, Tooltip, useTheme } from '@mui/material';
import Control from 'react-leaflet-custom-control';


interface MapButtonControlProps {
    placement: 'topleft' | 'topright' | 'bottomleft' | 'bottomright';
    icon: JSX.Element;
    toggled?: boolean;
    onClick: Function;
    tooltip: string;
    tooltipPlacement?: 'top' | 'right' | 'bottom' | 'left';
}

function MapButtonControl(props: MapButtonControlProps) {
    const theme = useTheme()
    return (
        <Control position={props.placement}>
            <Tooltip placement={props.tooltipPlacement} title={props.tooltip}>
                <IconButton
                    size='small'
                    disableRipple
                    onClick={(event) => props.onClick(event)}
                    sx={{
                        backgroundColor: props.toggled === true ? theme.palette.mapcontrol.backgroundAlt : theme.palette.mapcontrol.background,
                        color: props.toggled === true ? theme.palette.mapcontrol.foregroundAlt : theme.palette.mapcontrol.foreground,
                        borderRadius: '5px',
                        borderColor: "#000000",
                        borderWidth: '0px',
                        margin: '5px',
                        '&:hover': {
                            backgroundColor: theme.palette.mapcontrol.backgroundHover,
                        },
                    }}
                >{props.icon}</IconButton>
            </Tooltip>
        </Control>
    );
}

export default MapButtonControl;