import {useIntercom} from "react-use-intercom";
import useAccountApi from "../../hooks/account/Account";
import {useContext} from "react";
import {UserPreferencesContext} from "../../providers/UserPreferencesProvider";
import {UserAccountContext} from "../../providers/UserAccountProvider";

export function IntercomComponent() {
    const {account} = useAccountApi();
    const {boot, trackEvent} = useIntercom();
    const {preferencesLoaded, languageCode} = useContext(UserPreferencesContext);
    const userAccountContext = useContext(UserAccountContext);

    if (preferencesLoaded && userAccountContext.user && account) {
        boot({
            languageOverride: languageCode,
            hideDefaultLauncher: true,
            name: `${userAccountContext.user.firstName} ${userAccountContext.user.lastName}`,
            email: userAccountContext.user.email,
            userId: userAccountContext.user.salesforceContactId || userAccountContext.user.id,
            userHash: userAccountContext.user.intercomHash,
            company: {
                companyId: account.salesforceId,
                name: account.name,
                plan: account.featurePackage,
            },
            customLauncherSelector: '.account_callsupport_link'
        });
        trackEvent('RT2-Opened');
    }

    return <></>;
}
