import { Box, Button, MenuItem } from "@mui/material";
import LeftPanelButtonBar from "../../../../components/leftpanel/LeftPanelButtonBar";
import { Geofence, GeofenceCategory } from "../../../../hooks/geofences/dto/Geofence";
import TextInput from "../../../../components/form/TextInput";
import React, { useContext } from "react";
import { TwitterPicker } from 'react-color';
import { GeofenceWizardContext } from "../../../../providers/GeofenceWizardProvider";
import SelectInput from "../../../../components/form/SelectInput";
import LoadingSpinner from "../../../../components/loadingspinner/LoadingSpinner";
import ConfirmationDialog from "../../../../components/dialog/ConfirmationDialog";
import { useTranslation } from "react-i18next";
import { useGeofencesApi } from "../../../../hooks/geofences/Geofences";
import ReactGA from "react-ga4";

interface GeofenceDetailPanelProps {
    onBackButtonPressed: Function;   
}

export function GeofenceDetailPanel(props: GeofenceDetailPanelProps) {
    const { t } = useTranslation();
    const { getGeofenceCategories, createGeofence, getGeofenceById, updateGeofence, deleteGeofence } = useGeofencesApi(false);
    const { newGeofence, setNewGeofence, loading, setLoading, id, setEditingFeature, editingFeature, name, setName, category, setCategory, colour, setColour, feature, setFeature } = useContext(GeofenceWizardContext);
    const [geofence, setGeofence] = React.useState<Geofence | undefined>(undefined)
    const [geofenceCategories, setGeofenceCategories] = React.useState<GeofenceCategory[]>([])
    const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false)
    const [showMustSaveDialog, setShowMustSaveDialog] = React.useState<boolean>(false)

    function getCategories() {
        let categories = [<MenuItem key={"no-category-item"} value={'-1'}>{t("geofences_page.no_category")}</MenuItem>]
        let mappedCategories = geofenceCategories.map(category => (<MenuItem key={category.id + "-category-item"} value={category.id}>{category.name}</MenuItem>))
        categories.push(...mappedCategories)
        return categories
    }


    
    React.useEffect(() => {
        if(!id && feature)  setShowMustSaveDialog(true);

    }, [feature, id]);

    React.useEffect(() => {
        async function loadGeofenceToEdit() {
            setLoading(true)
            try {
                let response: GeofenceCategory[] = await getGeofenceCategories() as GeofenceCategory[];
                if (!response) {
                    props.onBackButtonPressed()
                }
                setGeofenceCategories(response)


                if (!newGeofence) {
                    let response: Geofence = await getGeofenceById(id!.toString()) as Geofence;
                    if (!response) {
                        props.onBackButtonPressed();
                    }
                    setName(response.name);
                    setCategory(response.category?.id);
                    setColour(response.colour);
                    setFeature(response.feature);
                    setGeofence(response);
                } else {
                    setColour("#FF6900")
                }
            } catch (error) {
                console.log("Failed to load geofences data: " + error)
            }
            setLoading(false)
        }

        loadGeofenceToEdit();
    }, []);

    function handleBack() {
        setLoading(false)
        setNewGeofence(false)
        setName("")
        setCategory(undefined)
        setColour("")
        setFeature(undefined)
        setGeofence(undefined)
        props.onBackButtonPressed()
    }

    function validForm() {
        if (name === "") {
            alert(t("geofences_page.validation_message.empty_name"))
            return false
        } else if (feature === undefined) {
            alert(t("geofences_page.validation_message.draw_shape"))
            return false
        }
        return true
    }

    async function handleSave() {
        if (validForm()) {
            let updatedGeofence = geofence
            updatedGeofence!.name = name
            updatedGeofence!.colour = colour
            updatedGeofence!.feature = feature!
            updatedGeofence!.category = geofenceCategories.find(cat => cat.id === category)
            updatedGeofence!.deletedAt = undefined
            await updateGeofence(updatedGeofence!)
            handleBack()
        }
    }

    async function handleCreate() {
        if (validForm()) {
            ReactGA.event("RT2-AddedGeofence");
            let newGeofence = new Geofence({
                name: name,
                colour: colour,
                feature: feature!,
                category: geofenceCategories.find(cat => cat.id === category),
                deletedAt : undefined,
            })

            await createGeofence(newGeofence)
            handleBack()
        }
    }

    async function handleDelete() {
        ReactGA.event("RT2-DeleteGeofence");
        await deleteGeofence(id?.toString() as string)
        handleBack()
    }

    async function editGeofenceShape()
    {
        if(!id) setFeature(undefined);  //On create clear the state as it clears the drawing
        document.getElementsByClassName("leaflet-draw-toolbar").item(0)?.getElementsByTagName("a").item(0)?.dispatchEvent(new MouseEvent('click', {view: window,
            bubbles: true,
            cancelable: true,
            buttons: 1
        }))
    }

    async function saveGeofenceEdits()
    {   
        setShowMustSaveDialog(true);
        
        document.getElementsByClassName("leaflet-draw-actions").item(0)?.childNodes[0]?.childNodes[0]?.dispatchEvent(new MouseEvent('click', {view: window,
            bubbles: true,
            cancelable: true,
            buttons: 1
        }))
    }

    async function revertGeofenceEdits()
    {
        document.getElementsByClassName("leaflet-draw-actions").item(0)?.childNodes[1]?.childNodes[0]?.dispatchEvent(new MouseEvent('click', {view: window,
            bubbles: true,
            cancelable: true,
            buttons: 1
        }))
    }

    async function revertGeofenceCreate()
    {
        setEditingFeature(false);
        document.getElementsByClassName("leaflet-draw-actions").item(0)?.childNodes[2]?.childNodes[0]?.dispatchEvent(new MouseEvent('click', {view: window,
            bubbles: true,
            cancelable: true,
            buttons: 1
        }))
    }

    

    return <Box component="main" sx={{
        flexGrow: 1, p: 0,
        height: 'calc(-67px + 100vh)', overflow: "hidden",
        overflowY: "scroll",
    }}>
        <LeftPanelButtonBar onBackPressed={handleBack}>
            {!loading && id && (
                <Button variant="contained" color="secondary" disabled={editingFeature} onClick={handleSave}>{t('button.save')}</Button>
            )}
            {!loading && newGeofence && (
                <Button variant="contained" color="secondary" disabled={editingFeature} onClick={handleCreate}>{t('button.create')}</Button>
            )}
        </LeftPanelButtonBar>

        {loading && (
            <Box sx={{ width: '100%', padding: '10px' }}>
                <LoadingSpinner />
            </Box>
        )}

        {!loading && (
            <Box sx={{ width: '100%', padding: '10px' }}>
                <TextInput editMode={!editingFeature} isEmail={false} label={t('geofences_page.wizard.geofence_name')} required={true} placeholder={t('geofences_page.wizard.geofence_name')} value={name} onChange={setName} />
                {!editingFeature && <SelectInput editMode={!editingFeature} label={t('geofences_page.wizard.geofence_category')} required={false} value={category?.toString() || ""} items={getCategories()} onChange={(newCategory) => setCategory(Number.parseInt(newCategory))} />}
                <Box sx={{ width: '100%', padding: '10px' }}>
                    {(editingFeature ? <p>{id ? t('geofences_page.wizard.disabled_color_picker') : t('geofences_page.wizard.disabled_when_creating') }</p> : <TwitterPicker width="100%" triangle="hide" color={colour} onChangeComplete={(newColor, event) => setColour(newColor.hex)} />)}
                  
                </Box>


{!id && !editingFeature && <Button variant="contained" fullWidth  style={{ marginBottom: '5px' }}  color={!feature ? 'primary' : 'error'} onClick={() => editGeofenceShape()}>{!feature ? t('geofences_page.wizard.create_shape_button') : t('geofences_page.wizard.create_shape_startover_button')}</Button>}
{id && !editingFeature && <Button variant="contained" fullWidth  style={{ marginBottom: '5px' }}  onClick={() => editGeofenceShape()}>{t('geofences_page.wizard.edit_shape_button')}</Button>}
              
              
                {!editingFeature && id && <Button variant="contained" fullWidth color="error" onClick={() => setShowDeleteDialog(true)}>{t('geofences_page.wizard.delete_button')}</Button>}

                <ConfirmationDialog open={showMustSaveDialog} title={t('geofences_page.mustSave_dialog.title')} description={t('geofences_page.mustSave_dialog.description')} confirmText={t('geofences_page.mustSave_dialog.confirmButton')}  onConfirm={() => setShowMustSaveDialog(false)} onCancel={() => setShowMustSaveDialog(false)}   />

                {id && editingFeature && <Button variant="contained" fullWidth style={{ marginBottom: '5px' }} color="secondary" onClick={() => saveGeofenceEdits()}>{t('geofences_page.wizard.save_shape_button')}</Button>}
               
                {id && editingFeature && <Button variant="contained" fullWidth  onClick={() => revertGeofenceEdits()}>{t('geofences_page.wizard.cancel_shape_button')}</Button>}
                {!id && editingFeature && <Button variant="contained" fullWidth  onClick={() => revertGeofenceCreate()}>{t('geofences_page.wizard.cancel_shape_button')}</Button>}

                <ConfirmationDialog open={showDeleteDialog} title={t('geofences_page.delete_dialog.title')} description={t('geofences_page.delete_dialog.description')} confirmText={t('button.delete')} cancelText={t('button.cancel')} onConfirm={handleDelete} onCancel={() => setShowDeleteDialog(false)} />
            </Box>
        )}
    </Box>
}
