export class Notification {
    id: number;
    title: string;
    description: string;
    unread: boolean;
    createdAt: Date;

    constructor(props: {
        id: number;
        title: string;
        description: string;
        unread: boolean;
        createdAt: Date;
    }) {
        this.id = props.id;
        this.title = props.title;
        this.description = props.description;
        this.unread = props.unread;
        this.createdAt = props.createdAt;
    }
}