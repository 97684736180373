import {Box, Button, Grid} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

interface ReportButtonBarProps {
    handleClearSelection: () => void;
    createDisabled: boolean;
    handleCreateReport: () => void;
}

export function ReportButtonBar(props: ReportButtonBarProps) {

    const { t } = useTranslation();

    return <Grid container paddingLeft="20px" paddingRight="20px">
        <Grid item xs={6} paddingTop="5px" paddingBottom="5px">
            <Box
                bottom="0px"
                sx={{
                    padding: '10px',
                    display: 'flex', flexDirection: "row", justifyContent: "space-between",
                }}>
                <Button
                    variant="contained"
                    size='small'
                    color="secondary"
                    onClick={props.handleClearSelection}
                >
                    {t("report.clear_selection_button")}
                </Button>
            </Box>
        </Grid>
        <Grid item xs={6} paddingTop="5px" paddingBottom="5px"
              sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box
                bottom="0px"
                sx={{
                    padding: '10px',
                    display: 'flex', flexDirection: "row", justifyContent: "space-between"
                }}>
                <Button
                    disabled={props.createDisabled}
                    variant="contained"
                    size='small'
                    color="primary"
                    onClick={props.handleCreateReport}
                >
                    {t("report.create_button")}
                </Button>
            </Box>
        </Grid>
    </Grid>
}