import { ButtonGroup, IconButton, Tooltip, useTheme } from '@mui/material';
import Control from 'react-leaflet-custom-control';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTranslation } from 'react-i18next';


interface ZoomMapControlProps {
    onZoomInClick: Function;
    onZoomOutClick: Function;
}

function ZoomMapControl(props: ZoomMapControlProps) {
    const theme = useTheme()

    const { t } = useTranslation();

    return (

        <Control position="topleft">
            <ButtonGroup orientation="vertical" variant="outlined" sx={{ borderColor: "#FFFFFF" }}>
                <Tooltip placement="right" title={t('map_controls.zoom_in')}>
                    <IconButton
                        size='small'
                        disableRipple
                        onClick={(event) => props.onZoomInClick(event)}
                        sx={{
                            backgroundColor: theme.palette.mapcontrol.background,
                            color: theme.palette.mapcontrol.foreground,
                            borderTopLeftRadius: '5px',
                            borderTopRightRadius: '5px',
                            borderBottomLeftRadius: '0px',
                            borderBottomRightRadius: '0px',
                            margin: '5px',
                            marginBottom: '0px',
                            '&:hover': {
                                backgroundColor: theme.palette.mapcontrol.backgroundHover,
                            },
                        }}
                    ><AddIcon /></IconButton>
                </Tooltip>
                <Tooltip placement="right" title={t('map_controls.zoom_out')}>
                    <IconButton
                        size='small'
                        disableRipple
                        onClick={(event) => props.onZoomOutClick(event)}
                        sx={{
                            backgroundColor: theme.palette.mapcontrol.background,
                            color: theme.palette.mapcontrol.foreground,
                            borderTopLeftRadius: '0px',
                            borderTopRightRadius: '0px',
                            borderBottomLeftRadius: '5px',
                            borderBottomRightRadius: '5px',
                            margin: '5px',
                            marginTop: '0px',
                            '&:hover': {
                                backgroundColor: theme.palette.mapcontrol.backgroundHover,
                            },
                        }}
                    ><RemoveIcon /></IconButton>
                </Tooltip>
            </ButtonGroup>
        </Control>







    );
}

export default ZoomMapControl;