import React, { createContext, useState } from 'react';
import { Feature } from 'geojson';

interface GeofenceWizardContextProps {
    newGeofence: boolean;
    setNewGeofence: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    id: number | undefined;
    setId: React.Dispatch<React.SetStateAction<number | undefined>>;
    editingFeature: boolean;
    setEditingFeature: React.Dispatch<React.SetStateAction<boolean>>;
    name: string;
    setName: React.Dispatch<React.SetStateAction<string>>;
    category: number | undefined;
    setCategory: React.Dispatch<React.SetStateAction<number | undefined>>;
    colour: string;
    setColour: React.Dispatch<React.SetStateAction<string>>;
    feature: Feature | undefined;
    setFeature: React.Dispatch<React.SetStateAction<Feature | undefined>>;
}

export const GeofenceWizardContext = createContext<GeofenceWizardContextProps>({
    newGeofence: false,
    setNewGeofence: () => { },
    loading: false,
    setLoading: () => { },
    id: undefined,
    setId: () => { },
    editingFeature: false,
    setEditingFeature: () => { },
    name: "",
    setName: () => { },
    category: undefined,
    setCategory: () => { },
    colour: "#7FFFD4",
    setColour: () => { },
    feature: undefined,
    setFeature: () => { },
});

interface Props {
    children: React.ReactNode;
}


export const GeofenceWizardProvider = ({ children }: Props) => {
    const [newGeofence, setNewGeofence] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [id, setId] = useState<number | undefined>(undefined);
    const [editingFeature, setEditingFeature] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [category, setCategory] = useState<number | undefined>(undefined);
    const [colour, setColour] = useState<string>("#7FFFD4");
    const [feature, setFeature] = useState<Feature | undefined>(undefined);

    return (
        <GeofenceWizardContext.Provider value={{ newGeofence, setNewGeofence, loading, setLoading, id, setId, editingFeature, setEditingFeature, name, setName, category, setCategory, colour, setColour, feature, setFeature }}>
            {children}
        </GeofenceWizardContext.Provider>
    );
}