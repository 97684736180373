import {Grid} from '@mui/material';
import DisplayAssetTable from '../../../components/table/shared/DisplayAssetTable';
import {Asset} from '../../../hooks/assets/dto/Asset';

interface ServiceAssetTableProps {
    loadingAssets: boolean;
    assets: Asset[] | null;
}

const ServiceAssetTable: React.FC<ServiceAssetTableProps> = ({
                                                                 loadingAssets,
                                                                 assets,
                                                             }) => {

    return (
        <Grid>
            <DisplayAssetTable
                loading={loadingAssets}
                assets={assets || []}
            />
        </Grid>
    )
};

export default ServiceAssetTable;
