import { useState, useEffect } from 'react';
import usePlatformFetch from '../shared/PlatformFetch';
import PropertiesLoader from "../../properties/PropertiesLoader";
const properties = PropertiesLoader();

export enum FeaturePackage {
    LITE = "LITE",
    CORE = "CORE",
    PLUS = "PLUS",
    LEGACY_LITE = "LEGACY_LITE",
    LEGACY_FULL = "LEGACY_FULL",
    EXPIRED = "EXPIRED"
}
export class Account {
    id: number;
    name: string;
    salesforceId: string;
    featurePackage: FeaturePackage;
    country: string;
    constructor(props: {
        id: number;
        name: string;
        salesforceId: string;
        featurePackage: FeaturePackage;
        country: string;
    }) {
        this.id = props.id;
        this.name = props.name;
        this.salesforceId = props.salesforceId;
        this.featurePackage = props.featurePackage;
        this.country = props.country;
    }
}

export class AccountContact {
    id: number;
    email: string;
    username: string;
    constructor(props: {
        id: number;
        email: string;
        username: string;
    }) {
        this.id = props.id;
        this.email = props.email;
        this.username = props.username;
    }
}

const useAccountApi = (shouldPoll?: boolean, pollFrequency?: number) => {
    const { platformFetch } = usePlatformFetch();
    const [account, setAccount] = useState<Account | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [accountContacts, setAccountContacts] = useState<AccountContact[] | null>(null);

    useEffect(() => {
        refreshAccount();
        getCurrentAccountContacts()

        if (shouldPoll) {
            const interval = setInterval(() => refreshAccount(true), pollFrequency || 30000);
            return () => clearInterval(interval);
        }
    }, []);

    const refreshAccount = async (disableLoading?: Boolean) => {
        try {
            setLoading(!disableLoading);
            const response = await platformFetch(properties.platformApiEndpoint + 'account/v1/accounts/me', 'get');
            if (response.status !== 200) {
                throw new Error(response.status.toString());
            }
            const result = await response.json() as Account;
            setAccount(result);
        } catch (err) {
            setError("Error fetching account");
        } finally {
            setLoading(false);
        }
    }

    const getCurrentAccountContacts = async () => {
        const response = await platformFetch(properties.platformApiEndpoint + 'account/v1/accounts/contacts', 'get');
        if (response.status !== 200) {
            throw new Error(response.status.toString());
        }
        const result = await response.json() as AccountContact[];
        setAccountContacts(result);
        return result;
    }

    const getAccountContacts = async (id: number) => {
        const response = await platformFetch(properties.platformApiEndpoint + `account/v1/accounts/${id}/contacts`, 'get');
        if (response.status !== 200) {
            throw new Error(response.status.toString());
        }
        const result = await response.json() as AccountContact[];
        return result;
    }

    const getAccounts = async () => {
        const response = await platformFetch(properties.platformApiEndpoint + 'account/v1/accounts/', 'get');
        if (response.status !== 200) {
            throw new Error(response.status.toString());
        }
        return await response.json() as Account[];
    }

    const getPaginatedAccounts = async (page = 0, size = 10, search = '') => {
        const response = await platformFetch(properties.platformApiEndpoint + `account/v1/accounts/paginated?page=${page}&pageSize=${size}&search=${search}`, 'get');
        if (response.status !== 200) {
            throw new Error(response.status.toString());
        }
        const responseData = await response.json();
        return {
            accounts: responseData.content as Account[],
            totalPages: responseData.totalPages as number,
            totalElements: responseData.totalElements as number,
            size: responseData.size as number,
            number: responseData.number as number
        };
    }

    return { account, accountContacts,error, loading, refreshAccount, getCurrentAccountContacts, getAccountContacts, getAccounts, getPaginatedAccounts };
};

export default useAccountApi;
