import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { colors } from "../../themes/AppTheme";
import { FileFormat } from "../../enum/FileFormat";

interface FileFormatSelectionProps {
    selectedFileFormat: string;
    setSelectedFileFormat: (selectedFileFormat: FileFormat) => void;
}

export default function FileFormatSelection(props: FileFormatSelectionProps) {

    function handleFileFormatChange(event: SelectChangeEvent<any>) {
        props.setSelectedFileFormat(event.target.value);
    }

    return (
        <Select
            id="file_format"
            size="small"
            value={props.selectedFileFormat}
            color="info"
            style={{ height: '100%', fontSize: '12px', color: colors.ram_purple }}
            onChange={handleFileFormatChange}
            fullWidth
        >
            <MenuItem key="file-format" style={{ height: 25, fontSize: '12px' }} value={FileFormat.PDF}>{"PDF"}</MenuItem>
            <MenuItem key="file-format" style={{ height: 25, fontSize: '12px' }} value={FileFormat.XLS}>{"Excel"}</MenuItem>
            <MenuItem key="file-format" style={{ height: 25, fontSize: '12px' }} value={FileFormat.DOC}>{"Word"}</MenuItem>
            <MenuItem key="file-format" style={{ height: 25, fontSize: '12px' }} value={FileFormat.HTML}>{"HTML"}</MenuItem>
        </Select>
    );
}
