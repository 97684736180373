import React, { createContext, useState, useMemo, useEffect } from 'react';

interface ImpersonateUserContextProps {
    impersonateUsername: string | undefined;
    setImpersonateUsername: React.Dispatch<React.SetStateAction<string | undefined>>;
    impersonateId: number | undefined;
    setImpersonateId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const ImpersonateUserContext = createContext<ImpersonateUserContextProps>({
    impersonateUsername: undefined,
    setImpersonateUsername: () => {},
    impersonateId: undefined,
    setImpersonateId: () => {},
});

interface Props {
    children: React.ReactNode;
}

export const ImpersonateUserProvider = ({ children }: Props) => {
    const [impersonateUsername, setImpersonateUsername] = useState<string | undefined>(() => {
        // Load from localStorage on component mount
        return localStorage.getItem('impersonateUsername') || undefined;
    });
    const [impersonateId, setImpersonateId] = useState<number | undefined>(() => {
        // Load from localStorage on component mount
        const storedId = localStorage.getItem('impersonateId');
        return storedId ? parseInt(storedId, 10) : undefined;
    });

    const contextValue = useMemo(() => ({
        impersonateUsername,
        setImpersonateUsername,
        impersonateId,
        setImpersonateId,
    }), [impersonateUsername, setImpersonateUsername, impersonateId, setImpersonateId]);

    // Save to localStorage whenever the state changes
    useEffect(() => {
        localStorage.setItem('impersonateUsername', impersonateUsername || '');
        localStorage.setItem('impersonateId', impersonateId?.toString() || '');
    }, [impersonateUsername, impersonateId]);

    return (
        <ImpersonateUserContext.Provider value={contextValue}>
            {children}
        </ImpersonateUserContext.Provider>
    );
};
