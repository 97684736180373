export enum ReportDaysCovered {
    ONE_DAY = 1,
    TWO_DAYS = 2,
    THREE_DAYS = 3,
    FOUR_DAYS = 4,
    FIVE_DAYS = 5,
    SIX_DAYS = 6,
    SEVEN_DAYS = 7,
    EIGHT_DAYS = 8,
    NINE_DAYS = 9,
    TEN_DAYS = 10,
    ELEVEN_DAYS = 11,
    TWELVE_DAYS = 12,
    THIRTEEN_DAYS = 13,
    FOURTEEN_DAYS = 14,
    FIFTEEN_DAYS = 15,
    SIXTEEN_DAYS = 16,
    SEVENTEEN_DAYS = 17,
    EIGHTEEN_DAYS = 18,
    NINETEEN_DAYS = 19,
    TWENTY_DAYS = 20,
    TWENTY_ONE_DAYS = 21,
    TWENTY_TWO_DAYS = 22,
    TWENTY_THREE_DAYS = 23,
    TWENTY_FOUR_DAYS = 24,
    TWENTY_FIVE_DAYS = 25,
    TWENTY_SIX_DAYS = 26,
    TWENTY_SEVEN_DAYS = 27,
    TWENTY_EIGHT_DAYS = 28,
    TWENTY_NINE_DAYS = 29,
    THIRTY_DAYS = 30,
    THIRTY_ONE_DAYS = 31
}
