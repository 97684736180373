import React, {createContext, useState} from 'react';
import {SsoUser} from '../hooks/user/dto/SsoUser';

interface UserAccountContextProps {
    user?: SsoUser;
    setUser: React.Dispatch<React.SetStateAction<SsoUser | undefined>>;
}

export const UserAccountContext = createContext<UserAccountContextProps>({
    user: undefined,
    setUser: () => {
    },
});

interface Props {
    children: React.ReactNode;
}

export const UserProvider = ({children}: Props) => {
    const [user, setUser] = useState<SsoUser | undefined>(undefined);

    return (
        <UserAccountContext.Provider value={{user, setUser}}>
            {children}
        </UserAccountContext.Provider>
    );
};
