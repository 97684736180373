import {AlertColor} from "@mui/material";
import React, {useContext, useState} from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import SplitView from "../../layouts/SplitView";
import {ReportPage, ReportsSecondaryNav} from "./ReportsSecondaryNavigation";
import {VehicleReportsPage} from "./VehicleReportsPage";
import {ReportResponse} from "../../hooks/reports/dto/ReportResponse";
import {ReportRequest} from "../../hooks/reports/dto/ReportRequest";
import {useReportsApi} from "../../hooks/reports/Reports";
import FullView from "../../layouts/FullView";
import {ReportReadyPage} from "./reportready/ReportReadyPage";
import ScheduledVehicleReportsPage from "./scheduled/ScheduledVehicleReportsPage";
import {GeneratorReportsPage} from "./generator/GeneratorReportsPage";
import propertiesLoader from "../../properties/PropertiesLoader";
import { ReportPreferencesContext } from "../../providers/ReportPreferencesProvider";
import {EmailReadyPage} from "./reportready/EmailReadyPage";
const properties = propertiesLoader();

function ReportsPage() {
    const reportPreferencesContext = useContext(ReportPreferencesContext)

    const [isReportMessageOpen, setIsReportMessageOpen] = useState(false);
    const [reportMessage, setReportMessage] = useState('');
    const [severity, setSeverity] = useState<AlertColor>();

    const showReportMessage = (message: string, severity: AlertColor) => {
        setReportMessage(message);
        setSeverity(severity);
        setIsReportMessageOpen(true);
    };

    const handleReportMessageClose = () => {
        setIsReportMessageOpen(false);
    };

    const secondaryNav = <ReportsSecondaryNav selectedReportPage={reportPreferencesContext.selectedReportPage} setSelectedReportPage={reportPreferencesContext.setSelectedReportPage}/>

    const {generateReport} = useReportsApi();
    const [isGenerating, setIsGenerating] = useState(false);
    const [reportUrl, setReportUrl] = useState<string | undefined>(undefined);
    const [reportRequest, setReportRequest] = useState<ReportRequest | undefined>(undefined);

    async function requestReport(request: ReportRequest) {
        setIsGenerating(true);

        let reportResponse: ReportResponse = await generateReport(request) as ReportResponse;

        if (reportResponse.success) {
            if (reportResponse.link) {
                setReportRequest(request)
                setReportUrl(reportResponse.link);
            } else {
                showReportMessage(reportResponse.errorMessage, "success");
            }
        } else {
            showReportMessage(reportResponse.errorMessage, "error");
        }
        setIsGenerating(false);
    }

    function handleBackToReports() {
        setIsGenerating(true)
        setReportUrl(undefined);
        setReportRequest(undefined);
        setIsGenerating(false);
    }

    let content;

    switch (reportPreferencesContext.selectedReportPage) {
        case ReportPage.VEHICLES:
            content = <VehicleReportsPage showReportMessage={showReportMessage} requestReport={requestReport}/>;
            break;
        case ReportPage.SCHEDULED_VEHICLES:
            content = <ScheduledVehicleReportsPage/>;
            break
        case properties.featureFlags.generatorReport && ReportPage.GENERATORS:
            content = <GeneratorReportsPage showReportMessage={showReportMessage} requestReport={requestReport}/>;
            break
        default :
            content = <></>;
    }

    const reportWizard = <SplitView
        scondaryNavigation={secondaryNav}
        content={content}/>

    const reportReadyPage = <FullView content={
        <ReportReadyPage
            isGenerating={isGenerating}
            handleBackToReports={handleBackToReports}
            reportLink={reportUrl}
            reportType={reportRequest?.reportType}
            reportFormat={reportRequest?.reportFormat}
            reportDateRange={reportRequest ? reportRequest.from.toLocaleDateString() + " - " + reportRequest?.to.toLocaleDateString() : undefined}
        />
    }/>

    const emailReadyPage = <FullView content={
        <EmailReadyPage
            isGenerating={isGenerating}
            handleBackToReports={handleBackToReports}
            reportType={reportRequest?.reportType}
            reportFormat={reportRequest?.reportFormat}
            reportDateRange={reportRequest ? reportRequest.from.toLocaleDateString() + " - " + reportRequest?.to.toLocaleDateString() : undefined}
        />
    }/>

    return (
        <>
            {(() => {
                if(reportUrl === 'Emails are being processed'){
                    return emailReadyPage;
                }

                if (reportUrl || isGenerating) {
                    if (reportRequest?.forSummaryReport) {
                        return emailReadyPage;
                    } else {
                        return reportReadyPage;
                    }
                } else {
                    return reportWizard;
                }
            })()}

            <Snackbar open={isReportMessageOpen} autoHideDuration={5000}
                      anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center'
                      }}
                      onClose={handleReportMessageClose}>
                <MuiAlert elevation={6} variant="filled" severity={severity}>
                    {reportMessage}
                </MuiAlert>
            </Snackbar>
        </>
    );
}


export default ReportsPage;
