export enum FontSizeName {
    BODY,
    CAPTION,
    TITLE,
    SMALL_TITLE,
    HEADER,
    LARGE_HEADER,
    SMALL_HEADER,

}

export const FontSizes = {
    [FontSizeName.LARGE_HEADER]: 22,
    [FontSizeName.HEADER]: 20,
    [FontSizeName.SMALL_HEADER]: 18,
    [FontSizeName.TITLE]: 16,
    [FontSizeName.SMALL_TITLE]: 14,
    [FontSizeName.BODY]: 13,
    [FontSizeName.CAPTION]: 12,
}

export function getFontSize(size: FontSizeName, largerFont: Boolean): string {
    if (largerFont) {
        return (FontSizes[size] * 1.25) + "px";
    } else {
        return FontSizes[size] + "px";
    }
}
