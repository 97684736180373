import React, {useState} from 'react';
import {Box, Grid, InputAdornment, TextField} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SelectAssetTable from '../../../components/table/shared/SelectAssetTable';
import {Asset} from '../../../hooks/assets/dto/Asset';
import {AssetSortOptions, sortByDriverName, sortByLastEventTime} from '../../../utils/AssetArraySorting';
import {filterAssetsBySearch} from '../../../utils/AssetArrayFiltering';

interface ServiceAssetSelectorProps {
    loadingAssets: boolean;
    assets: Asset[] | null;
    selectedAssetRows: number[];
    setSelectedAssetRows: React.Dispatch<React.SetStateAction<number[]>>;
    label: string;
    sort: AssetSortOptions | undefined;
}

const ServiceAssetSelector: React.FC<ServiceAssetSelectorProps> = ({
                                                                       loadingAssets,
                                                                       assets,
                                                                       selectedAssetRows,
                                                                       setSelectedAssetRows,
                                                                       label,
                                                                       sort
                                                                   }) => {
    const [assetFilter, setAssetFilter] = useState<string | undefined>(undefined);

    const onAssetSearchChange = (search: string) => {
        setAssetFilter(search === "" ? undefined : search);
    };

    const filterAssets = (assets: Array<Asset>) => {
        let filteredAssets = filterAssetsBySearch(assets, assetFilter);

        switch (sort) {
            case AssetSortOptions.DRIVER_NAME:
                filteredAssets.sort(sortByDriverName);
                break;
            case AssetSortOptions.LAST_REPORTED:
                filteredAssets.sort(sortByLastEventTime);
                break;
            default:
                break;
        }

        return filteredAssets;
    };

    return (
        <>
            <Box sx={{paddingBottom: '10px'}}>
                <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    label={label}
                    variant="outlined"
                    color="info"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon/>
                            </InputAdornment>
                        ),
                    }}
                    value={assetFilter}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        onAssetSearchChange(event.target.value);
                    }}
                />
            </Box>
            <Grid>
                <SelectAssetTable
                    multiselect={false}
                    disableSelect={false}
                    loading={loadingAssets}
                    assets={filterAssets(assets || [])}
                    selectedRows={selectedAssetRows}
                    setSelectedRows={setSelectedAssetRows}
                />
            </Grid>
        </>
    );
};

export default ServiceAssetSelector;
