import {AssetEvent, AssetStatus} from "../hooks/assets/dto/Asset";
import {colors, StatusColors} from "../themes/AppTheme";
import {isWarningEvent} from "./AssetEventUtils";

export function getColorByAssetStatus(status: AssetStatus, statusColors: StatusColors): string {
    if (status === AssetStatus.MOVING) {
        return statusColors.moving;
    } else if (status === AssetStatus.STOPPED) {
        return statusColors.stopped;
    } else if (status === AssetStatus.STATIONARY) {
        return statusColors.stationary;
    }

    return statusColors.moving;
}

export function getColorByAssetEvent(event: AssetEvent | undefined, statusColors: StatusColors): string {
    if(!event) {
        return statusColors.inactive;
    }
    if (isWarningEvent(event.speed, event.speedLimit, event.type)) {
        return colors.ram_orange;
    }

    return getColorByAssetStatus(event.status, statusColors);
}
