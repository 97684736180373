import { Box, Grid, useTheme } from "@mui/material";
import ProgressPanel from "./components/progresspanel/ProgressPanel";
import { useContext, useState } from "react";
import LanguageSelectionStep from "./components/steps/LanguageSelectionStep";
import PersonalInformationStep from "./components/steps/PersonalInformationStep";
import CustomiseExperienceStep from "./components/steps/CustomiseExperienceStep";
import AccessibilityStep from "./components/steps/AccessibilityStep";
import { UserPreferences } from "../../hooks/user/dto/UserPreferences";
import { UserPreferencesContext } from "../../providers/UserPreferencesProvider";
import useUserApi from "../../hooks/user/User";

export default function SetupWizard() {
    const theme = useTheme();
    const { setUserPreferences } = useUserApi();
    const [step, setStep] = useState(0);

    const {
        colorPalette, setColorPalette,
        largeText, setLargeText,
        setOpenDrawer,
        languageCode, setLanguageCode,
        clusterEnabled, setClusterEnabled,
        labelsEnabled, setLabelsEnabled,
        mapType, setMapType,
        labelType, setLabelType,
        twentyFourHourEnabled, setTwentyFourHourEnabled,
        setPreferencesLoaded
    } = useContext(UserPreferencesContext)

    async function createUserPreferences() {

        const userPreferences: UserPreferences = {
            colorPalette: colorPalette,
            largeText: largeText,
            openDrawer: true,
            languageCode: languageCode,
            clusterEnabled: clusterEnabled,
            labelsEnabled: labelsEnabled,
            mapType: mapType,
            labelType: labelType,
            twentyFourHourEnabled: twentyFourHourEnabled
        }

        const preferencesResponse = await setUserPreferences(userPreferences) as UserPreferences
        setColorPalette(preferencesResponse.colorPalette)
        setLargeText(preferencesResponse.largeText)
        setOpenDrawer(preferencesResponse.openDrawer)
        setLanguageCode(preferencesResponse.languageCode)
        setClusterEnabled(preferencesResponse.clusterEnabled)
        setLabelsEnabled(preferencesResponse.labelsEnabled)
        setMapType(preferencesResponse.mapType)
        setLabelType(preferencesResponse.labelType)
        setTwentyFourHourEnabled(preferencesResponse.twentyFourHourEnabled)
        setPreferencesLoaded(true)
    }


    return (
        <Box component="main" sx={{ flexGrow: 1, p: 1, backgroundColor: theme.palette.loginwizard.background }}>
            <Grid container sx={{ minHeight: 'calc(100vh - 43px)', }} padding="10px" spacing={2.5}>
                <Grid item xs={12} md={3} lg={3}>
                    <ProgressPanel step={step} />
                </Grid>
                <Grid item xs={12} md={9} lg={9}>
                    {step === 0 && <LanguageSelectionStep onContinue={() => setStep(step + 1)} />}
                    {step === 1 && <PersonalInformationStep onContinue={() => setStep(step + 1)} onBack={() => setStep(step - 1)} />}
                    {step === 2 && <CustomiseExperienceStep onContinue={() => setStep(step + 1)} onBack={() => setStep(step - 1)} />}
                    {step === 3 && <AccessibilityStep onContinue={createUserPreferences} onBack={() => setStep(step - 1)} />}
                </Grid>
            </Grid>
        </Box>
    );
}

