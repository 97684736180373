import React, { useContext } from "react";

import { Map } from "leaflet";
import { GeofenceDetailPanel } from "./GeofenceDetailPanel";
import { GeofencesListPanel } from "./GeofencesListPanel";
import { calculateBoundsOfAllFeatures } from "../../utils/GeofenceUtils";
import { GeofenceWizardContext } from "../../../../providers/GeofenceWizardProvider";
import { Geofence } from "../../../../hooks/geofences/dto/Geofence";

interface GeofencesPanelProps {
    mapref: React.RefObject<Map>;
    loading: boolean;
    geofences: Geofence[];
    updateGeofences: Function;
}

function GeofencesPanel(props: GeofencesPanelProps) {

    const { newGeofence, id, setId, setFeature } = useContext(GeofenceWizardContext);

    function onBackPressed() {
        props.updateGeofences()
        setId(undefined)
        setFeature(undefined)
        let features = props.geofences.map(geofence => geofence.feature)
        props.mapref.current?.fitBounds(calculateBoundsOfAllFeatures(features))
    }

    const geofencesListPanel = <GeofencesListPanel
        loading={props.loading}
        mapref={props.mapref}
        geofences={props.geofences}
    />

    const geofenceDetailPanel = <GeofenceDetailPanel
        onBackButtonPressed={onBackPressed}
    />

    return id || newGeofence ? geofenceDetailPanel : geofencesListPanel;
}

export default GeofencesPanel;