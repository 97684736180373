import {LiveShareLink} from "./dto/LiveShareLink";

export function mapToLiveShareLink(liveShareLink: any) {
    return new LiveShareLink({
        id: liveShareLink.id,
        uuid: liveShareLink.uuid,
        url: liveShareLink.url,
        availabilityType: liveShareLink.availabilityType,
        expiresAt: liveShareLink.expiresAt,
        linkPeriod: liveShareLink.linkPeriod,
        startTime: liveShareLink.startTime,
        endTime: liveShareLink.endTime,
        assets: liveShareLink.assets
    })
}
