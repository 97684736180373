import { Box, Button, FormControl, FormLabel, RadioGroup, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import RadioButton from '../../../../components/form/RadioButton';
import ToggleInput from '../../../../components/form/ToggleInput';
import { UserPreferencesContext } from '../../../../providers/UserPreferencesProvider';
import { MapLayer } from '../../../../components/map/MapTileLayer';
import TuneIcon from '@mui/icons-material/Tune';
import StepHeader from '../shared/StepHeader';
import StepContainer from '../shared/StepContainer';

interface CustomiseExperienceStepProps {
    onContinue: () => void;
    onBack: () => void;
}

export default function CustomiseExperienceStep(props: CustomiseExperienceStepProps) {
    const { t } = useTranslation();

    const { clusterEnabled, setClusterEnabled, labelsEnabled, setLabelsEnabled, mapType, setMapType, twentyFourHourEnabled, setTwentyFourHourEnabled } = useContext(UserPreferencesContext);

    return (
        <StepContainer>
                <StepHeader
                    icon={<TuneIcon />}
                    title={t('setup_wizard.customise_experience_step.title')}
                    description={t('setup_wizard.customise_experience_step.description')}
                />

                <FormControl fullWidth
                    sx={{
                        textAlign: "left"
                    }}>
                    <FormLabel id="demo-radio-buttons-group-label">
                        <Typography variant="smallTitle">
                            {t('setup_wizard.customise_experience_step.map_style.label')}
                        </Typography>
                    </FormLabel>
                    <RadioGroup
                        name="radio-buttons-group"
                    >
                        <RadioButton mainText={t('setup_wizard.customise_experience_step.map_style.options.google_road.label')} subText={t('setup_wizard.customise_experience_step.map_style.options.google_road.description') as string} selected={mapType === MapLayer.GOOGLE_ROAD_MAP} onClick={() => { setMapType(MapLayer.GOOGLE_ROAD_MAP) }} />
                        <RadioButton mainText={t('setup_wizard.customise_experience_step.map_style.options.google_hybrid')} selected={mapType === MapLayer.GOOGLE_HYBRID_MAP} onClick={() => { setMapType(MapLayer.GOOGLE_HYBRID_MAP) }} />
                        <RadioButton mainText={t('setup_wizard.customise_experience_step.map_style.options.osm')} selected={mapType === MapLayer.OSM_DEFAULT} onClick={() => { setMapType(MapLayer.OSM_DEFAULT) }} />
                    </RadioGroup>
                </FormControl>

            <FormControl fullWidth
                         sx={{
                             textAlign: "left"
                         }}>
                <FormLabel id="demo-radio-buttons-group-label">
                    <Typography variant="smallTitle">
                        {t('setup_wizard.customise_experience_step.clock_type.select_label')}
                    </Typography>
                </FormLabel>
                <RadioGroup
                    name="radio-buttons-group"
                >
                    <RadioButton mainText={t('setup_wizard.customise_experience_step.clock_type.options.24_hour')} selected={twentyFourHourEnabled} onClick={() => { setTwentyFourHourEnabled(true) }} />
                    <RadioButton mainText={t('setup_wizard.customise_experience_step.clock_type.options.12_hour')} selected={!twentyFourHourEnabled} onClick={() => { setTwentyFourHourEnabled(false) }} />
                </RadioGroup>
            </FormControl>

                <Box
                    sx={{ textAlign: "left" }}
                >
                    <ToggleInput mainText={t('setup_wizard.customise_experience_step.map_clustering.label')} subText={t('setup_wizard.customise_experience_step.map_clustering.description') as string} selected={clusterEnabled} onToggle={() => { setClusterEnabled(!clusterEnabled) }} />
                    <ToggleInput mainText={t('setup_wizard.customise_experience_step.asset_labels.label')} subText={t('setup_wizard.customise_experience_step.asset_labels.description') as string} selected={labelsEnabled} onToggle={() => { setLabelsEnabled(!labelsEnabled) }} />

                </Box>
                <Box>
                    <Button color='primary' sx={{ marginBottom: "5px" }} fullWidth variant="contained" onClick={props.onContinue}>Continue</Button>
                    <Button color='secondary' fullWidth variant="contained" onClick={props.onBack}>Back</Button>
                </Box>
        </StepContainer>
    );
};





