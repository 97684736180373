import {Box, Grid, Radio, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {SelectorLabel} from "./SelectorLabel";
import {ReportType} from "../../../enum/ReportType";

export interface ReportStyle {
    name: string;
    description: string;
    reportTypes: ReportType[];
    multiAsset: boolean;
}

export const VEHICLE_SINGLE_REPORTS = {
    name: "report.single_reports",
    description: "report.single_reports_description",
    reportTypes: [ReportType.TRAVEL_REPORT,
        ReportType.PRIVATE_REPORT,
        ReportType.BUSINESS_REPORT,
        ReportType.STOP_REPORT,
        ReportType.DETAIL_REPORT,
        ReportType.IDLE_REPORT,
        ReportType.SUMMARY_REPORT,
        ReportType.GREEN_REPORT,
        ReportType.SUMMARIZED_DETAIL_REPORT],
    multiAsset: false
};

export const VEHICLE_GROUP_REPORTS = {
    name: "report.group_reports",
    description: "report.group_reports_description",
    reportTypes: [ReportType.TRAVEL_REPORT,
        ReportType.PRIVATE_REPORT,
        ReportType.BUSINESS_REPORT,
        ReportType.STOP_REPORT,
        ReportType.IDLE_REPORT,
        ReportType.SUMMARY_REPORT,
        ReportType.GREEN_REPORT],
    multiAsset: true
};

export const VEHICLE_SUMMARY_REPORTS = {
    name: "report.summary_reports",
    description: "report.summary_reports_description",
    reportTypes: [ReportType.TRAVEL_SUMMARY_REPORT],
    multiAsset: true
};

export const GENERATOR_SINGLE_REPORTS = {
    name: "report.single_reports",
    description: "report.single_reports_description",
    reportTypes: [
        ReportType.GENERATOR_DETAIL_REPORT],
    multiAsset: false
};

export const VEHICLE_SINGLE_FINANCIAL_REPORTS = {
    name: "report.single_financial_reports",
    description: "report.single_financial_reports_description",
    reportTypes: [ReportType.TRAVEL_REPORT_FINANCE,
        ReportType.PRIVATE_REPORT_FINANCE,
        ReportType.BUSINESS_REPORT_FINANCE,
        ReportType.STOP_REPORT_FINANCE,
        ReportType.LOCATION_STOP_REPORT_FINANCE,
        ReportType.STOP_LOCATION_FINANCE_REPORT,
        ReportType.PRIVATE_REPORT_DISTANCE_ONLY],
    multiAsset: false
};

export const VEHICLE_GROUP_FINANCIAL_REPORTS = {
    name: "report.group_financial_reports",
    description: "report.group_financial_reports_description",
    reportTypes: [ReportType.TRAVEL_REPORT_FINANCE,
        ReportType.PRIVATE_REPORT_FINANCE,
        ReportType.BUSINESS_REPORT_FINANCE,
        ReportType.STOP_REPORT_FINANCE,
        ReportType.LOCATION_STOP_REPORT_FINANCE,
        ReportType.TIMESHEET_REPORT_FINANCE],
    multiAsset: true
};


export const VEHICLE_REPORT_GROUPS = [VEHICLE_SINGLE_REPORTS, VEHICLE_GROUP_REPORTS, VEHICLE_SUMMARY_REPORTS,
    VEHICLE_SINGLE_FINANCIAL_REPORTS, VEHICLE_GROUP_FINANCIAL_REPORTS];
export const GENERATOR_REPORT_GROUPS = [GENERATOR_SINGLE_REPORTS];

interface ReportStyleSelectorProps {
    availableReportStyles: ReportStyle[];
    selectedReportStyle: ReportStyle;
    handleReportStyleChange: (selectedReportStyle: ReportStyle) => void;
}

export function ReportStyleSelector(props: ReportStyleSelectorProps) {

    const {t} = useTranslation();

    return <Grid container>
        <SelectorLabel
            title={t('report.report_style')}
            subtitle={t('report.select_report_style')}
        />
        <Grid item xs={12} md={12} lg={10} padding="20px">
            <Grid container spacing={2} flexDirection="row">
                {props.availableReportStyles.map((reportStyle) => (
                    <Grid key={"report-style-" + reportStyle.name} item xs={12} md={4} lg={4} xl={3}>
                        <Box
                            border={2}
                            borderRadius={3}
                            borderColor={"divider"}
                            padding={2}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="space-between"
                            onClick={() => props.handleReportStyleChange(reportStyle)}
                            sx={{
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <Box>
                                <Typography align={"center"} variant="title">
                                    {t(reportStyle.name)}
                                </Typography>
                                <Typography align={"center"} variant="body">
                                    {t(reportStyle.description)}
                                </Typography>
                            </Box>
                            <Box sx={{alignSelf: "center"}}>
                                <Radio
                                    checked={props.selectedReportStyle === reportStyle}
                                />
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    </Grid>
}
