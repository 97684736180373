import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ExclamationIcon from '@mui/icons-material/Warning'
import { Button } from '@mui/material';
import { useIntercom } from 'react-use-intercom';
import { useTranslation } from 'react-i18next';


interface FeatureRestrictionPageProps {
  title: string;
  description: string;
}

function FeatureRestrictionPage(props: FeatureRestrictionPageProps) {
  const {trackEvent} = useIntercom();
  const {t} = useTranslation();

  return (
    <div style={{ backgroundColor: '#efeded', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 45px)', width: '100vw' }}>
      <Card sx={{ maxWidth: 600 }}>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '50px' }}>
          <div style={{ display: 'flex', gap: '16px' }}>
            <div style={{ width: '70px', height: '70px', backgroundColor: '#ff5f00', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <ExclamationIcon style={{ fontSize: '40px', color: 'white' }} />
            </div>
          </div>
          <Typography gutterBottom variant="h5" component="div" style={{ marginTop: '16px', textAlign: 'center' }}>
            {props.title}
          </Typography>
          <Typography variant="body" color="text.secondary" style={{ textAlign: 'center' }}>
            {props.description}
          </Typography>
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>


          {<Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={() => trackEvent('package-upgrade-requested')}
                            sx={{
                                marginTop: '20px',
                                marginBottom: '20px'
                            }}>
                            {t('feature_package.upgrade_button')}
                        </Button> }

          
        </CardActions>
      </Card>
    </div>
  );


};

export default FeatureRestrictionPage;
