
import usePlatformFetch from "../shared/PlatformFetch";
import PropertiesLoader from "../../properties/PropertiesLoader";
import {mapToAssetsArray} from "../assets/AssetMapper";
import {Asset} from "../assets/dto/Asset";
import {Geofence} from "../geofences/dto/Geofence";

const properties = PropertiesLoader();

export class Location {
    displayName: string;
    latitude: number;
    longitude: number;

    constructor(props: { displayName: string; latitude: number; longitude: number }) {
        this.displayName = props.displayName;
        this.latitude = props.latitude;
        this.longitude = props.longitude;
    }
}

export class SearchResults {
    assets: Asset[];
    postcodes: Location[];
    geofences: Geofence[];


    constructor(props: { assets: Asset[]; postcodes: Location[]; geofences: Geofence[] }) {
        this.assets = props.assets;
        this.postcodes = props.postcodes;
        this.geofences = props.geofences;
    }
}


function mapSearchResults(json: any): SearchResults {
    return new SearchResults({
        assets: mapToAssetsArray(json.assets),
        postcodes: json.postcodes as Location[],
        geofences: json.geofences as Geofence[]
    });
}

export function useSearchApi() {
    const { platformFetch, checkOk } = usePlatformFetch();

    const performSearch = async (input: String) : Promise<SearchResults> => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + "tracking/v1/search/" + input,
                'get')
                .then(checkOk)
                .then(response => response.json())
                .then(json => mapSearchResults(json))
                .then((searchResults: SearchResults) => resolve(searchResults))
                .catch((error) => {
                    console.error("Error getting search results: " + error)
                    reject(error)
                });
        });
    }



    return { performSearch };
}