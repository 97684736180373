import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Checkbox, Paper, Table, TablePagination, useTheme} from "@mui/material";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import {AccountContact} from "../../../hooks/account/Account";
import {colors} from "../../../themes/AppTheme";

interface ImpersonateAccountContactTableProps {
    accountContacts: AccountContact[];
    selectedRow: number | undefined;
    setSelectedRow: (selectedRow: number) => void;
}

export default function ImpersonateAccountContactTable(props: ImpersonateAccountContactTableProps) {
    const {t} = useTranslation();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleRowSelect = (accountContactId: number) => {
        props.setSelectedRow(accountContactId);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const theme = useTheme();

    //Directs to the first page to see the search made in the search bar
    useEffect(() => {
        setPage(0);
    }, [props.accountContacts]);

    return (
        <TableContainer id="impersonate-account-contact-table-panel" component={Paper} sx={{height: '100%'}}>
            <Table aria-label="simple table" size={"small"}>
                <TableHead sx={{backgroundColor: colors.ram_light_blue}}>
                    <TableRow>
                        <TableCell/>
                        <TableCell>{t("impersonate_page.username")}</TableCell>
                        <TableCell>{t("impersonate_page.email")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.accountContacts.slice(startIndex, endIndex).map((accountContact) => (
                        <TableRow
                            key={accountContact + "-selection-row"}
                            sx={{
                                backgroundColor: theme.palette.contentPage.listBackground,
                                '&:hover': {backgroundColor: theme.palette.contentPage.listHoverBackground,},
                                '&:last-child td, &:last-child th': {border: 0}
                            }}
                        >
                            <TableCell>
                                <Checkbox
                                    key={accountContact + "-selection-checkbox"}
                                    checked={props.selectedRow === accountContact.id}
                                    onChange={() => handleRowSelect(accountContact.id)}
                                />
                            </TableCell>
                            <TableCell>{accountContact.username}</TableCell>
                            <TableCell>{accountContact.email}</TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination count={props.accountContacts.length}
                             page={page}
                             rowsPerPage={rowsPerPage}
                             onPageChange={handleChangePage}
                             rowsPerPageOptions={[10]}
                             onRowsPerPageChange={handleChangeRowsPerPage}
                             ActionsComponent={TablePaginationActions}/>
        </TableContainer>
    );
}
