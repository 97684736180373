import {Box, Tooltip} from '@mui/material';
import React, {useContext} from 'react';
import {useAssetApi} from '../../../hooks/assets/Assets';
import {EventAlert, useEventAlertsApi} from '../../../hooks/alerts/EventAlerts';
import {Table, TableCell, TableRow} from '../../../components/table/core/Table';
import {useTranslation} from 'react-i18next';
import {formatTime} from '../../../utils/TimeUtils';
import {UserPreferencesContext} from '../../../providers/UserPreferencesProvider';
import {RowDeleteButton, RowEditButton} from '../../../components/table/core/TableButtons';
import ConfirmationDialog from '../../../components/dialog/ConfirmationDialog';
import {useNavigate} from 'react-router-dom';
import FilterBar from '../../../components/filterbar/FilterBar';

interface EventAlertsPageProps {
}

export default function EventAlertsPage(props: EventAlertsPageProps) {
    const {t} = useTranslation();
    const {assets} = useAssetApi({shouldLoadAll: true});
    const {eventAlerts, getAllEventAlerts, loading} = useEventAlertsApi({shouldLoadAll: true})
    const [searchInput, setSearchInput] = React.useState<string>("");
    const navigate = useNavigate();

    const getIdentificationMark = (id: number): string => {
        const asset = assets?.find(asset => asset.id === id);
        if (asset) {
            if (asset.alias) {
                return asset.alias;
            } else if (asset.identificationMark) {
                return asset.identificationMark;
            }
        }
        return "";
    }

    function getFilteredAlerts() {
        if (eventAlerts === null) {
            return [];
        } else if (searchInput === undefined || searchInput === "") {
            return eventAlerts || [];
        } else {
            const lowerCaseFilter = searchInput.toLowerCase();
            return eventAlerts.filter(alert => alert.emailAddress.some(email => email.toLowerCase().includes(lowerCaseFilter)) ||
                getIdentificationMark(alert.vehicleId).toLowerCase().includes(lowerCaseFilter));

        }
    }

    const columns = [
        { id:'asset', content: t('alert_table.asset')},
        { id:'event', content: t('alert_table.event')},
        { id:'rime-range', content: t('alert_table.time_range')},
        { id:'days', content: t('alert_table.days')},
        { id:'recipients', content: t('alert_table.recipients')},
        { id:'action', content: t('alert_table.action')}
    ];

    const rows = getFilteredAlerts().map((alert) => {
        return <EventAlertRow key={alert.id} alert={alert} updateTrigger={getAllEventAlerts} getIdentificationMark={getIdentificationMark}/>
    })

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "space-between",
            margin: '30px',
            marginTop: '10px',
            height: '100%',
        }}>
            <FilterBar onSearchChange={setSearchInput} filter={searchInput} label={t('search_bar.search_for')}/>

            <Table
                id="event-alerts"
                label="Event Alerts"
                columns={columns}
                loading={loading}
                rows={rows}
                createAction={() => {
                    navigate('/alerts/create-event-alerts')
                }}
                emptyLabel={t('alert_table.no_alerts') as string}
            />
        </Box>
    );
};


function EventAlertRow(props: { alert: EventAlert, updateTrigger: () => void, getIdentificationMark: (id: number) => string }) {
    const {t} = useTranslation();
    const {twentyFourHourEnabled} = useContext(UserPreferencesContext)
    const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false)
    const [selectedAlertId, setSelectedAlertId] = React.useState<number | null>(null);
    const navigate = useNavigate()
    const {deleteEventAlert} = useEventAlertsApi()

    async function handleDelete() {
        await deleteEventAlert(selectedAlertId?.toString() as string)
            .catch((error) => {
                alert(t('alert_delete.failed'))
            })

        setSelectedAlertId(null);
        setShowDeleteDialog(false);
        props.updateTrigger();

    }

    return <TableRow>
        <TableCell>
            {props.getIdentificationMark(props.alert.vehicleId)}
        </TableCell>
        <TableCell>
            {t('vehicle_event.' + props.alert.vehicleEventName.toLowerCase())}
        </TableCell>
        <TableCell>
            {`${formatTime(props.alert.startTime, twentyFourHourEnabled)} - ${formatTime(props.alert.endTime, twentyFourHourEnabled)}`}
        </TableCell>

        <TableCell>
            {(props.alert.mon && props.alert.tue && props.alert.wed && props.alert.thu && props.alert.fri && !props.alert.sat && !props.alert.sun) &&
                <>{t('days.weekdays')}</>}
            {(!props.alert.mon && !props.alert.tue && !props.alert.wed && !props.alert.thu && !props.alert.fri && props.alert.sat && props.alert.sun) &&
                <>{t('days.weekends')}</>}
            {(props.alert.mon && props.alert.tue && props.alert.wed && props.alert.thu && props.alert.fri && props.alert.sat && props.alert.sun) &&
                <>{t('days.all_days')}</>}
            {!((props.alert.mon && props.alert.tue && props.alert.wed && props.alert.thu && props.alert.fri) || (props.alert.sat && props.alert.sun)) &&
                <>
                    {props.alert.mon && <>{t('days.monday')}</>}
                    {props.alert.tue && <>{t('days.tuesday')}</>}
                    {props.alert.wed && <>{t('days.wednesday')}</>}
                    {props.alert.thu && <>{t('days.thursday')}</>}
                    {props.alert.fri && <>{t('days.friday')}</>}
                    {props.alert.sat && <>{t('days.saturday')}</>}
                    {props.alert.sun && <>{t('days.sunday')}</>}
                </>
            }
        </TableCell>

        <TableCell>
                {props.alert.emailAddress.length > 3 ? (
                        <Tooltip title={props.alert.emailAddress.join(', ')} arrow>
                            <div>
                                {props.alert.emailAddress.length + ' ' + t('alert_table.recipients')}
                            </div>
                        </Tooltip>
                    ) :
                    props.alert.emailAddress.map(recipient => (
                        <div
                            key={props.alert.id.toString() + "_recipients_" + recipient}
                            id={props.alert.id.toString() + "_recipients_" + recipient}>
                            {recipient} <br/>
                        </div>
                    ))
                }
        </TableCell>

        <TableCell>
            <RowEditButton action={() => {
                const selectedAlert = props.alert;
                navigate('/alerts/update-event-alerts', {state: {selectedAlert}});
            }}/>
            <RowDeleteButton action={() => {
                setSelectedAlertId(props.alert.id);
                setShowDeleteDialog(true);
            }}/>
            <ConfirmationDialog open={showDeleteDialog}
                                title={t('alerts_page.event_alerts_page.delete_dialog.title')}
                                description={t('alerts_page.event_alerts_page.delete_dialog.description')}
                                confirmText={t('button.delete')} cancelText={t('button.cancel')}
                                onConfirm={handleDelete}
                                onCancel={() => setShowDeleteDialog(false)}/>
        </TableCell>
    </TableRow>
}

