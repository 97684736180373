import React, {useContext, useEffect, useRef, useState} from "react";

//Leaflet Maps
import {Marker, useMapEvents} from "react-leaflet";
import L, {LatLng, Map} from "leaflet";


//Map Components - These components are pulled from the livemap page
import MapContextMenu from "../map/components/MapContextMenu";
import MapTileLayer, {DEFAULT_MAP_LAYER} from "../../components/map/MapTileLayer";

import RouteReplayLeftPanel from "./components/leftpanel/RouteReplayLeftPanel";
import {Asset, AssetEvent, AssetType} from "../../hooks/assets/dto/Asset";
import {SvgMarkerIcon} from "../../components/svgmarkericon/SvgMarkerIcon";
import ReactDOMServer from "react-dom/server";
import EventMarkerPopup from "./components/leftpanel/EventMarkerPopup";
import {useAssetApi} from "../../hooks/assets/Assets";
import MapView from "../../layouts/MapView";
import {useSearchParams} from "react-router-dom";
import {isWarningEvent} from "../../utils/AssetEventUtils";
import HexagonOutlinedIcon from '@mui/icons-material/HexagonOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MapButtonControl from "../map/components/MapButtonControl";
import MapLayerControl from "../map/components/MapLayerControl";
import {UserPreferencesContext} from "../../providers/UserPreferencesProvider";
import {useCongestionZonesApi} from "../../hooks/zones/CongestionZones";
import {useGeofencesApi} from "../../hooks/geofences/Geofences";
import CongestionZoneLayer from "../../components/map/CongestionZoneLayer";
import GeofenceLayer from "../../components/map/GeofenceLayer";

interface RouteReplayPageProps {
}

function EventMarkers(props: { events: Array<AssetEvent>, selectedAsset: string | undefined, assets: Asset[] }) {
    return (
        <React.Fragment>
            {props.events.map((event) => {

                const svg = SvgMarkerIcon('black', AssetType.CAR, event.status, event.heading,
                    isWarningEvent(event.speed, event.speedLimit, event.type))

                const customMarkerIcon = L.divIcon({
                    html: ReactDOMServer.renderToString(svg),
                    iconSize: [40, 40],
                    iconAnchor: [20, 20],
                    popupAnchor: [0, -20],
                    className: "none"
                });
                return (<Marker key={event.time.toISOString()} icon={customMarkerIcon}
                                position={new LatLng(event.location.snappedCoordinates.latitude, event.location.snappedCoordinates.longitude)}>
                    <EventMarkerPopup
                        event={event}
                        selectedAsset={props.selectedAsset}
                        assets={props.assets}
                    />
                </Marker>)
            })}
        </React.Fragment>
    );
}


function RouteReplayPage(this: any, props: RouteReplayPageProps) {
    const mapRef = useRef<Map>(null);

    const {assets, loading} = useAssetApi({shouldLoadAll: true, shouldPoll: false});

    const [selectedLayer] = useState(DEFAULT_MAP_LAYER);
    const [markers, setMarkers] = useState<AssetEvent[]>([]);
    const [selectedAsset, setSelectedAsset] = useState<string | undefined>(undefined);
    const [searchParams, setSearchParams] = useSearchParams();
    const {zones} = useCongestionZonesApi(true);
    const {geofences} = useGeofencesApi(true);
    const {mapType, setMapType, labelsEnabled,} = useContext(UserPreferencesContext);
    const [congestionZonesEnabled, setCongestionZonesEnabled] = useState(false);
    const [geofenceEnabled, setGeofenceEnabled] = useState(false);

    const [mapLayerControllerOpen, setMapLayerControllerOpen] = useState(false);

    const mapLayers = <>
        <MapTileLayer mapLayer={selectedLayer}/>

        <MapContextMenu/>

        <EventMarkers events={markers} selectedAsset={selectedAsset} assets={assets || []}/>
    </>

    const handleClickOperation = () => {
        setMapLayerControllerOpen(!mapLayerControllerOpen);
    };

    function MapEventHandler() {
        useMapEvents({
            click: (event) => {
                setMapLayerControllerOpen(false);
            }
        })

        return <></>
    }

    const leftPanel = <RouteReplayLeftPanel
        mapref={mapRef}
        setMarkers={setMarkers}
        selectedAsset={selectedAsset}
        setSelectedAsset={setSelectedAsset}
        loading={loading}
        assets={assets || []}/>

    const controls = (
        <>
            {/* Map Tile Layer - Selected by the MapLayerControl */}
            <MapTileLayer mapLayer={mapType}/>

            <MapEventHandler/>

            <MapButtonControl placement="topleft" tooltip="Toggle Congestion Zones" toggled={congestionZonesEnabled}
                              icon={<HexagonOutlinedIcon/>} onClick={() => setCongestionZonesEnabled(!congestionZonesEnabled)}/>
            <MapButtonControl placement="topleft" tooltip="Toggle Geofences" toggled={geofenceEnabled}
                              icon={<LocationOnOutlinedIcon/>} onClick={() => setGeofenceEnabled(!geofenceEnabled)}/>
            <MapLayerControl placement="topleft" selectedLayer={mapType} setSelectedLayer={setMapType} open={mapLayerControllerOpen}
                             handleClickOperation={handleClickOperation}/>

            {/* Congestion Zones */}
            {zones && zones.length > 0 ?
                (<CongestionZoneLayer zones={zones || []} labelsEnabled={labelsEnabled} congestionZonesEnabled={congestionZonesEnabled}/>) :
                (<></>)}
            {/* Geofences */}
            {geofences && geofences.length > 0 ?
                (<GeofenceLayer geofences={geofences} labelsEnabled={labelsEnabled} geofenceEnabled={geofenceEnabled}/>) :
                (<></>)}
        </>
    );

    useEffect(() => {
        let selectedId = searchParams.get("selected");

        if (mapRef.current === null) {
            return;
        }

        if (selectedId !== null) {
            setSelectedAsset(selectedId);
            searchParams.delete("selected");
            setSearchParams(searchParams);
        }

    }, [searchParams, mapRef.current]);

    return (<MapView leftPanel={leftPanel} mapLayers={mapLayers} mapref={mapRef} controls={controls}/>
    );
}

export default RouteReplayPage;
