import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReportDaysOfMonths } from "../../../enum/ReportDaysOfMonths";
import { colors } from "../../../themes/AppTheme";

interface ReportDayOfMonthSelectionProps {
    selectedReportDaysOfMonth: number;
    setSelectedReportDaysOfMonth: (selectedReportDaysOfMonth: ReportDaysOfMonths) => void;
}

export default function ReportDayOfMonthSelection(props: ReportDayOfMonthSelectionProps) {
    const { t } = useTranslation();

    function handleReportDayOfMonthChange(event: SelectChangeEvent<any>) {
        props.setSelectedReportDaysOfMonth(event.target.value);
    }

    const menuItems = Array.from({ length: 31 }, (_, index) => (
        <MenuItem
            key={`day-of-month-${index + 1}`}
            style={{ height: 25, fontSize: '12px' }}
            value={index + 1}
        >
            {t(`scheduled_report.${ordinal(index + 1)}_day`)}
        </MenuItem>
    ));

    return (
        <Select
            id="file_format"
            size="small"
            value={props.selectedReportDaysOfMonth}
            color="info"
            style={{ height: '100%', fontSize: '12px', color: colors.ram_purple }}
            onChange={handleReportDayOfMonthChange}
            fullWidth
        >
            {menuItems}
        </Select>
    );
}

// Function to convert number to ordinal string (e.g., 1 -> 'first', 2 -> 'second', etc.)
function ordinal(n: number) {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
}
