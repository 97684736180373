import { Box, List, Typography, useTheme } from '@mui/material';
import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';

interface SecondaryNavigationItem {
    text: string;
    selected: boolean;
    onClick?: () => void;
    to?: string;
}

interface SecondaryNavigationPanelProps {
    title: String;
    items: SecondaryNavigationItem[];
}

export default function SecondaryNavigationPanel(props: SecondaryNavigationPanelProps) {
    const theme = useTheme();

    return (
        <Box display="flex" flexDirection="column" alignItems="Left"
            sx={{
                background: theme.palette.contentPage.backgroundPanel,
                height: '100%',
                padding: '15px',
                paddingTop: '10px',
                paddingBottom: '10px',
                borderRadius: '5px',
                boxShadow: 3
            }}>
            <Box sx={{
                flexDirection: "column", flexGrow: 1
            }}>
                <Typography variant="title">
                    {props.title}
                </Typography>
                <List>
                    {props.items.map((item) => (
                        <SecondaryNavigationButton key={item.text} text={item.text} selected={item.selected} to={item.to} onClick={item.onClick} />
                    ))}
                </List>
            </Box>
        </Box>
    );
};



interface SecondaryNavigationButtonProps {
    text: string;
    selected: boolean;
    onClick?: () => void;
    to?: string;
}

function SecondaryNavigationButton(props: SecondaryNavigationButtonProps) {
    const theme = useTheme();

    return (
        <ListItem
            key={props.text}
            disablePadding
            sx={{
                display: "block",
                backgroundColor: props.selected ? theme.palette.contentPage.listSelectedBackground : theme.palette.contentPage.listBackground,
                borderLeftColor: props.selected ? theme.palette.contentPage.listSelectedBorder : theme.palette.contentPage.listBackground,
                borderLeftWidth: '5px',
                borderLeftStyle: 'solid',
                margin: '2px',
                "&:hover": {
                    backgroundColor: theme.palette.contentPage.listHoverBackground,
                    borderLeftColor: props.selected ? theme.palette.contentPage.listSelectedBorder : theme.palette.contentPage.listHoverBorder,
                },
            }}>
            <ListItemButton
                component={props.to ? Link : "div"}
                onClick={props.onClick}
                to={props.to}
                sx={{
                    minHeight: 34,
                    justifyContent: "initial",
                    px: 2,
                    color: props.selected ? theme.palette.contentPage.listSelectedText : theme.palette.contentPage.listText,
                    "&:hover": {
                        color: props.selected ? theme.palette.contentPage.listSelectedText : theme.palette.contentPage.listHoverText,
                    },
                }}>
                <ListItemText
                    primary={props.text}
                />
            </ListItemButton>
        </ListItem>
    );
};

