import { Tooltip, Circle, Polygon } from "react-leaflet";
import { Geofence } from "../../hooks/geofences/dto/Geofence";
import { LatLngExpression } from "leaflet";
import { convertPositionArrayToLatLngArray } from "../../utils/MapUtils";

interface GeofenceLayerProps {
    geofences: Geofence[]
    labelsEnabled: boolean;
    geofenceEnabled: boolean;
}

function GeofenceLayer(props: GeofenceLayerProps) {

    const geofencePolygons = props.geofences.map((geofence: Geofence) => {
        if (geofence.feature.geometry.type === "Point") {
            return (<Circle
                key={geofence.id}
                center={[geofence.feature.geometry.coordinates[1], geofence.feature.geometry.coordinates[0]] as LatLngExpression}
                radius={geofence.feature.properties!.radius} color={geofence.colour}
            >
                <Tooltip direction={"center"}>{geofence.name}</Tooltip>
            </Circle>)
        } else if (geofence.feature.geometry.type === "Polygon") {
            return (<Polygon
                key={geofence.id}
                positions={convertPositionArrayToLatLngArray(geofence.feature.geometry.coordinates[0])}
                pathOptions={{ color: geofence.colour }}>
                <Tooltip direction={"center"}>{geofence.name}</Tooltip>
            </Polygon>)
        } else {
            return (<></>)
        }
    })

    return (<>
        {props.geofenceEnabled ? geofencePolygons : (<></>)}
    </>)

}

export default GeofenceLayer;
