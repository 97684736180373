import { Button, useTheme } from '@mui/material';
import { useIntercom } from 'react-use-intercom';
import PropertiesLoader from "../../properties/PropertiesLoader";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import {useContext} from "react";
import {useTranslation} from "react-i18next";
const properties = PropertiesLoader();
function LogoutButton() {
    const auth: IAuthContext = useContext(AuthContext)
    const theme = useTheme()
    const {shutdown} = useIntercom();
    const {t} = useTranslation();
    return (
        <Button
            size='small'
            disableRipple
            onClick={() => {
                auth.logOut();
                shutdown();
                window.location.replace(
                    properties.authProviderLogoutEndpoint
                );}}
            sx={{
                backgroundColor: theme.palette.appbar.buttonBackground,
                color: theme.palette.appbar.buttonForeground,
                borderRadius: '5px',
                margin: '5px',
                height: '34px',
                '&:hover': {
                    backgroundColor: theme.palette.appbar.buttonBackgroundHover,
                },
            }}
        >{t('button.logout')}</Button>
    );
}

export default LogoutButton;