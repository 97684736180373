//Custom Properties Loader - Ask Carl how it works, probs can be improved
import PropertiesLoader from "./properties/PropertiesLoader";

//Styling imports
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";
import CssBaseline from "@mui/material/CssBaseline";
import {styled, ThemeProvider} from "@mui/material/styles";

//Components used in root page
import RamAppBar from "./components/appbar/RamAppBar";

//Pages
import Dashboard from "./pages/dashboard/Dashboard";
import {LeafletRightClickProvider} from "react-leaflet-rightclick";
import {UserProvider} from "./providers/UserAccountProvider";
import LandingPage from "./pages/landing/LandingPage";
import LoadingPage from "./pages/loading/LoadingPage";
import {IntercomProvider} from 'react-use-intercom';

import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';
import {UserPreferencesContext, UserPreferencesProvider} from "./providers/UserPreferencesProvider";
import React, {useContext} from "react";
import {BrowserRouter,useLocation} from "react-router-dom";
import {FilterSortProvider} from "./providers/FilterSortProvider";
import {AuthContext, AuthProvider, IAuthContext, TAuthConfig} from 'react-oauth2-code-pkce'
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {generateAppTheme} from "./themes/AppTheme";
import {ImpersonateUserProvider} from "./providers/ImpersonateUserProvider";
import ReactGA from "react-ga4";
import { FilterAssetProvider } from "./providers/FilterAssetProvider";
import { ReportProvider } from "./providers/ReportPreferencesProvider";

i18n
    .use(initReactI18next)
    .use(Backend)
    .init({
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        },

    });


//Load Properties and Setup Auth Service
const properties = PropertiesLoader();
const authConfig: TAuthConfig = {
    clientId: properties.authClientId,
    authorizationEndpoint: properties.authProviderEndpoint + '/authorize',
    tokenEndpoint: properties.authProviderEndpoint + '/token',
    redirectUri: properties.authRedirect,
    decodeToken: true,
    scope: 'all',
    autoLogin: false,
    refreshTokenExpiresIn: 600000,
}

//Offset for the floating nav to make sure content doesnt disapear behind it
const Offset = styled("div")(({theme}) => theme.mixins.toolbar);

function Content() {
    const auth: IAuthContext = useContext(AuthContext)
    const { pathname } = useLocation()

    if (auth.loginInProgress) {
        document.title = "RAM Tracking - Loading";
        ReactGA.send({ hitType: "pageview", page: '/loading', title:  document.title });
        return <LoadingPage/>
    }

    if (!auth.token) {
        if(pathname !== "/") window.location.href = "/";
        document.title = "RAM Tracking - Login";
        ReactGA.send({ hitType: "pageview", page: '/login', title:  document.title });
        return <LandingPage/>
    }
    return <Dashboard/>
}

interface ThemeProviderWrapperProps {
    children: React.ReactNode;
}

function ThemeProviderWrapper({children}: ThemeProviderWrapperProps) {
    const {largeText} = useContext(UserPreferencesContext)

    return (<ThemeProvider theme={generateAppTheme(largeText)}>{children}</ThemeProvider>)
}

function WrappedSecuredApp() {

    return (
        <AuthProvider authConfig={authConfig}>
            <UserPreferencesProvider>
               <FilterAssetProvider>
                    <ThemeProviderWrapper>
                        <IntercomProvider appId={"xq6a05yj" || process.env.APP_ID} autoBoot={false}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <BrowserRouter>
                                    <UserProvider>
                                        <ImpersonateUserProvider>
                                            <FilterSortProvider>
                                                <ReportProvider>
                                                    <LeafletRightClickProvider>
                                                        <CssBaseline/>
                                                        <RamAppBar/>
                                                        <Offset/>
                                                        <Content/>
                                                    </LeafletRightClickProvider>
                                                </ReportProvider>
                                            </FilterSortProvider>
                                        </ImpersonateUserProvider>
                                    </UserProvider>
                                </BrowserRouter>
                            </LocalizationProvider>
                        </IntercomProvider>
                    </ThemeProviderWrapper>
               </FilterAssetProvider>
            </UserPreferencesProvider>
        </AuthProvider>
    );
}

export default WrappedSecuredApp;
