import {VehicleEventTranslations} from "../enum/VehicleEventTranslations";


export function mapToVehicleEventTranslation(input: string): string | undefined {
    const mappedValue = Object.values(VehicleEventTranslations).find(
        v => v.toLowerCase() === input.toLowerCase().replaceAll(' ', '_')
    );
    if(mappedValue){
        return mappedValue.toString()
    }
    else{
        return undefined;
    }
}

