import {Box, Tooltip} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {RowDeleteButton, RowEditButton} from "../../../components/table/core/TableButtons";
import ConfirmationDialog from "../../../components/dialog/ConfirmationDialog";
import {Table, TableCell, TableRow} from "../../../components/table/core/Table";
import FilterBar from "../../../components/filterbar/FilterBar";
import {EngineHourAlert, useEngineHoursAlertsApi} from "../../../hooks/alerts/EngineHourAlerts";
import {getTranslatedName} from "./components/EngineHourAlertTypes";

interface EngineHoursAlertsPageProps {
}

export default function EngineHoursAlertsPage(props: EngineHoursAlertsPageProps) {

    const {t} = useTranslation();

    const {engineHourAlerts, getAllEngineHoursAlerts, loading: alertsLoading} = useEngineHoursAlertsApi({shouldLoadAll: true})
    const [searchInput, setSearchInput] = React.useState<string>("");
    const navigate = useNavigate()
    const loading = alertsLoading;

    function getFilteredAlerts() {
        if (engineHourAlerts === null) {
            return [];
        } else if (searchInput === undefined || searchInput === "") {
            return engineHourAlerts || [];
        } else {
            const lowerCaseFilter = searchInput.toLowerCase();
            return engineHourAlerts.filter(alert =>
                alert.recipients.some(recipient => recipient.toLowerCase().includes(lowerCaseFilter))
                || alert.vehicles.some(vehicle => vehicle.toLowerCase().includes(lowerCaseFilter))
            );
        }
    }

    const columns = [
        {id: 'asset', content: t('alert_table.asset')},
        {id: 'recipients', content: t('alert_table.recipients')},
        {id: 'type', content: t('alert_table.type')},
        {id: 'hour', content: t('alert_table.hour_threshold')},
        {id: 'action', content: t('alert_table.action')}
    ];

    const rows = getFilteredAlerts().map((alert) => {
        return <EngineHourAlertRow key={alert.id} alert={alert} updateTrigger={getAllEngineHoursAlerts}/>
    })

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "space-between",
            margin: '30px',
            marginTop: '10px',
            height: '100%',
        }}>
            <FilterBar onSearchChange={setSearchInput} filter={searchInput} label={t('search_bar.search_for')}/>

            <Table
                id={"engine-hour-alerts"}
                label={"Engine Hour Alerts"}
                columns={columns}
                rows={rows}
                loading={loading}
                createAction={() => {
                    navigate('/alerts/create-engine-hour-alerts')
                }}
                emptyLabel={t('alert_table.no_alerts') as string}/>
        </Box>
    );
};

function EngineHourAlertRow(props: { alert: EngineHourAlert, updateTrigger: () => void }) {
    const {t} = useTranslation();
    const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false)
    const [selectedAlertId, setSelectedAlertId] = React.useState<number | null>(null);
    const navigate = useNavigate()
    const {deleteEngineHoursAlert} = useEngineHoursAlertsApi()

    async function handleDelete() {
        await deleteEngineHoursAlert(selectedAlertId?.toString() as string)
            .catch((error) => {
                alert(t('alert_delete.failed'))
            })

        setShowDeleteDialog(false)
        props.updateTrigger()
    }

    return <TableRow
        id={props.alert.id.toString()}>
        <TableCell>
            {props.alert.vehicles?.length > 3 ? (
                    <Tooltip title={props.alert.vehicles.join(', ')} arrow>
                        <div>{props.alert.vehicles.length + " " + t('alert_table.vehicles')}</div>
                    </Tooltip>
                ) :
                props.alert.vehicles?.map(vehicle => (
                    <div
                        key={props.alert.id.toString() + "_vehicles_" + vehicle}
                        id={props.alert.id.toString() + "_vehicles_" + vehicle}>
                        {vehicle}<br/>
                    </div>
                ))
            }
        </TableCell>
        <TableCell>
            {props.alert.recipients?.length > 3 ? (
                    <Tooltip title={props.alert.recipients.join(', ')} arrow>
                        <div>
                            {props.alert.recipients.length + ' ' + t('alert_table.recipients')}
                        </div>
                    </Tooltip>
                ) :
                props.alert.recipients?.map(recipient => (
                    <div
                        key={props.alert.id.toString() + "_recipients_" + recipient}
                        id={props.alert.id.toString() + "_recipients_" + recipient}>
                        {recipient} <br/>
                    </div>
                ))
            }
        </TableCell>
        <TableCell>
            {getTranslatedName(props.alert.type, t)}
        </TableCell>
        <TableCell>
            {props.alert.triggerValue.toString()}
        </TableCell>
        <TableCell>
            {(
                <RowEditButton action={() => {
                    const selectedAlert = props.alert;
                    navigate('/alerts/update-engine-hour-alerts', {state: {selectedAlert}});
                }}/>
            )}
            <RowDeleteButton action={() => {
                setSelectedAlertId(props.alert.id);
                setShowDeleteDialog(true);
            }}/>

            <ConfirmationDialog open={showDeleteDialog}
                                title={t('engine_hour_alert.admin.delete.title')}
                                description={t('engine_hour_alert.admin.delete.description')}
                                confirmText={t('button.delete')} cancelText={t('button.cancel')}
                                onConfirm={handleDelete}
                                onCancel={() => setShowDeleteDialog(false)}/>
        </TableCell>
    </TableRow>
}
