import ProfileContentPanel from "./components/content/ProfileContentPanel";
import { useLocation } from "react-router-dom";
import SecondaryNavigationPanel from "../../components/secondary_navigation_panel/SecondaryNavigationPanel";
import { useTranslation } from "react-i18next";
import SplitView from "../../layouts/SplitView";

interface ProfilePageProps {
}

export default function ProfilePage(props: ProfilePageProps) {
  const { t } = useTranslation();
  const { pathname } = useLocation()

  const secondaryNav = <SecondaryNavigationPanel title={t('profile_page.navigation.title') as String} items={[
    { text: t('profile_page.navigation.user_information'), selected: pathname === '/profile' || pathname === '/profile/user-info', to: 'user-info' },
    { text: t('profile_page.navigation.accessibility'), selected: pathname === '/profile/accessibility', to: 'accessibility' }
  ]} />

  const content = <ProfileContentPanel />

  return (
    <SplitView scondaryNavigation={secondaryNav} content={content} />
  );
}