export enum VehicleEventTranslations {
    LOCATIONREPLY = 'location_reply',
    DRIVING = 'driving',
    TOW_ALERT = 'tow_alert',
    RFID_READ = 'rfid_read',
    BARCODE_READ = 'barcode_read',
    IDLE_END = 'idle_end',
    IDLE_START = 'idle_start',
    EXTENDED_IDLE_1 = 'extended_idle_1',
    EXTENDED_IDLE_2 = 'extended_idle_2',
    FATIGUE = 'fatigue',
    EXTENDED_STOP = 'extended_stop',
    POWER_UP = 'power_up',
    OVER_SPEED = 'over_speed',
    UNDER_SPEED = 'under_speed',
    EXT_POWER_LOW = 'ext_power_low',
    EXT_POWER_FAIL = 'ext_power_fail',
    INT_BAT_LOW = 'int_bat_low',
    INT_BAT_FAIL = 'int_bat_fail',
    EXT_POWER_LOST = 'ext_power_lost',
    GPS_LOST = 'gps_lost',
    GPS_ANT_FAILED = 'gps_ant_failed',
    EXT_POWER_RESTORED = 'ext_power_restored',
    INT_BAT_RESTORED = 'int_bat_restored',
    GPS_MODULE_FAILED = 'gps_module_failed',
    IMPACT_DETECTED = 'impact_detected',
    HARSH_ACCELERATION = 'harsh_acceleration',
    ACCELERATION_END = 'acceleration_end',
    HARSH_BRAKING = 'harsh_braking',
    DECELERATION_END = 'deceleration_end',
    GSM_ROAMING_START = 'gsm_roaming_start',
    GSM_ROAMING_END = 'gsm_roaming_end',
    GPS_JAMMING_ALERT = 'gps_jamming_alert',
    IGNITION_OFF = 'ignition_off',
    IGNITION_ON = 'ignition_on',
    INPUT_2_LOW = 'input_2_low',
    INPUT_2_HIGH = 'input_2_high',
    INPUT_3_LOW = 'input_3_low',
    INPUT_3_HIGH = 'input_3_high',
    INPUT_4_LOW = 'input_4_low',
    INPUT_4_HIGH = 'input_4_high',
    INPUT_1_LOW = 'input_1_low',
    INPUT_1_HIGH = 'input_1_high',
    FIRST_IGNITION_OF_DAY = 'first_ignition_of_day',
    LCZ_IN = 'lcz_in',
    LCZ_OUT = 'lcz_out',
    PRIVATE_MILEAGE = 'private_mileage',
    NO_ACTIVITY = 'no_activity',
    INPUT_5_LOW = 'input_5_low',
    INPUT_5_HIGH = 'input_5_high',
    INPUT_6_LOW = 'input_6_low',
    INPUT_6_HIGH = 'input_6_high',
    INPUT_7_LOW = 'input_7_low',
    INPUT_7_HIGH = 'input_7_high',
    INPUT_8_LOW = 'input_8_low',
    INPUT_8_HIGH = 'input_8_high',
    ENTERED = 'entered',
    EXITED = 'exited',
    AUTO_REPLY = 'auto_reply',
    UNKNOWN_EVENT = 'unknown_event',
    STATIONARY = 'stationary',
    PRIVATE_MODE = 'private_mode',
    PRIVATE_MODE_START = 'private_mode_start',
    PRIVATE_MODE_END = 'private_mode_end',
    PRIVATE_MILEAGE_START = 'private_mileage_start',
    PRIVATE_MILEAGE_END = 'private_mileage_end',
    HARSH_CORNERING = 'harsh_cornering',
    MOVEMENT_NO_IGNITION = 'movement_no_ignition',
    FAKE_TOW = 'fake_tow',
    TREMBLE_START = 'tremble_start',
    TREMBLE_STOP = 'tremble_stop',
    BACKUP_BATTERY_STARTS_CHARGING = 'backup_battery_starts_charging',
    BACKUP_BATTERY_STOPS_CHARGING = 'backup_battery_stops_charging',
    REBOOT = 'reboot',
    UNKNOWN_HARSH_EVENT = 'unknown_harsh_event',
    HARSH_DRIVING_EVENT_END = 'harsh_driving_event_end',
    TRIP_START = 'trip_start',
    TRIP_END = 'trip_end',
    TRAILER_DISCONNECTED_PARKED = 'trailer_disconnected_parked',
    GEOFENCE_IN = 'geofence_in',
    GEOFENCE_OUT = 'geofence_out',
    INTERNAL_BATTERY_PLUGGED = 'internal_battery_plugged',
    INTERNAL_BATTERY_UNPLUGGED = 'internal_battery_unplugged',
    CELL_ID_MOVING = 'cell_id_moving',
    DATAMODE_HOME_MOVING = 'datamode_home_moving',
    DATAMODE_HOME_STOPPED = 'datamode_home_stopped',
    DATAMODE_ROAMING_STOPPED = 'datamode_roaming_stopped',
    DATAMODE_ROAMING_MOVING = 'datamode_roaming_moving',
    DATAMODE_UNKNOWN_STOPPED = 'datamode_unknown_stopped',
    DATAMODE_UNKNOWN_MOVING = 'datamode_unknown_moving',
    GSM_DEEP_SLEEP = 'gsm_deep_sleep',
    GSM_NOT_DEEP_SLEEP = 'gsm_not_deep_sleep',
    GPS_JAMMING_ENDED = 'gps_jamming_ended',
    IBUTTON_NOT_CONNECTED = 'ibutton_not_connected',
    IBUTTON_CONNECTED = 'ibutton_connected',
    STATIONARY_NO_IGNITION = 'stationary_no_ignition',
    ZONE_IN = 'zone_in',
    ZONE_OUT = 'zone_out',
    HEARTBEAT = 'heartbeat',
    DEVICE_STATUS = 'device_status',
    MANUEL_LOCATION_UPDATE = 'manuel_location_update',
    INFORMATION_REPORT = 'information_report',
    DATA_CONNECTION_REPORT = 'data_connection_report',
    GENERATOR_ON = 'generator_on',
    GENERATOR_OFF = 'generator_off',
    GENERATOR_RUNNING = 'generator_running',
    TRANSIT_START = 'transit_start',
    TRANSIT_STOP = 'transit_stop',
    OIL_LOW = 'oil_low',
    OIL_LOW_END = 'oil_low_end',
    WASTE_LOW = 'waste_low',
    WASTE_LOW_END = 'waste_low_end',
    OVERRUN = 'overrun',
    OVERRUN_END = 'overrun_end',
    ANALOGUE_1 = 'analogue_1',
    ANALOGUE_2 = 'analogue_2',
}