import {LiveShareLinksPeriod} from "./LiveShareLink";

export class LiveShareRequest {

    vehicleIds: number[];
    linkPeriod: LiveShareLinksPeriod;
    startTime: string;
    endTime: string;
    expiresAt?: string;

    constructor(props: {
        vehicleIds: number[];
        linkPeriod: LiveShareLinksPeriod;
        startTime: string;
        endTime: string;
        expiresAt?: string;
    }) {
        this.vehicleIds = props.vehicleIds;
        this.linkPeriod = props.linkPeriod;
        this.startTime = props.startTime;
        this.endTime = props.endTime;
        this.expiresAt = props.expiresAt;
    }
}
