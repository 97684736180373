import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ReactGA from "react-ga4";

interface FeaturedProductWidgetProps {
    id: string
    nameKey: string;
    descriptionKey: string;
    background: string;
    image: string;
    link: string;
}

const FeaturedProductWidget: React.FC<FeaturedProductWidgetProps> = (props: FeaturedProductWidgetProps) => {

    const { t } = useTranslation();

    return (
        <Box
            component={Link}
            onClick={()=> {ReactGA.event("RT2-FPW-Link-" + t(props.nameKey));  window.open(props.link, "_blank");}}
       
            id={`featured-product-panel-${props.id}`}
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{
                textDecoration: 'none',
                backgroundImage: "url( " + props.background + ")",
                backgroundSize: 'cover',
                height: '100%',
                padding: '30px',
                paddingTop: '20px',
                paddingBottom: '20px',
                borderRadius: '5px',
                boxShadow: 3
            }}>
            <img
                src={props.image}
                alt={props.id}
                style={{
                    width: '100%',
                    height: 'auto',
                    maxWidth: '200px',
                }}
            />
            <Box sx={{
                flexDirection: "column", flexGrow: 1,
                maxWidth: '300px'
            }}>
                <Typography variant="title" color='#FFF'>
                    {t(props.nameKey)}
                </Typography>
                <Typography variant="body" color='#FFF'>
                    {t(props.descriptionKey)}
                </Typography>
            </Box>
        </Box>
    );
};

export default FeaturedProductWidget;