import {useEffect, useState} from "react";
import usePlatformFetch from "../../shared/PlatformFetch";
import PropertiesLoader from "../../../properties/PropertiesLoader";
import {ServiceRecordRequest} from "./dto/ServiceRecordRequest";
import {ServiceRecord} from "./dto/ServiceRecord";

const properties = PropertiesLoader();

interface ServiceRecordApiOptions {
    shouldLoadAll?: boolean;
    shouldPoll?: boolean;
    pollFrequency?: number;
}

export function useServiceRecordApi(props: ServiceRecordApiOptions = {}) {
    const {platformFetch, checkOk, checkCreated, checkNoContent} = usePlatformFetch();

    const [serviceRecords, setServiceRecords] = useState<ServiceRecord[]>([]);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const getAllServiceRecords = async () => {
        return new Promise((resolve, reject) => {
            setLoading(true)
            platformFetch(
                properties.platformApiEndpoint + "tracking/v1/servicing/records",
                'get')
                .then(checkOk)
                .then(response => response.json())
                .then(serviceRecords => {
                    setServiceRecords(serviceRecords)
                    setLoading(false)
                    resolve(serviceRecords)
                })
                .catch((error) => {
                    console.error("Error getting service records: " + error)
                    setError(error)
                    setLoading(false)
                    reject(error)
                });
        });
    }

    const createServiceRecord = async (serviceRecordRequest: ServiceRecordRequest) => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `tracking/v1/servicing/${serviceRecordRequest.vehicleId}/records`,
                'post',
                JSON.stringify(serviceRecordRequest))
                .then(checkCreated)
                .then(response => response.json())
                .then(serviceRecord => resolve(serviceRecord))
                .catch((error) => {
                    console.error("Error creating service record: " + error)
                    reject(error)
                });
        });
    }

    const updateServiceRecord = async (serviceRecordRequest: ServiceRecordRequest, recordId: string, vehicleId: number) => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `tracking/v1/servicing/${vehicleId}/records/${recordId}`,
                'put',
                JSON.stringify(serviceRecordRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(serviceRecord => resolve(serviceRecord))
                .catch((error) => {
                    console.error("Error updating service record: " + error)
                    reject(error)
                });
        });
    }

    const deleteServiceRecord = async (assetId: string, recordId: string) => {
        return new Promise<void>((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `tracking/v1/servicing/${assetId}/records/${recordId}`,
                'delete')
                .then(checkNoContent)
                .then(() => resolve())
                .catch((error) => {
                    console.error("Error deleting service record: " + error)
                    reject(error)
                });
        });
    }

    useEffect(() => {
        if (props.shouldLoadAll) {
            getAllServiceRecords();
            if (props.shouldPoll) {
                const interval = setInterval(getAllServiceRecords, props.pollFrequency ?? 30000);
                return () => clearInterval(interval);
            }
        }
    }, []);

    return {
        loading, error, serviceRecords, getAllServiceRecords, createServiceRecord, updateServiceRecord, deleteServiceRecord
    }
}
