export enum ReportDaysOfMonths {
    FIRST_DAY = 1,
    SECOND_DAY = 2,
    THIRD_DAY = 3,
    FOURTH_DAY = 4,
    FIFTH_DAY = 5,
    SIXTH_DAY = 6,
    SEVENTH_DAY = 7,
    EIGHTH_DAY = 8,
    NINTH_DAY = 9,
    TENTH_DAY = 10,
    ELEVENTH_DAY = 11,
    TWELFTH_DAY = 12,
    THIRTEENTH_DAY = 13,
    FOURTEENTH_DAY = 14,
    FIFTEENTH_DAY = 15,
    SIXTEENTH_DAY = 16,
    SEVENTEENTH_DAY = 17,
    EIGHTEENTH_DAY = 18,
    NINETEENTH_DAY = 19,
    TWENTIETH_DAY = 20,
    TWENTY_FIRST_DAY = 21,
    TWENTY_SECOND_DAY = 22,
    TWENTY_THIRD_DAY = 23,
    TWENTY_FOURTH_DAY = 24,
    TWENTY_FIFTH_DAY = 25,
    TWENTY_SIXTH_DAY = 26,
    TWENTY_SEVENTH_DAY = 27,
    TWENTY_EIGHTH_DAY = 28,
    TWENTY_NINTH_DAY = 29,
    THIRTIETH_DAY = 30,
    THIRTY_FIRST_DAY = 31,
}
