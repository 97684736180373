import { Box, List, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ProgressPanelItem from './ProgressPanelItem';

interface ProgressPanelProps {
    step: number;
}

export default function ProgressPanel(props: ProgressPanelProps) {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Box display="flex" flexDirection="column" alignItems="Left"
            sx={{
                background: theme.palette.loginwizard.progressPanelBackground,
                height: '100%',
                padding: '15px',
                paddingTop: '10px',
                paddingBottom: '10px',
                borderRadius: '5px',
            }}>
            <Box sx={{
                flexDirection: "column", flexGrow: 1
            }}>
                <img
                    src="/images/logos/ram-tracking.png"
                    style={{
                        width: "100%",
                        height: "50px",
                        pointerEvents: "none",
                        padding: "3px",
                        objectFit: "contain",
                    }}
                    alt="logo"
                />
                <List>
                    <ProgressPanelItem currentStep={props.step === 0} completedStep={props.step > 0} stepName={t('setup_wizard.language_selection_step.title')} stepDescription={t('setup_wizard.language_selection_step.description')} />
                    <ProgressPanelItem currentStep={props.step === 1} completedStep={props.step > 1} stepName={t('setup_wizard.personal_information_step.title')} stepDescription={t('setup_wizard.personal_information_step.description')} />
                    <ProgressPanelItem currentStep={props.step === 2} completedStep={props.step > 2} stepName={t('setup_wizard.customise_experience_step.title')} stepDescription={t('setup_wizard.customise_experience_step.description')} />
                    <ProgressPanelItem currentStep={props.step === 3} completedStep={props.step > 3} stepName={t('setup_wizard.accessibility_step.title')} stepDescription={t('setup_wizard.accessibility_step.description')} />
                </List>
            </Box>
        </Box>
    );
};





