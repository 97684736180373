import { Box, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';

interface AlertsContentPanelProps {
}

export default function AlertsContentPanel(props: AlertsContentPanelProps) {
    const theme = useTheme();

    return (
        <Box display="flex" flexDirection="column" alignItems="Left"
            sx={{
                background: theme.palette.contentPage.backgroundPanel,
                height: '100%',
                borderRadius: '5px',
                boxShadow: 3
            }}>
            <Outlet />
        </Box>
    );
};





