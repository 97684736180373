import { Box, FormControl, InputAdornment, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import React from "react";
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from "react-i18next";
import { Geofence } from "../../../../hooks/geofences/dto/Geofence";

interface GeofencesFilterBarProps {
    onSearchChange: (search: string) => void;
    categoryFilter: string | undefined;
    setCategoryFilter: (category: string | undefined) => void;
    geofences: Geofence[];
}

export default function GeofencesFilterBar(props: GeofencesFilterBarProps) {
    const { t } = useTranslation();

    function handleCategoryChange(event: SelectChangeEvent<any>) {
        props.setCategoryFilter(event.target.value as string);
    }

    function removeDuplicates(array: any[]) {
        return array.filter((item,
            index) => array.indexOf(item) === index);
    }

    return (
        <>
            <Box sx={{
                padding: '10px',
                paddingTop: '20px',
                paddingBottom: '5px'
            }}>
                <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    label={t('geofences_page.filter_bar.search_placeholder')}
                    variant="outlined"
                    color="info"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        props.onSearchChange(event.target.value);
                    }}
                />
            </Box>
            <Box
                sx={{
                    padding: '10px',
                    paddingTop: '5px',
                }}>
                <FormControl>
                    <Select
                        id="asset-group-filter-select"
                        size="small"
                        value={props.categoryFilter}
                        color="info"
                        style={{ height: 25, fontSize: '12px' }}
                        onChange={handleCategoryChange}
                    >
                        <MenuItem key="filter-group-all" style={{ height: 25, fontSize: '12px' }} value={"all"}>{t('geofences_page.filter_bar.filter_options.all')}</MenuItem>
                        {removeDuplicates(props.geofences.map((geofence) => geofence.category?.name))
                            .map((category) => {
                                if (category === '' || category === undefined || category === null) {
                                    return (<MenuItem key={"filter-category-ungrouped"} style={{ height: 25, fontSize: '12px' }} value={category}>{t('geofences_page.filter_bar.filter_options.none')}</MenuItem>)
                                }
                                return (<MenuItem key={"filter-category-" + category} style={{ height: 25, fontSize: '12px' }} value={category}> {t('geofences_page.filter_bar.filter_options.category') + category}</MenuItem>)
                            })}
                    </Select>
                </FormControl>
            </Box>
        </>
    );
}