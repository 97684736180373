import {MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {useTranslation} from "react-i18next";
import {getTranslatedName, ReportType} from "../../../enum/ReportType";
import {colors} from "../../../themes/AppTheme";

interface ReportTypeSelectionProps {
    selectedReportType: string;
    setSelectedReportType: (selectedReportType: ReportType) => void;
}

export default function ReportTypeSelection(props: ReportTypeSelectionProps) {
    const {t} = useTranslation();

    function handleReportTypeChange(event: SelectChangeEvent<any>) {
        props.setSelectedReportType(event.target.value);
    }

    const reportTypesWithNames = Object.values(ReportType).map((reportType) => ({
        reportType: reportType,
        translatedName: getTranslatedName(reportType, t),
    }));

    return (
        <Select
            id="file_format"
            size="small"
            value={props.selectedReportType}
            color="info"
            style={{height: '100%', fontSize: '12px', color: colors.ram_purple}}
            onChange={handleReportTypeChange}
            fullWidth
        >
            {reportTypesWithNames
                .sort((a, b) =>
                    a.translatedName.localeCompare(b.translatedName)
                )
                .map(({reportType, translatedName}, index) => (
                    <MenuItem key={index} style={{height: 25, fontSize: '12px'}} value={reportType}>
                        {translatedName}
                    </MenuItem>
                ))}
        </Select>
    );
}
