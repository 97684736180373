import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReportPeriod } from "../../../enum/ReportPeriod";
import { colors } from "../../../themes/AppTheme";

interface ReportPeriodSelectionProps {
    selectedReportPeriod: string;
    setSelectedReportPeriod: (selectedReportPeriod: ReportPeriod) => void;
}

export default function ReportPeriodSelection(props: ReportPeriodSelectionProps) {
    const { t } = useTranslation();

    function handleReportPeriodChange(event: SelectChangeEvent<any>) {
        props.setSelectedReportPeriod(event.target.value);
    }

    return (
        <Select
            id="file_format"
            size="small"
            value={props.selectedReportPeriod}
            color="info"
            style={{ height: '100%', fontSize: '12px', color: colors.ram_purple }}
            onChange={handleReportPeriodChange}
            fullWidth
        >
            {Object.values(ReportPeriod).map((period: ReportPeriod) => (
                <MenuItem key={period} style={{ height: 25, fontSize: '12px' }} value={period}>
                    {t(`scheduled_report.${period.toLowerCase()}`)}
                </MenuItem>
            ))}
        </Select>
    );
}
