import React, {ChangeEvent, useContext} from "react";
import {FilterSortContext} from "../../../providers/FilterSortProvider";
import {AssetSortOptions} from "../../../utils/AssetArraySorting";
import {useTranslation} from "react-i18next";
import {Asset} from "../../../hooks/assets/dto/Asset";

interface AssetTableSearchBarProps {
    onSearchChange: (search: string) => void;
    assets: Asset[];
}

export default function AssetTableSearchBar(props: Readonly<AssetTableSearchBarProps>) {
    const {t} = useTranslation();
    const {
        sort, setSort,
        groupFilter, setGroupFilter,
        typeFilter, setTypeFilter
    } = useContext(FilterSortContext);

    function handleSortChange(event: ChangeEvent<HTMLSelectElement>) {
        const { value } = event.target;
        setSort(value as AssetSortOptions);
    }

    function handleGroupChange(event: ChangeEvent<any>) {
        setGroupFilter(event.target.value as string);
    }

    function handleTypeChange(event: ChangeEvent<any>) {
        setTypeFilter(event.target.value as string);
    }

    function removeDuplicates(array: any[]) {
        return array.filter((item,
                             index) => array.indexOf(item) === index);
    }

    function sortEntities(a: any, b: any) {
        // Handle null, undefined, and empty string values
        if (a == null || a === '') {
            return -1; // 'a' comes before 'b'
        } else if (b == null || b === '') {
            return 1; // 'b' comes before 'a'
        } else {
            return a.localeCompare(b);
        }
    }

    return (
            <div className="py-1 grid grid-cols-9 gap-2">
                <input
                    type="text"
                    placeholder={t('asset_filter_bar.search_placeholder') as string}
                    className="w-full border-2 border-base-300 p-2 rounded-md col-span-9 lg:col-span-6"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        props.onSearchChange(event.target.value);
                    }}
                />
                <Select
                    id="asset-sort-select"
                    value={sort || AssetSortOptions.DEFAULT}
                    onChange={handleSortChange}
                >
                    <option value={AssetSortOptions.DEFAULT}>{t('asset_filter_bar.sort_options.default')}</option>
                    <option value={AssetSortOptions.DRIVER_NAME}>{t('asset_filter_bar.sort_options.assignee')}</option>
                    <option value={AssetSortOptions.LAST_REPORTED}>{t('asset_filter_bar.sort_options.last_reported')}</option>
                </Select>

                <Select
                    id="asset-group-filter-select"
                    value={groupFilter}
                    onChange={handleGroupChange}
                >
                    <option value={"all"}>{t('asset_filter_bar.filter_options.group.all')}</option>
                    {removeDuplicates(props.assets.map((asset) => asset.groupName))
                        .sort((a, b) => sortEntities(a, b))
                        .map((group) => {
                            if (group === '' || group === undefined || group === null) {
                                return (<option key={`group-filter-none`} value={group}>{t('asset_filter_bar.filter_options.group.none')}</option>)
                            }
                            return (<option key={`group-filter-${group}`} value={group}>{t('asset_filter_bar.filter_options.group.group')}{group}</option>)
                        })}
                </Select>

                <Select
                    id="asset-type-filter-select"
                    value={typeFilter}
                    onChange={handleTypeChange}
                >
                    <option value={"all"}>{t('asset_filter_bar.filter_options.type.all')}</option>
                    {removeDuplicates(props.assets.map((asset) => asset.type))
                        .sort((a, b) => sortEntities(a, b))
                        .map((type) => {
                            if (type === '' || type === undefined || type === null) {
                                return (<option key={`type-filter-none`} value={type}>{t('asset_filter_bar.filter_options.type.none')}</option>)
                            }
                            return (<option key={`type-filter-${type}`} value={type}>{t('asset_filter_bar.filter_options.type.type')}{t('asset_filter_bar.filter_options.type.' + type.toLowerCase())}</option>)
                        })}
                </Select>
            </div>
    );
}

function Select(props: any) {
    return (
        <select className="h-full border-2 bg-base-100 border-base-300 p-2 col-span-3 lg:col-span-1 rounded-md webkit-appearance-none moz-appearance-none appearance-none" {...props}>
            {props.children}
        </select>
    )
}
