import { Box, Select, SelectChangeEvent, Typography } from '@mui/material';


interface SelectInputProps {
    label: string;
    required: boolean;
    editMode: boolean;
    items: JSX.Element[];
    value: string;
    onChange: (value: string) => void;
}

export default function SelectInput(props: SelectInputProps) {

    const handleChange = (event: SelectChangeEvent<string>) => {
        props.onChange(event.target.value as string);
    };

    return (
        <Box sx={{ paddingBottom: "20px" }}>
            <Typography variant="smallTitle">
                {props.label} {props.required ? "*" : ""}
            </Typography>
            <Select
                id={props.label + "-select"}
                value={props.value}
                disabled={!props.editMode}
                onChange={handleChange}
                size='small'
                sx={{
                    width: "100%"
                }}>
                {props.items}
            </Select>
        </Box >
    );
};





