import {
  useContext,
} from "react";

//MUI Component imports
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";

//State
import { UserPreferencesContext } from "../../providers/UserPreferencesProvider";
import NavigationList from "./NavigationList";

interface AppDrawerProps { }

export const drawerWidth = 260;

export const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  height: "50px",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));



function AppDrawer(props: AppDrawerProps) {

  const { openDrawer } = useContext(UserPreferencesContext);

  return (<>
    <Drawer
      variant="permanent"
      open={openDrawer}
      sx={{ display: { xs: "none", sm: "none", md: "block" } }}
    >
      <DrawerHeader>
        <img
            src="/images/logos/ram-tracking.png"
            style={{
              width: "100%",
              height: "40px",
              pointerEvents: "none",
              padding: "3px",
              objectFit: "contain",
            }}
            alt="logo"
        />
      </DrawerHeader> 
      <NavigationList openDrawer={openDrawer} />
    </Drawer>
  </>
  );
}

export default AppDrawer;
