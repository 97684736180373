import { Checkbox, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Table, TableCell, TableRow } from "../core/Table";

interface SelectEnumTableProps<T extends string> {
    loading: boolean;
    header: string;
    values: T[];
    multiselect?: boolean;
    disableSelect?: boolean;
    inOrder?: boolean; // Optional flag to enable/disable sorting
    selectedRows: T[];
    setSelectedRows: (selectedRows: T[]) => void;
    translationKeyPrefix: string; // The prefix for translation keys (for enum display)
}

export default function SelectEnumTable<T extends string>(props: Readonly<SelectEnumTableProps<T>>) {
    const { t } = useTranslation();

    const handleRowSelect = (value: T) => {
        if (props.multiselect) {
            if (props.selectedRows.includes(value)) {
                props.setSelectedRows(props.selectedRows.filter(selected => selected !== value));
            } else {
                props.setSelectedRows([...props.selectedRows, value]);
            }
        } else {
            props.setSelectedRows([value]);
        }
    };

    const handleSelectAllValues = () => {
        if (props.values.length === props.selectedRows.length) {
            props.setSelectedRows([]);
        } else {
            props.setSelectedRows(props.values);
        }
    };

    const selectHeader = props.multiselect ? (
        <Tooltip title={t("select.all")}>
            <Checkbox
                id="select"
                disabled={props.disableSelect}
                checked={props.values.length === props.selectedRows.length}
                onChange={() => handleSelectAllValues()}
            />
        </Tooltip>
    ) : <></>;

    const columns = [
        { id: 'select', content: selectHeader },
        { id: 'enum-value', content: props.header }
    ];

    // Sort the enum values according to their translations
    const sortedValues = props.inOrder
        ? props.values.slice().sort((a, b) => {
            const translatedA = t(`${props.translationKeyPrefix}.${a.toLowerCase()}`);
            const translatedB = t(`${props.translationKeyPrefix}.${b.toLowerCase()}`);
            return translatedA.localeCompare(translatedB);
        })
        : props.values;

    const rows = sortedValues.map((value) => (
        <TableRow key={value}>
            <TableCell>
                <Checkbox
                    id={`select-${props.header}-${value}`}
                    disabled={props.disableSelect}
                    checked={props.selectedRows.includes(value)}
                    onChange={() => handleRowSelect(value)}
                />
            </TableCell>
            <TableCell>{t(`${props.translationKeyPrefix}.${value.toLowerCase()}`)}</TableCell>
        </TableRow>
    ));

    return (
        <Table
            id={`select-${props.header}-table`}
            label={`select-${props.header}-table`}
            columns={columns}
            disableCreateButton={true}
            rows={rows}
            loading={props.loading}
        />
    );
}
