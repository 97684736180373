import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const containerStyle = {
    width: "100%",
    padding: '20px 12px'
};

interface LeftPanelButtonBarProps {
    onBackPressed: Function;
    children?: React.ReactNode;
}

export default function LeftPanelButtonBar(props: LeftPanelButtonBarProps) {
    const { t } = useTranslation();
    return (
        <div style={containerStyle}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%', // Ensure the ButtonGroup takes full width
            }}>
                <Button variant="contained" color="primary" onClick={(event) => props.onBackPressed()}>
                    {t('button.back')}
                </Button>
                {props.children}
            </Box>
        </div>
    )
}
