import {Grid} from "@mui/material";
import ReportTypePanel from "../ReportTypePanel";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {ReportTypeResponse} from "../../../hooks/reports/dto/ReportTypeResponse";
import {useReportsApi} from "../../../hooks/reports/Reports";
import {SelectorLabel} from "./SelectorLabel";
import {ReportType} from "../../../enum/ReportType";

interface ReportTypeSelectorProps {
    reportTypes: ReportType[];
    selectedReportType: ReportType;
    setSelectedReportType: (selectedReportType: ReportType) => void;
}

export function ReportTypeSelector(props: ReportTypeSelectorProps) {

    const { t } = useTranslation();
    const { getAvailableReports } = useReportsApi();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [availableReports, setAvailableReports] = React.useState<ReportType[]>([]);

    const retrieveAvailableReports = async (tabReports: string[]) => {
        let reportTypes: ReportTypeResponse[] = await getAvailableReports() as ReportTypeResponse[];
        let availableReports: ReportType[] = reportTypes
            .filter(value => tabReports.includes(value.reportCode))
            .map(value => value.reportCode);
        setAvailableReports(availableReports);


        if (props.selectedReportType && availableReports.includes(props.selectedReportType)) {
            props.setSelectedReportType(props.selectedReportType);
        } else {
            props.setSelectedReportType(availableReports[0]);
        }

    }

    useEffect(() => {
        setLoading(true);
        retrieveAvailableReports(props.reportTypes)
            .finally(() => setLoading(false));
    }, [props.reportTypes]);

    return <Grid container>
        <SelectorLabel
            title={t("report.report_type")}
            subtitle={t("report.select_report_type")}
            />
        <Grid item xs={12} md={12} lg={10} padding="20px">
            <Grid container spacing={2} flexDirection="row" paddingTop={"10px"} paddingLeft={"15px"}>
                <ReportTypePanel loading={loading} reportTypes={availableReports} reportTypeSelected={props.selectedReportType}
                                 handleRadioClick={props.setSelectedReportType} />
            </Grid>
        </Grid>
    </Grid>
}
