import {ListItemText} from '@mui/material';

interface AssetDetailStatusHeadingProps {
    title: String;
    titleColor: string;
}

function AssetDetailStatusHeading(props: AssetDetailStatusHeadingProps) {
    return (
        <ListItemText sx={{
            color: props.titleColor
        }}
                      primary={"● " + props.title.charAt(0).toUpperCase() + props.title.slice(1).toLowerCase()}/>
    );
}

export default AssetDetailStatusHeading;
