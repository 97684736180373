import {RefObject, useContext, useState} from "react";
import "./GeofencesMapContainer.css";

//Map Components
import {MapContainer, FeatureGroup, AttributionControl, useMapEvents} from "react-leaflet";
import { Map } from "leaflet";
import MapContextMenu from "../../../map/components/MapContextMenu";
import MapTileLayer from "../../../../components/map/MapTileLayer";
import ZoomMapControl from "../../../map/components/ZoomMapControl";
import MapLayerControl from "../../../map/components/MapLayerControl";

import { UserPreferencesContext } from "../../../../providers/UserPreferencesProvider";
import GeofenceLayer from "../../../../components/map/GeofenceLayer";
import React from "react";
import { resizeMap } from "../../../../utils/MapUtils";
import { GeofenceWizardContext } from "../../../../providers/GeofenceWizardProvider";
import { EditControl } from "react-leaflet-draw";
import L from "leaflet";
import { Feature } from 'geojson';
import { Geofence } from "../../../../hooks/geofences/dto/Geofence";

const containerStyle = {
    width: "100%",
    height: "calc(100vh - 48px)",
    margin: "0 auto",
};

interface GeofencesMapContainerProps {
    mapref?: React.RefObject<Map>;
    geofences: Geofence[];
}

const GeofencesMapContainer = (props: GeofencesMapContainerProps) => {
    const { loading, newGeofence, id, setEditingFeature, name, colour, feature, setFeature } = useContext(GeofenceWizardContext);
    const { mapType, setMapType } = useContext(UserPreferencesContext);
    const featureGroupRef = React.useRef<L.FeatureGroup>(null);
    const createdFeatureGroupRef = React.useRef<L.FeatureGroup>(null);
    const [mapLayerControllerOpen, setMapLayerControllerOpen] = useState(false);

    React.useEffect(() => {
        if (!feature) {
            featureGroupRef.current?.clearLayers();
            createdFeatureGroupRef.current?.clearLayers();
        }

        if (featureGroupRef.current?.getLayers().length && featureGroupRef.current?.getLayers().length > 0) {
            featureGroupRef.current?.clearLayers();
        }

        if (createdFeatureGroupRef.current?.getLayers().length && createdFeatureGroupRef.current?.getLayers().length > 0) {
            createdFeatureGroupRef.current?.clearLayers();
        }

        if (featureGroupRef.current?.getLayers().length === 0 && feature) {
            drawFeatureToFeatureGroup(feature, featureGroupRef)
        } else if (featureGroupRef.current?.getLayers().length && featureGroupRef.current?.getLayers().length > 0 && !feature) {
            featureGroupRef.current?.clearLayers();
        }

        if (createdFeatureGroupRef.current?.getLayers().length === 0 && feature) {
            drawFeatureToFeatureGroup(feature, createdFeatureGroupRef)
        } else if (createdFeatureGroupRef.current?.getLayers().length && createdFeatureGroupRef.current?.getLayers().length > 0 && !feature) {
            createdFeatureGroupRef.current?.clearLayers();
        }

    }, [feature, id, colour, name]);

    function drawFeatureToFeatureGroup(feature: Feature, featureGroupRef: RefObject<L.FeatureGroup>) {
        L.geoJSON(feature).eachLayer((layer) => {
            if (
                layer instanceof L.Polyline ||
                layer instanceof L.Polygon ||
                layer instanceof L.Marker
            ) {
                let tooltip = new L.Tooltip({ direction: "center" })
                tooltip.setContent(name)

                if (layer?.feature?.properties.radius && featureGroupRef.current) {
                    let circle = new L.Circle(layer.feature.geometry.coordinates.slice().reverse(), {
                        radius: layer.feature?.properties.radius,
                    })
                    circle.setStyle({ color: colour })
                    circle.bindTooltip(tooltip)
                    circle.addTo(featureGroupRef.current);
                } else {
                    layer.bindTooltip(tooltip)
                    if (layer instanceof L.Polygon) {
                        layer.setStyle({ color: colour })
                    }
                    featureGroupRef.current?.addLayer(layer);
                }
            }
        });
    }

    function handleEditSaved(e: any) {
        let layers = e.layers.getLayers()
        if (layers.length === 1) {
            let layer = layers[0]
            if (layer instanceof L.Circle) {
                const circleFeature = layer.toGeoJSON();
                circleFeature.properties.radius = layer.getRadius();
                setFeature(circleFeature)
            } else {
                const polygonFeature = layer.toGeoJSON();
                setFeature(polygonFeature)
            }
        }
    };

    function handlePolygonCreated(e: any) {
        const polygonFeature = e.layer.toGeoJSON();
        setFeature(polygonFeature)
    };

    function handleStartDrawing() {
        if (createdFeatureGroupRef.current?.getLayers().length && createdFeatureGroupRef.current?.getLayers().length > 0) {
            createdFeatureGroupRef.current?.clearLayers();
        }
        setEditingFeature(true)
    }

    function handleStopDrawing(e: any) {
        setEditingFeature(false)
    }

    const handleClickOperation = () => {
        setMapLayerControllerOpen(!mapLayerControllerOpen);
    };

    function MapEventHandler() {
        useMapEvents({
            click: (event) => {
                setMapLayerControllerOpen(false);
            }
        })

        return <></>
    }

    return (<div
        style={containerStyle}
    >
        <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/leaflet.min.css" />
        <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.4/leaflet.draw.css" />

        <MapContainer
            ref={props.mapref}
            attributionControl={false}
            style={containerStyle}
            center={[53.801277, -1.548567]}
            zoom={7}
            scrollWheelZoom={true}
            zoomControl={false}
            id="leaflet-container"
            whenReady={() => { resizeMap(props.mapref!) }}
        >
            <AttributionControl position="bottomright" prefix="" />

            {/* Map Tile Layer - Selected by the MapLayerControl */}
            <MapTileLayer mapLayer={mapType} />

            <MapEventHandler/>

            {/* Context Menu - Currently only used for street view */}
            <MapContextMenu />

            {/* Map Controls */}
            <ZoomMapControl onZoomInClick={() => { props.mapref?.current?.zoomIn() }} onZoomOutClick={() => { props.mapref?.current?.zoomOut() }} />
            <MapLayerControl placement="topleft" selectedLayer={mapType} setSelectedLayer={setMapType} open={mapLayerControllerOpen}
                             handleClickOperation={handleClickOperation}/>

            {/* Edit Tools */}

            {!loading && id !== undefined && (
                <FeatureGroup ref={featureGroupRef}>
                    <EditControl
                        position='topleft'
                        onEditStart={handleStartDrawing}
                        onEdited={handleEditSaved}
                        onEditStop={handleStopDrawing}
                        draw={{
                            rectangle: false,
                            polyline: false,
                            polygon: false,
                            circle: false,
                            circlemarker: false,
                            marker: false,
                        }}
                        edit={{
                            edit: true,
                            remove: false,
                        }}
                    />
                </FeatureGroup>
            )}


            {!loading && newGeofence && (
                <FeatureGroup
                    ref={createdFeatureGroupRef}
                >
                    <EditControl
                        position='topleft'
                        onDrawStart={handleStartDrawing}
                        onDrawStop={handleStopDrawing}
                        onCreated={handlePolygonCreated}
                        draw={{
                            rectangle: false,
                            polyline: false,
                            polygon: true,
                            circle: false,
                            circlemarker: false,
                            marker: false,
                        }}
                        edit={{
                            edit: false,
                            remove: false,
                        }}
                    />
                </FeatureGroup>
            )}

            {!loading && (props.geofences.length > 0) && (id === undefined) && (!newGeofence) ?
                (<GeofenceLayer geofences={props.geofences} labelsEnabled={true} geofenceEnabled={true} />) :
                (<></>)}

        </MapContainer>
    </div>)
}

export default GeofencesMapContainer;
