import {intervalToDuration} from "date-fns";
import dayjs from "dayjs";

export function getDateDifference(start: Date, end: Date): Duration {
    return intervalToDuration({
        start: start,
        end: end,
    })
}

export function getTomorrowDate() {
    return dayjs().add(1, 'day').format('YYYY-MM-DDTHH:mm:ss');
}
