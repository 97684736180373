import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import { ListItemButton, ListItemText } from "@mui/material";
import AppBarButton from './AppBarButton';
import { FlagIcon } from "react-flag-kit";
import { useContext } from 'react';
import { UserPreferencesContext } from '../../providers/UserPreferencesProvider';

export default function LanguageSelector() {
    const { languageCode, setLanguageCode } = useContext(UserPreferencesContext);

    const handleLanguageChange = (lang: string) => {
        setLanguageCode(lang);
    };



    function getFlagCodeForLanguage(language: string) {
        switch (language) {
            case 'en':
                return 'GB';
            case 'fr':
                return 'FR';
            default:
                return 'GB';
        }
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center' }}>
                <AppBarButton
                    id='language-selector-button'
                    icon={<FlagIcon code={getFlagCodeForLanguage(languageCode)} />}
                        onClick={handleClick}
                        ariaControls={open ? 'account-menu' : undefined}
                        ariaHasPopup={true}
                        ariaExpanded={open ? true : undefined}
                />
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="product-selector"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <ListItemButton
                    onClick={() => {
                        handleLanguageChange('en');
                        handleClose();
                    }}>
                    <ListItemIcon>
                        <FlagIcon code="GB" />
                    </ListItemIcon>
                    <ListItemText primary="English (UK)" />
                </ListItemButton>
                <Divider />
                <ListItemButton
                    onClick={() => {
                        handleLanguageChange('fr');
                        handleClose();
                    }}>
                    <ListItemIcon>
                        <FlagIcon code="FR" />
                    </ListItemIcon>
                    <ListItemText primary="Français" />
                </ListItemButton>
            </Menu>
        </React.Fragment>
    );
}