import {Checkbox, Tooltip} from "@mui/material";
import { useTranslation } from "react-i18next";
import {Table, TableCell, TableRow} from "../core/Table";

interface SelectRecipientTableProps {
    loading: boolean;
    recipients: string[];
    multiselect?: boolean;
    disableSelect?: boolean;
    selectedRows: string[];
    setSelectedRows: (selectedRows: string[]) => void;
}

export default function SelectRecipientTable(props: Readonly<SelectRecipientTableProps>) {
    const { t } = useTranslation();

    const handleRowSelect = (recipient: string) => {
        if(props.multiselect) {
            if (props.selectedRows.includes(recipient)) {
                props.setSelectedRows(props.selectedRows.filter(selectedAsset => selectedAsset !== recipient));
            } else {
                props.setSelectedRows([...props.selectedRows, recipient]);
            }
        } else {
            props.setSelectedRows([recipient]);
        }
    };

    const handleSelectAllRecipients = () => {
        if (props.recipients.length === props.selectedRows.length) {
            props.setSelectedRows([]);
        } else {
            props.setSelectedRows(props.recipients.map(recipient => recipient));
        }
    };

    const selectHeader = props.multiselect ? (
        <Tooltip title={t("select.all")}>
        <Checkbox
            id="select"
            disabled={props.disableSelect}
            checked={props.recipients.length === props.selectedRows.length}
            onChange={() => handleSelectAllRecipients()}
        />
    </Tooltip>) : <></>;

    const columns = [
        { id:'select', content: selectHeader },
        { id:'recipients', content: t("event.recipients") }
    ];

    const rows = props.recipients?.map((recipient) => {
        return <TableRow key={recipient}>
            <TableCell>
                <Checkbox
                    id={"select-recipient-" + recipient}
                    disabled={props.disableSelect}
                    checked={props.selectedRows.includes(recipient)}
                    onChange={() => handleRowSelect(recipient)}
                />
            </TableCell>
            <TableCell>{recipient}</TableCell>
        </TableRow>

    });


    return (
        <Table
            id="select-recipient-table"
            label="select-recipient-table"
            columns={columns}
            disableCreateButton={true}
            rows={rows}
            loading={props.loading}/>
    );
}