import React from "react";
import "./Holding.css";

function Holding() {
    return (
        <div className="App">
            <header className="App-header">
                <img
                    src="/images/logos/ram-tracking.png"
                    className="App-logo"
                    alt="logo"
                />
                <p>
                    RAM Tracking is evolving. Check back soon to see what we are building!
                </p>
            </header>
        </div>
    );
}

export default Holding;