import dayjs from "dayjs";

export const formatTime = (time: string, twentyFourHourEnabled: boolean) => {
    const [hours, minutes, seconds] = time.split(':');

    let formattedTime = `${hours}:${minutes}:${seconds}`;

    if (!twentyFourHourEnabled) {
        const parsedHours = parseInt(hours, 10);
        const ampm = parsedHours >= 12 ? 'PM' : 'AM';
        const displayHours = parsedHours % 12 || 12;

        formattedTime = `${displayHours}:${minutes}:${seconds} ${ampm}`;
    }

    return formattedTime;
};

export function extractTime(dateTime: string) {
    const dateTimeParts = dateTime.split(":");
    return dayjs().hour(Number(dateTimeParts[0])).minute(Number(dateTimeParts[1])).second(Number(dateTimeParts[2]));
}
