import {Alert, Grid} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {colors} from "../../../themes/AppTheme";
import dayjs, {Dayjs} from "dayjs";
import {useTranslation} from "react-i18next";
import useAccountApi, {FeaturePackage} from "../../../hooks/account/Account";
import {SelectorLabel} from "./SelectorLabel";
import React, {useContext} from "react";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {UserPreferencesContext} from "../../../providers/UserPreferencesProvider";

interface DateRangeSelectorProps {
    selectedFromDate: Dayjs;
    setSelectedFromDate: (selectedFromDate: Dayjs) => void;
    selectedToDate: Dayjs;
    setSelectedToDate: (selectedToDate: Dayjs) => void;

}

export function DateRangeSelector(props: DateRangeSelectorProps) {

    const {t} = useTranslation();

    const {account} = useAccountApi();
    const {languageCode} = useContext(UserPreferencesContext)

    function calculateMinDate() {
        if (account?.featurePackage === FeaturePackage.LITE || account?.featurePackage === FeaturePackage.LEGACY_LITE) {
            return dayjs().startOf('date').utc(true).subtract(3, 'month')
        } else {
            return dayjs().startOf('date').utc(true).subtract(3, 'year')
        }
    }

    const ramLitePanel = <Grid container>
        <Grid item xs={12} md={12} lg={12} sx={{padding: '10px'}}>
            <Alert severity="info">{t('report.ram_lite_message')}</Alert>
        </Grid>
    </Grid>

    return <>
        {account?.featurePackage === FeaturePackage.LITE || account?.featurePackage === FeaturePackage.LEGACY_LITE ? ramLitePanel : null}
        <Grid container>
            <SelectorLabel
                title={t("report.date_range")}
                subtitle={t("report.select_dates")}
            />
            <Grid item xs={12} md={4} lg={5} padding="20px">
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={languageCode}
                >
                    <DatePicker
                        sx={{
                            fontSize: 12,
                            fontWeight: 'bold',
                            color: colors.ram_purple,
                        }}
                        disableFuture
                        minDate={calculateMinDate()}
                        label={t("report.date_from")}
                        value={props.selectedFromDate}
                        format="DD/MM/YYYY"
                        slotProps={{textField: {fullWidth: true}}}
                        onChange={(newDate) => {
                            if (newDate != null) {
                                props.setSelectedFromDate(newDate)
                                if (props.selectedToDate > newDate.add(30, 'day')) {
                                    props.setSelectedToDate(newDate.add(30, 'day'))
                                }
                                if (props.selectedToDate < newDate) {
                                    props.setSelectedToDate(newDate)
                                }
                            } else {
                                props.setSelectedFromDate(dayjs().startOf('date').utc(true))
                            }
                        }}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4} lg={5} padding="20px">
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={languageCode}
                >
                    <DatePicker
                        sx={{
                            fontSize: 12,
                            fontWeight: 'bold',
                            color: colors.ram_purple,
                        }}
                        disableFuture
                        minDate={props.selectedFromDate}
                        maxDate={props.selectedFromDate.add(30, 'day')}
                        label={t("report.date_to")}
                        value={props.selectedToDate}
                        format="DD/MM/YYYY"
                        slotProps={{textField: {fullWidth: true}}}
                        onChange={(newDate) => {
                            if (newDate != null) {
                                props.setSelectedToDate(newDate)
                            } else {
                                props.setSelectedToDate(dayjs().startOf('date').utc(true))
                            }
                        }}
                    />
                </LocalizationProvider>
            </Grid>
        </Grid>
    </>
}
