import { RefObject } from "react";
import { LatLngExpression, Map } from "leaflet";
import { Position } from 'geojson';

export function resizeMap(mapRef: RefObject<Map>) {
    const resizeObserver = new ResizeObserver(() =>
        mapRef.current?.invalidateSize()
    );
    const container = document.getElementById("leaflet-container");
    if (container) {
        resizeObserver.observe(container);
    }
};

export function convertPositionArrayToLatLngArray(positions: Position[]): LatLngExpression[] {
    return positions.map(position => convertPositionToLatLng(position));
}

export function convertPositionToLatLng(position: Position): LatLngExpression {
    return [position[1], position[0]];
}

export function convertLatLngToPosition(latLng: [number, number]): Position {
    return [latLng[1], latLng[0]];
}