import {useEffect, useState} from "react";
import usePlatformFetch from "../shared/PlatformFetch";
import PropertiesLoader from "../../properties/PropertiesLoader";
import {EngineHourAlertType} from "../../pages/alerts/engineHours/components/EngineHourAlertTypes";

const properties = PropertiesLoader();
export class EngineHourAlert {
    id: number;
    accountId: number;
    type: EngineHourAlertType;
    triggerValue: number;
    recipients: string[];
    vehicles: string[];

    constructor(props: {
        id: number;
        accountId: number;
        type: EngineHourAlertType;
        triggerValue: number;
        recipients: string[];
        vehicles: string[];
    }) {
        this.id = props.id;
        this.accountId = props.accountId;
        this.type = props.type;
        this.triggerValue = props.triggerValue;
        this.recipients = props.recipients;
        this.vehicles = props.vehicles;
    }
}

export class EngineHoursAlertRequest {
    type: EngineHourAlertType;
    triggerValue: number;
    recipients: number[];
    vehicles: number[];

    constructor(props: {
        type: EngineHourAlertType;
        triggerValue: number;
        recipients: number[];
        vehicles: number[];
    }) {
        this.type = props.type;
        this.triggerValue = props.triggerValue;
        this.recipients = props.recipients;
        this.vehicles = props.vehicles;
    }
}

interface EngineHourAlertsApiOptions {
    shouldLoadAll?: boolean;
    shouldPoll?: boolean;
    pollFrequency?: number;
}

export function useEngineHoursAlertsApi(props: EngineHourAlertsApiOptions = {}) {
    const {platformFetch, checkOk, checkCreated} = usePlatformFetch();

    const [engineHourAlerts, setEngineHourAlerts] = useState<EngineHourAlert[] | null>(null);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const getAllEngineHoursAlerts = async () => {
        return new Promise((resolve, reject) => {
            setLoading(true)
            platformFetch(
                properties.platformApiEndpoint + "tracking/v1/alerts/engine-hour-alerts",
                'get')
                .then(checkOk)
                .then(response => response.json())
                .then(alerts => {
                    setEngineHourAlerts(alerts)
                    resolve(alerts)
                })
                .catch((error) => {
                    console.error("Error getting engine hours alerts: " + error)
                    setError(error)
                    reject(error)
                })
                .finally(() => {
                    setLoading(false)
                });
        });
    }

    const createEngineHoursAlert = async (engineHoursAlertRequest: EngineHoursAlertRequest) => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + "tracking/v1/alerts/engine-hours-alerts",
                'post',
                JSON.stringify(engineHoursAlertRequest))
                .then(checkCreated)
                .then(response => response.json())
                .then(alert => resolve(alert))
                .catch((error) => {
                    console.error("Error creating engine hours alert: " + error)
                    reject(error)
                });
        });
    }

    const updateEngineHoursAlert = async (engineHoursAlertRequest: EngineHoursAlertRequest, alertId: string) => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `tracking/v1/alerts/engine-hours-alerts/${alertId}`,
                'put',
                JSON.stringify(engineHoursAlertRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(alert => resolve(alert))
                .catch((error) => {
                    console.error("Error updating engine hours alerts: " + error)
                    reject(error)
                });
        });
    }

    const deleteEngineHoursAlert = async (engineHoursAlertId: string) => {
        return new Promise<void>((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `tracking/v1/alerts/engine-hours-alerts/${engineHoursAlertId}`,
                'delete')
                .then(checkOk)
                .then(alert => resolve())
                .catch((error) => {
                    console.error("Error deleting engine hours alert: " + error)
                    reject(error)
                });
        });
    }

    useEffect(() => {
        if (props.shouldLoadAll) {
            getAllEngineHoursAlerts();
            if (props.shouldPoll) {
                const interval = setInterval(getAllEngineHoursAlerts, props.pollFrequency || 30000);
                return () => clearInterval(interval);
            }
        }
    }, []);

    return {loading, error, engineHourAlerts: engineHourAlerts, getAllEngineHoursAlerts, createEngineHoursAlert, deleteEngineHoursAlert, updateEngineHoursAlert}
}
