import { Asset } from "../../hooks/assets/dto/Asset";
import AssetCard from "./AssetCard";
import AssetsListSectionHeading from "./AssetsListSectionHeading";
import React from "react";
import { Map } from "leaflet";
import ReactGA from "react-ga4";

interface AssetsViewProps {
    mapref: React.RefObject<Map>;
    title: string;
    titleColor: string;
    assets: Array<Asset>;
    setSelectedAsset: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export default function AssetsListSection(props: AssetsViewProps) {
    const clickedVehicle = (asset: Asset | undefined) => {
        ReactGA.event("RT2-LiveMap-SelectedVehicle");
        props.setSelectedAsset(asset === undefined ? undefined : asset.id.toString())
    }

    const [open, setOpen] = React.useState(true);

    return (
        <>
            <AssetsListSectionHeading title={props.title} titleColor={props.titleColor} numberOfAssets={props.assets.length} open={open} onClick={() => { setOpen(!open) }} />
            {open ? props.assets.map((asset) => {
                return (<AssetCard key={asset.id} asset={asset} mapref={props.mapref} clickedVehicle={clickedVehicle} />)
            }) : <></>}
        </>
    );
}