import {Box, Button, Grid, Paper, Typography, useTheme} from "@mui/material";

import {useTranslation} from "react-i18next";

import React, {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import FullView from "../../layouts/FullView";
import TextInput from "../../components/form/TextInput";
import {WebhookRequest} from "../../hooks/webhooks/dto/WebhookRequest";
import {VehicleEvent} from "../../enum/VehicleEvent";
import {useWebhookApi} from "../../hooks/webhooks/Webhooks";
import SelectEnumTable from "../../components/table/shared/SelectEnumTable";


export default function WebhooksPageEditor() {
    return (
        <FullView content={<WebhooksPageEditorContent/>}/>
    );
}

function WebhooksPageEditorContent() {

    const theme = useTheme();
    const navigate = useNavigate()
    const {t} = useTranslation();
    const {createWebhook, updateWebhook} = useWebhookApi();

    const location = useLocation();
    const isState = location.state !== undefined && location.state !== null;
    const isEdit = isState && location.state.selectedWebhook !== null && location.state.selectedWebhook !== undefined;
    const selectedWebhook = isEdit ? location.state.selectedWebhook : undefined;

    const [name, setName] = useState<string>(isEdit ? selectedWebhook?.name : '')
    const [link, setLink] = useState<string>(isEdit ? selectedWebhook?.url : '')
    const [authHeader, setAuthHeader] = useState<string>(isEdit ? selectedWebhook?.authHeader : '')
    const [events, setEvents] = useState(isEdit ? selectedWebhook?.events : []);

    const handleCreateWebhook = async () => {

        await createWebhook(new WebhookRequest({
            name: name,
            url: link,
            authHeader: authHeader,
            events: events
        }))
            .then((webhook) => {
                navigate('/webhooks')
            })
            .catch((error) => {
                alert(t('webhook_page.webhook_messages.webhook_create_failed'))
            })
    }

    const handleUpdateWebhook = async () => {

        await updateWebhook(new WebhookRequest({
            name: name,
            url: link,
            authHeader: authHeader,
            events: events
        }), selectedWebhook?.id)
            .then((webhook) => {
                navigate('/webhooks')
            })
            .catch((error) => {
                alert(t('webhook_page.webhook_messages.webhook_update_failed'))
            })
    }


    const handleCancel = () => {
        navigate('/webhooks')
    }

    return (

        <Box
            component="main"
            sx={{
                width: '100%',
                height: '100%',
                backgroundColor: theme.palette.contentPage.background
            }}
        >
            <Grid container component={Paper} sx={{padding: '10px', height: '100%', width: '100%'}} elevation={3}>

                {/* Name */}
                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "20px"}}
                                    variant="title">{t("webhook_page.webhook_table.name")}</Typography>
                        <Typography sx={{marginLeft: "20px"}}
                                    variant="body">{t("webhook_page.webhook_table.name_selection_message")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <TextInput editMode={true} isNumber={false} required={true} label={''} placeholder={''}
                                   value={name} onChange={setName}/>
                    </Grid>
                </Grid>

                {/* Link */}
                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "20px"}}
                                    variant="title">{t("webhook_page.webhook_table.link")}</Typography>
                        <Typography sx={{marginLeft: "20px"}}
                                    variant="body">{t("webhook_page.webhook_table.link_selection_message")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <TextInput editMode={true} isNumber={false} required={true} label={''} placeholder={''}
                                   value={link} onChange={setLink}/>
                    </Grid>
                </Grid>

                {/* Auth Header */}
                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "20px"}}
                                    variant="title">{t("webhook_page.webhook_table.auth_header")}</Typography>
                        <Typography sx={{marginLeft: "20px"}}
                                    variant="body">{t("webhook_page.webhook_table.auth_header_selection_message")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <TextInput editMode={true} isNumber={false} required={false} label={''} placeholder={''}
                                   value={authHeader} onChange={setAuthHeader}/>
                    </Grid>
                </Grid>

                {/* Events */}
                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "10px"}} variant="title">{t("event.event_type")}</Typography>
                        <Typography sx={{marginLeft: "20px"}} variant="body">{t("event.select_event_type")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <SelectEnumTable
                            multiselect={true}
                            loading={false}
                            header={t("webhook_page.webhook_table.events")}
                            values={Object.values(VehicleEvent)}
                            inOrder={true}
                            selectedRows={events}
                            setSelectedRows={setEvents}
                            translationKeyPrefix={"vehicle_event"}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                        <Box
                            bottom="0px"
                            sx={{
                                padding: '10px',
                                display: 'flex', flexDirection: "row", justifyContent: "space-between"
                            }}>
                            <Button
                                variant="contained"
                                size='small'
                                color="secondary"
                                onClick={() => {
                                    handleCancel()
                                }}>
                                {t('button.cancel')}
                            </Button>
                            <Button
                                disabled={events.length === 0 || name === '' || link === ''}
                                variant="contained"
                                size='small'
                                color="primary"
                                onClick={isEdit ? handleUpdateWebhook : handleCreateWebhook}
                            >
                                {isEdit ? t("button.edit") : t("button.create")}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};
