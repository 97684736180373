import React from "react";
import ProfileContentPanel from "../profile/components/content/ProfileContentPanel";
import FullView from "../../layouts/FullView";

interface ImpersonatePageProps {
}

export default function ImpersonatePage(props: ImpersonatePageProps) {
    return (
        <FullView content={<ProfileContentPanel/>}/>
    );
}
