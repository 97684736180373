import {MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {colors} from "../../../../themes/AppTheme";


interface IntervalSelectionProps {
    selectedInterval: number | undefined;
    setSelectedInterval: (selectedInterval: number) => void;
}

export default function IntervalSelection(props: Readonly<IntervalSelectionProps>) {

    function handleIntervalChange(event: SelectChangeEvent<any>) {
        props.setSelectedInterval(event.target.value);
    }

    return (
        <Select
            id="file_format"
            size="small"
            value={props.selectedInterval}
            color="info"
            style={{height: '100%', fontSize: '12px', color: colors.ram_purple}}
            onChange={handleIntervalChange}
            fullWidth
        >
            <MenuItem key="interval-1000" style={{height: 25, fontSize: '12px'}} value={1000}>{1000}</MenuItem>
            <MenuItem key="interval-2000" style={{height: 25, fontSize: '12px'}} value={2000}>{2000}</MenuItem>
            <MenuItem key="interval-3000" style={{height: 25, fontSize: '12px'}} value={3000}>{3000}</MenuItem>
            <MenuItem key="interval-4000" style={{height: 25, fontSize: '12px'}} value={4000}>{4000}</MenuItem>
            <MenuItem key="interval-5000" style={{height: 25, fontSize: '12px'}} value={5000}>{5000}</MenuItem>
            <MenuItem key="interval-6000" style={{height: 25, fontSize: '12px'}} value={6000}>{6000}</MenuItem>
            <MenuItem key="interval-8000" style={{height: 25, fontSize: '12px'}} value={8000}>{8000}</MenuItem>
            <MenuItem key="interval-10000" style={{height: 25, fontSize: '12px'}} value={10000}>{10000}</MenuItem>
            <MenuItem key="interval-12000" style={{height: 25, fontSize: '12px'}} value={12000}>{12000}</MenuItem>
            <MenuItem key="interval-15000" style={{height: 25, fontSize: '12px'}} value={15000}>{15000}</MenuItem>
            <MenuItem key="interval-20000" style={{height: 25, fontSize: '12px'}} value={20000}>{20000}</MenuItem>
            <MenuItem key="interval-25000" style={{height: 25, fontSize: '12px'}} value={25000}>{25000}</MenuItem>
        </Select>
    );
}
