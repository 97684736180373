import {AlertColor, Divider} from "@mui/material";
import dayjs from "dayjs";
import React, {useContext, useEffect} from "react";
import Paper from "@mui/material/Paper";
import {DateRangeSelector} from "./shared/DateRangeSelector";
import {FileFormatSelector} from "./shared/FileFormatSelector";
import {FileFormat} from "../../enum/FileFormat";
import {AssetSelector} from "./shared/AssetSelector";
import {
    ReportStyle,
    ReportStyleSelector,
    VEHICLE_GROUP_FINANCIAL_REPORTS,
    VEHICLE_GROUP_REPORTS,
    VEHICLE_REPORT_GROUPS,
    VEHICLE_SINGLE_FINANCIAL_REPORTS,
    VEHICLE_SINGLE_REPORTS,
    VEHICLE_SUMMARY_REPORTS
} from "./shared/ReportStyleSelector";
import {ReportTypeSelector} from "./shared/ReportTypeSelector";
import {ReportButtonBar} from "./shared/ReportButtonBar";
import {ReportRequest} from "../../hooks/reports/dto/ReportRequest";
import {getCountry} from "../../enum/Country";
import {UserPreferencesContext} from "../../providers/UserPreferencesProvider";
import utc from 'dayjs/plugin/utc';
import {SummariseBySelector} from "./shared/SummariseBySelector";
import {SummariseIncludeSelector} from "./shared/SummariseIncludeSelector";
import {ReportEmailSelector} from "./shared/ReportEmailSelector";
import ReactGA from "react-ga4";
import {ReportPreferencesContext} from "../../providers/ReportPreferencesProvider";
import isEmailValid from "../../utils/EmailValidator";
import {ReportType} from "../../enum/ReportType";
import {UserLocationTableSelector} from "./shared/UserLocationTableSelector";

interface VehicleReportsPageProps {
    requestReport: (request: ReportRequest) => void;
    showReportMessage: (message: string, severity: AlertColor) => void;
}

export function VehicleReportsPage(props: VehicleReportsPageProps) {
    const {languageCode} = useContext(UserPreferencesContext)
    const {vehicleReportPreferences} = useContext(ReportPreferencesContext);
    const {
        selectedFromDate, setSelectedFromDate,
        selectedToDate, setSelectedToDate,
        selectedReportStyle, setSelectedReportStyle,
        selectedFileFormat, setSelectedFileFormat,
        selectedAssets, setSelectedAssets,
        selectedReportType, setSelectedReportType,
        selectedByWeek, setSelectedByWeek,
        selectedByMonth, setSelectedByMonth,
        selectedByVehicle, setSelectedByVehicle,
        selectedByDriver, setSelectedByDriver,
        selectedIncludeFullReport, setSelectedIncludeFullReport,
        selectedIncludeSummaryTotals, setSelectedIncludeSummaryTotals,
        selectedEmail, setSelectedEmail,
        selectedUserLocationRows, setSelectedUserLocationRows,
    } = vehicleReportPreferences;

    // // Import and enable the UTC plugin for dayjs
    dayjs.extend(utc);

    function createDisabled() {
        return selectedAssets.length < 1 || isEmailRequired() || isUserLocationRequired()
    }

    const isEmailRequired = (): boolean => {
        if (selectedReportType === ReportType.TIMESHEET_REPORT_FINANCE) {
            return false;
        }

        if (selectedReportStyle === VEHICLE_GROUP_FINANCIAL_REPORTS ||
            selectedReportStyle === VEHICLE_SUMMARY_REPORTS ||
            selectedReportStyle === VEHICLE_GROUP_REPORTS) {
            return selectedEmail === '' || !isEmailValid(selectedEmail);
        }

        return false;
    };

    const isUserLocationRequired = (): boolean => {
        if ((selectedReportStyle === VEHICLE_SINGLE_FINANCIAL_REPORTS &&
                selectedReportType === ReportType.STOP_LOCATION_FINANCE_REPORT) ||
            (selectedReportStyle === VEHICLE_GROUP_FINANCIAL_REPORTS &&
                selectedReportType === ReportType.TIMESHEET_REPORT_FINANCE)) {
            return selectedUserLocationRows.length < 1;
        }

        return false;
    };

    function handleClear() {
        setSelectedFromDate(dayjs().startOf('date').utc(true))
        setSelectedToDate(dayjs().startOf('date').utc(true))
        setSelectedReportStyle(VEHICLE_SINGLE_REPORTS)
        setSelectedFileFormat(FileFormat.PDF)
        setSelectedReportType(VEHICLE_SINGLE_REPORTS.reportTypes[0])
        setSelectedAssets([])
        setSelectedEmail('')
        setSelectedUserLocationRows([])
    }

    const handleReportStyleChange = (reportStyle: ReportStyle) => {
        if (selectedReportStyle.multiAsset !== reportStyle.multiAsset) {
            setSelectedAssets([])
        }

        setSelectedEmail('')
        setSelectedUserLocationRows([])
        setSelectedReportStyle(reportStyle);
    }

    async function createReport() {
        ReactGA.event("RT2-Reports-" + selectedReportStyle.name + "-" + selectedReportType + "-" + selectedFileFormat);

        let forSummaryReport: boolean = false;

        if (selectedReportStyle === VEHICLE_SUMMARY_REPORTS) {
            forSummaryReport = true;
        }

        let request: ReportRequest = {
            reportType: selectedReportType,
            from: selectedFromDate.utc(true).toDate(),
            to: selectedToDate.utc(true).toDate(),
            vehicleIds: selectedAssets,
            country: getCountry(languageCode),
            emails: [selectedEmail],
            reportFormat: selectedFileFormat,
            byWeek: selectedByWeek,
            byMonth: selectedByMonth,
            byVehicle: selectedByVehicle,
            byDriver: selectedByDriver,
            includeFullReport: selectedIncludeFullReport,
            includeSummaryTotals: selectedIncludeSummaryTotals,
            forSummaryReport: forSummaryReport,
            isSingleReport: selectedReportStyle === VEHICLE_GROUP_REPORTS,
            group: isEmailValid(selectedEmail),
            locationIds: selectedUserLocationRows
        };

        props.requestReport(request);
    }

    useEffect(() => {
        setSelectedReportType(selectedReportStyle.reportTypes[0]);
    }, [selectedReportStyle]);

    return <Paper elevation={3} style={{display: 'flex', flexDirection: 'column', height: '100%'}}>

        <DateRangeSelector
            selectedFromDate={selectedFromDate}
            setSelectedFromDate={setSelectedFromDate}
            selectedToDate={selectedToDate}
            setSelectedToDate={setSelectedToDate}
        />

        <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

        <ReportStyleSelector
            availableReportStyles={VEHICLE_REPORT_GROUPS}
            selectedReportStyle={selectedReportStyle}
            handleReportStyleChange={handleReportStyleChange}
        />

        <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

        <ReportTypeSelector
            reportTypes={selectedReportStyle.reportTypes}
            selectedReportType={selectedReportType}
            setSelectedReportType={setSelectedReportType}
        />

        <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

        {((selectedReportStyle === VEHICLE_SUMMARY_REPORTS) ||
            (selectedReportStyle === VEHICLE_GROUP_REPORTS) ||
            (selectedReportStyle === VEHICLE_GROUP_FINANCIAL_REPORTS && selectedReportType !== ReportType.TIMESHEET_REPORT_FINANCE)) && (
            <>
                {/* E-mail */}
                <ReportEmailSelector
                    email={selectedEmail}
                    setEmail={setSelectedEmail}
                    required={true}
                />

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>
            </>
        )}

        {selectedReportStyle === VEHICLE_SUMMARY_REPORTS && (
            <>
                {/* Summarise By Selection */}
                <SummariseBySelector
                    selectedByWeek={selectedByWeek}
                    setSelectedByWeek={setSelectedByWeek}
                    selectedByMonth={selectedByMonth}
                    setSelectedByMonth={setSelectedByMonth}
                    selectedByVehicle={selectedByVehicle}
                    setSelectedByVehicle={setSelectedByVehicle}
                    selectedByDriver={selectedByDriver}
                    setSelectedByDriver={setSelectedByDriver}
                />

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                {/* Include Selection */}
                <SummariseIncludeSelector
                    selectedIncludeFullReport={selectedIncludeFullReport}
                    setSelectedIncludeFullReport={setSelectedIncludeFullReport}
                    selectedIncludeSummaryTotals={selectedIncludeSummaryTotals}
                    setSelectedIncludeSummaryTotals={setSelectedIncludeSummaryTotals}
                />

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>
            </>
        )}

        {(selectedReportStyle === VEHICLE_SINGLE_FINANCIAL_REPORTS && selectedReportType === ReportType.STOP_LOCATION_FINANCE_REPORT) && (
            <>
                {/* User Location */}
                <UserLocationTableSelector
                    singleReport={!selectedReportStyle.multiAsset}
                    selectedUserLocations={selectedUserLocationRows}
                    setSelectedUserLocations={setSelectedUserLocationRows}
                />
                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>
            </>
        )}

        {((selectedReportStyle === VEHICLE_GROUP_FINANCIAL_REPORTS) && selectedReportType === ReportType.TIMESHEET_REPORT_FINANCE) && (
            <>
                {/* User Location */}
                <UserLocationTableSelector
                    singleReport={!selectedReportStyle.multiAsset}
                    selectedUserLocations={selectedUserLocationRows}
                    setSelectedUserLocations={setSelectedUserLocationRows}
                />
                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>
            </>
        )}

        <FileFormatSelector
            selectedFileFormat={selectedFileFormat}
            setSelectedFileFormat={setSelectedFileFormat}
        />

        <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

        <AssetSelector
            singleReport={!selectedReportStyle.multiAsset}
            filterGenerator={false}
            selectedAssets={selectedAssets}
            setSelectedAssets={setSelectedAssets}
        />

        <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

        <ReportButtonBar
            handleClearSelection={handleClear}
            createDisabled={createDisabled()}
            handleCreateReport={createReport}
        />
    </Paper>
}
