import Paper from "@mui/material/Paper";
import LoadingSpinner from "../../../components/loadingspinner/LoadingSpinner";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {getTranslatedName, ReportType} from "../../../enum/ReportType";

interface EmailReadyContentProps {
    handleBackToReports: () => void;
    reportType: string;
    reportFormat: string;
    reportDateRange: string;
}

export interface EmailReadyPageProps extends Partial<EmailReadyContentProps> {
    isGenerating: boolean;
    handleBackToReports: () => void;
    reportType?: ReportType;
    reportFormat?: string;
    reportDateRange?: string;
}

export function EmailReadyPage(props: EmailReadyPageProps) {
    const { t } = useTranslation();
    return (
        <Paper elevation={3} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            {props.isGenerating ?
                <LoadingContent /> :
                <EmailReadyContent
                    handleBackToReports={props.handleBackToReports}
                    reportType={getTranslatedName(props.reportType, t)}
                    reportFormat={props.reportFormat!}
                    reportDateRange={props.reportDateRange!}
                />}
        </Paper>
    );
}

function LoadingContent() {
    return <div style={{ width: '100%', height: '100%' }}>
        <div style={{ textAlign: 'center', top: '50%', left: '50%', position: 'absolute' }}>
            <LoadingSpinner />
        </div>
    </div>
}

function EmailReadyContent(props: EmailReadyContentProps) {
    const { t } = useTranslation();
    return <div style={{ width: '100%', height: '100%', padding: '10px', paddingTop: '0px', display: 'flex', flexDirection: 'column' }}>
        <Typography variant="smallHeader" style={{ paddingBottom: '0px' }}>
            {t('report_ready.title')}
        </Typography>
        <Typography variant="subtitle" style={{ paddingTop: '0px' }}>
            {t('report_ready.email_processed_description')}
        </Typography>

        <Box sx={{
            backgroundColor: "tertiary.main",
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            padding: '10px',
            marginTop: '10px',
            marginBottom: '10px',
            borderRadius: '5px',
        }
        }>
            <div>
                <Typography variant="subtitle" style={{ paddingTop: '0px' }}>
                    {t('report_ready.headers.report_name')}
                </Typography>
                <Typography variant="body" style={{ paddingTop: '0px' }}>
                    {props.reportType}
                </Typography>
            </div>
            <div>
                <Typography variant="subtitle" style={{ paddingTop: '0px' }}>
                    {t('report_ready.headers.date_range')}
                </Typography>
                <Typography variant="body" style={{ paddingTop: '0px' }}>
                    {props.reportDateRange}
                </Typography>
            </div>
            <div>
                <Typography variant="subtitle" style={{ paddingTop: '0px' }}>
                    {t('report_ready.headers.file_format')}
                </Typography>
                <Typography variant="body" style={{ paddingTop: '0px' }}>
                    {props.reportFormat}
                </Typography>
            </div>

        </Box>
        <Box
            bottom="0px"
            sx={{
                padding: '10px',
                borderTop: '1px solid #808080',
                display: 'flex', flexDirection: "row", justifyContent: "space-between"
            }}>
            <Button
                variant="contained"
                size='small'
                color="secondary"
                onClick={() => {
                    props.handleBackToReports()
                }}>
                {t('button.back')}
            </Button>
        </Box>


    </div>
}
