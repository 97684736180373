import { Geofence, GeofenceCategory } from './dto/Geofence';
import { Feature } from 'geojson';

export function mapToGeofenceArray(json: any) {
    return json.map((geofence: any) => {
        return mapToGeofence(geofence)
    })
}

export function mapToGeofence(geofence: any) {
    return new Geofence({
        id: geofence.id,
        name: geofence.name,
        category: geofence.category as GeofenceCategory,
        feature: geofence.feature as Feature,
        colour: geofence.colour,
        deletedAt : geofence.deletedAt,
    })

}
