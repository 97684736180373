

export default function LoadingSpinner() {

    return (<img
        src="/images/load/globe.gif"
        alt="Loading..."
        style={{
            display: "block",
            margin: "0 auto",
        }} />)
}