import {AccountContact} from "../hooks/account/Account";
export function filterContactsBySearch(contacts: Array<AccountContact>, searchInput: String | undefined) {

    if (searchInput) {
        let lowerCaseFilter = searchInput.toLowerCase();
        contacts = contacts.filter(contact => {
            if (contact) {
                // Check for recipient properties and filter accordingly
                if (contact.email && contact.email.toLowerCase().includes(lowerCaseFilter)) {
                    return true;
                }
            }
            return false; // Return false for recipients that don't match the filter
        });
    }

    return contacts;
}
