import {Box, Divider, Grid, Typography, useTheme} from '@mui/material';
import React from 'react';
import {useTranslation} from "react-i18next";
import {Asset} from "../../../hooks/assets/dto/Asset";

interface AssetOverviewWidgetProps {
    assets: Asset[];
}

const AssetOverviewWidget: React.FC<AssetOverviewWidgetProps> = (props: AssetOverviewWidgetProps) => {
    const theme = useTheme();

    const { t } = useTranslation();

    const filterAssetsByStatus = (assets: Array<Asset>, status: string) => {
        return assets.filter(asset => asset.status === status);
    }

    const vehiclesWithoutDriver = (assets: Array<Asset>) => {
        return assets.filter(asset => asset.assignee?.name === 'Unknown Assignee');
    }

    const assetsInGeofence = (assets: Array<Asset>) => {
        return assets.filter(asset => asset.latestEvent && asset.latestEvent.location.geofences.length > 0);
    }

    const assetsSpeeding = (assets: Array<Asset>) => {
        return assets
            .filter(asset => asset.latestEvent !== undefined)
            .filter(asset =>
            asset.latestEvent!.speed?.units &&
            asset.latestEvent!.speedLimit?.units &&
            asset.latestEvent!.speed.value > asset.latestEvent!.speedLimit.value
        );
    }

    return (
        <Box
            id="asset-overview-panel"
            display="flex"
            flexDirection="column"
            alignItems="Left"
            sx={{
                background: theme.palette.contentPage.backgroundPanel,
                height: '100%',
                padding: '30px',
                paddingTop: '10px',
                paddingBottom: '10px',
                borderRadius: '5px',
                boxShadow: 3,
            }}>
            <Typography variant="header">
                {t('asset_overview.title')}
            </Typography>
            <Grid container padding="10px" spacing={2.5}>
                <Grid item xs={12} md={4} lg={4}>
                    <Typography variant="body1" align="center" style={{ fontWeight: 'bold' }}>
                        {filterAssetsByStatus(props.assets, "MOVING").length}
                    </Typography>
                    <Typography variant="body1" align="center">
                        {t('asset_overview.assets_moving')}
                    </Typography>

                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Typography variant="body1" align="center" style={{ fontWeight: 'bold' }}>
                        {filterAssetsByStatus(props.assets, "STATIONARY").length}
                    </Typography>
                    <Typography variant="body1" align="center">
                        {t('asset_overview.assets_stationary')}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Typography variant="body1" align="center" style={{ fontWeight: 'bold' }}>
                        {filterAssetsByStatus(props.assets, "STOPPED").length}
                    </Typography>
                    <Typography variant="body1" align="center">
                        {t('asset_overview.assets_stopped')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Divider/>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                    <Typography variant="body1" align="center" style={{ fontWeight: 'bold' }}>
                        {vehiclesWithoutDriver(props.assets).length}
                    </Typography>
                    <Typography variant="body1" align="center">
                        {t('asset_overview.assets_without_driver')}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Typography variant="body1" align="center" style={{ fontWeight: 'bold' }}>
                        {assetsInGeofence(props.assets).length}
                    </Typography>
                    <Typography variant="body1" align="center">
                        {t('asset_overview.assets_in_geofence')}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Typography variant="body1" align="center" style={{ fontWeight: 'bold' }}>
                        {assetsSpeeding(props.assets).length}
                    </Typography>
                    <Typography variant="body1" align="center">
                        {t('asset_overview.assets_speeding')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Divider/>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <Typography variant="body">
                        {t('asset_overview.more_info')} <a href="reports">{t('asset_overview.report_link')}</a>
                    </Typography>
                </Grid>

            </Grid>
        </Box>
    );
};
export default AssetOverviewWidget;
