import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

function TableButton(props: Readonly<{ color: "primary" | "secondary", label: string, action: () => void }>) {
    return <Button
        variant="contained"
        size='small'
        style={{ margin: '2px' }}
        onClick={props.action}
        color={props.color}>
        {props.label}
    </Button>
}

export function RowViewButton(props: Readonly<{ action: () => void }>) {
    const { t } = useTranslation();
    return <TableButton color="primary" label={t('button.view') as string} action={props.action} />
}

export function RowEditButton(props: Readonly<{ action: () => void }>) {
    const { t } = useTranslation();
    return <TableButton color="primary" label={t('button.edit') as string} action={props.action} />
}

export function RowDeleteButton(props: Readonly<{ action: () => void }>) {
    const { t } = useTranslation();
    return <TableButton color="secondary" label={t('button.delete') as string} action={props.action} />
}

export function CreateButton(props: Readonly<{ action: () => void }>) {
    const { t } = useTranslation();
    return <TableButton color="primary" label={t('button.create') as string} action={props.action} />
}