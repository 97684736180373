import {useTranslation} from "react-i18next";
import {LiveShareLinksPeriod} from "../../../hooks/liveshare/dto/LiveShareLink";
import SelectStringTable from "../../../components/table/shared/SelectStringTable";

interface LiveshareDayTableProps {
    linkPeriod: LiveShareLinksPeriod;
    setLinkPeriod: (selectedLinkPeriod: LiveShareLinksPeriod) => void;
}

export default function LiveshareDayTable(props: LiveshareDayTableProps) {
    const {t} = useTranslation();

    const handleRowSelect = (linkPeriod: LiveShareLinksPeriod) => {
        props.setLinkPeriod(linkPeriod);
    };

    const liveshareLinkPeriods = [LiveShareLinksPeriod.ALL, LiveShareLinksPeriod.WEEKDAYS, LiveShareLinksPeriod.WEEKENDS,
        LiveShareLinksPeriod.MONDAY, LiveShareLinksPeriod.TUESDAY, LiveShareLinksPeriod.WEDNESDAY, LiveShareLinksPeriod.THURSDAY,
        LiveShareLinksPeriod.FRIDAY, LiveShareLinksPeriod.SATURDAY, LiveShareLinksPeriod.SUNDAY]

    const getLinkPeriodDayText = (liveShareLinksPeriod: LiveShareLinksPeriod) => {
        switch (liveShareLinksPeriod) {
            case LiveShareLinksPeriod.ALL:
                return t("days.all");
            case LiveShareLinksPeriod.WEEKDAYS:
                return t("days.weekdays");
            case LiveShareLinksPeriod.WEEKENDS:
                return t("days.weekends");
            case LiveShareLinksPeriod.MONDAY:
                return t("days.monday");
            case LiveShareLinksPeriod.TUESDAY:
                return t("days.tuesday");
            case LiveShareLinksPeriod.WEDNESDAY:
                return t("days.wednesday");
            case LiveShareLinksPeriod.THURSDAY:
                return t("days.thursday");
            case LiveShareLinksPeriod.FRIDAY:
                return t("days.friday");
            case LiveShareLinksPeriod.SATURDAY:
                return t("days.saturday");
            case LiveShareLinksPeriod.SUNDAY:
                return t("days.sunday");
        }
    }

    return (
        <SelectStringTable
            loading={false}
            header={t("event.day")}
            values={liveshareLinkPeriods.map(period => getLinkPeriodDayText(period))}
            selectedRows={[getLinkPeriodDayText(props.linkPeriod)]}
            setSelectedRows={(selectedRows: string[]) => handleRowSelect(liveshareLinkPeriods.find(period => getLinkPeriodDayText(period) === selectedRows[0])!)}
        />
    );
}
