import {Asset, AssetType} from "../hooks/assets/dto/Asset";

export function buildAssetStatusMessage(asset: Asset, t: (key: string) => string): string {
    switch (asset.status) {
        case 'STOPPED':
            return t('asset_status.stopped');
        case 'MOVING':
            if (asset.latestEvent) {
                return t('asset_status.moving_towards') + " " + t(`asset_directions.${asset.latestEvent.headingIndicator.toLowerCase()}`) + " " + t('asset_messages.at') + " " + asset.latestEvent.speed.value + " " + asset.latestEvent.speed.units;
            }
            return t('asset_status.moving');
        case 'STATIONARY':
            return t('asset_status.stationary');
        default:
            return t('asset_status.unknown_status');
    }
}

export function buildAssigneeName(asset: Asset, t: (key: string) => string): string {
    return asset.assignee.name.toLowerCase() === "unknown assignee" ? t('asset_messages.default_driver_name') : asset.assignee.name;
}

export function doesAssetHaveProblem(asset: Asset): boolean {
    return asset.latestEvent?.location.goodGpsFix === false;
}

export function buildLastEventMessage(date: Date, languageCode: string, t: (key: string) => string): string {
    const difference = Date.now() - date.getTime(); // difference in milliseconds
    const resultInMinutes = Math.round(difference / 60000);
    switch (languageCode) {
        case 'fr':
            return t('asset_messages.last_updated') + ' ' + t('asset_messages.ago') + ' ' + formatLastUpdateTime(resultInMinutes, t);
        default:
            return t('asset_messages.last_updated') + ' ' + formatLastUpdateTime(resultInMinutes, t) + ' ' + t('asset_messages.ago');
    }
}

function formatLastUpdateTime(minutes: number, t: (key: string) => string): string {
    if (minutes <= 60) {
        return `${minutes} ${t('asset_messages.mins')}`;
    } else if (minutes <= 1440) {
        const hours = Math.floor(minutes / 60);
        const hourMessage = hours > 1 ? t('asset_messages.hours') : t('asset_messages.hour')
        return `${hours} ${hourMessage}`;
    } else {
        const days = Math.floor(minutes / 1440);
        const dayMessage = days > 1 ? t('asset_messages.days') : t('asset_messages.day')
        return `${days} ${dayMessage}`;
    }
}

export function isVehicle(assetType: AssetType) {
    const vehicleAsset = new Set([
        AssetType.MOTORCYCLE,
        AssetType.CAR,
        AssetType.VAN,
        AssetType.TRUCK
    ]);

    return vehicleAsset.has(assetType);
}

export function findAsset(assets: Asset[] | null, id: number) {
    return assets?.find(asset => asset.id === id);
}

export function filterGenerator(assets: Asset[]) {
    return assets.filter(asset => asset.type === AssetType.GENERATOR)
}

export function hasGeneratorAsset(assets: Asset[]): boolean {
    return assets.some(asset => asset.type === AssetType.GENERATOR);
}

export function getAssetName(asset: Asset | undefined) {
    return asset?.alias != null && asset?.alias !== "" ? asset?.alias : asset?.identificationMark ?? 'Unknown';
}

