import { Asset } from "../hooks/assets/dto/Asset";

export enum AssetSortOptions {
    DEFAULT = "DEFAULT",
    DRIVER_NAME = "DRIVER_NAME",
    LAST_REPORTED = "LAST_REPORTED"
}

export function sortByAliasOrReg(a: Asset, b: Asset) {
    const astr = a.alias || a.identificationMark;
    const bstr = b.alias || b.identificationMark;
    if (astr < bstr) {
        return -1;
    }
    if (astr > bstr) {
        return 1;
    }
    return 0;
}

export function sortByDriverName(a: Asset, b: Asset) {
    if (a.assignee.name < b.assignee.name) {
        return -1;
    }
    if (a.assignee.name > b.assignee.name) {
        return 1;
    }
    return 0;
}

export function sortByLastEventTime(a: Asset, b: Asset) {
    if (!a.latestEvent && !b.latestEvent) {
        return 0;
    }
    if (!a.latestEvent) {
        return -1;
    }
    if (!b.latestEvent) {
        return 1;
    }
    if (a.latestEvent.time < b.latestEvent.time) {
        return 1;
    }
    if (a.latestEvent.time > b.latestEvent.time) {
        return -1;
    }
    return 0;
}