

//MUI Component imports
import { styled } from "@mui/material/styles";

import NavigationList from "./NavigationList";
import Drawer from "@mui/material/Drawer";

interface MobileAppDrawerProps {
    isMobileDrawerOpen: boolean;
    setIsMobileDrawerOpen: (open: boolean) => void;
}

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    height: "50px",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


function MobileAppDrawer(props: MobileAppDrawerProps) {
    return (
        <Drawer open={props.isMobileDrawerOpen} onClose={() => props.setIsMobileDrawerOpen(false)}>
            <DrawerHeader />
            <NavigationList openDrawer={true} />
        </Drawer>
    );
}

export default MobileAppDrawer;