import {Button, useTheme} from '@mui/material';
import {useIntercom} from 'react-use-intercom';
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import {UserPreferencesContext} from "../../providers/UserPreferencesProvider";

interface FeedbackButtonProps {
}

function FeedbackButton(props: FeedbackButtonProps) {
    const {languageCode} = useContext(UserPreferencesContext)
    const {t} = useTranslation();
    const theme = useTheme()
    const {startSurvey, boot} = useIntercom();

    const handleSurveyClick = () => {
        boot({
            languageOverride: languageCode,
        });

        startSurvey(34076676);
    };


    return (
        <Button
            size='small'
            disableRipple
            onClick={handleSurveyClick}
            sx={{
                backgroundColor: theme.palette.appbar.buttonBackground,
                color: theme.palette.appbar.buttonForeground,
                borderRadius: '5px',
                margin: '5px',
                height: '34px',
                '&:hover': {
                    backgroundColor: theme.palette.appbar.buttonBackgroundHover,
                },
            }}
        >{t('feedback.button')}</Button>
    );
}

export default FeedbackButton;
