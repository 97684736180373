import {Box, Tooltip} from '@mui/material';
import React, {useContext} from 'react';
import {GeofenceAlert, useGeofenceAlertsApi} from '../../../hooks/alerts/GeofenceAlerts';
import {Table, TableCell, TableRow} from '../../../components/table/core/Table';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {UserPreferencesContext} from '../../../providers/UserPreferencesProvider';
import ConfirmationDialog from '../../../components/dialog/ConfirmationDialog';
import {formatTime} from '../../../utils/TimeUtils';
import {RowDeleteButton, RowEditButton} from '../../../components/table/core/TableButtons';
import FilterBar from "../../../components/filterbar/FilterBar";

interface GeofenceAlertsPageProps {
}

export default function GeofenceAlertsPage(props: GeofenceAlertsPageProps) {

    const {t} = useTranslation();
    const {geofenceAlerts, getAllGeofenceAlerts, loading} = useGeofenceAlertsApi({shouldLoadAll: true})
    const [searchInput, setSearchInput] = React.useState<string>("");
    const navigate = useNavigate()

    function getFilteredAlerts() {
        if (geofenceAlerts === null) {
            return [];
        } else if (searchInput === undefined || searchInput === "") {
            return geofenceAlerts || [];
        } else {
            const lowerCaseFilter = searchInput.toLowerCase();
            return geofenceAlerts.filter(alert => alert.vehicles.some(vehicle => vehicle.toLowerCase().includes(lowerCaseFilter)) ||
                alert.recipients.some(recipient => recipient.toLowerCase().includes(lowerCaseFilter)) ||
                alert.geofenceDto.name.toLowerCase().includes(lowerCaseFilter)
            );
        }
    }

    const columns = [
        { id:'asset', content: t('alert_table.asset')},
        { id:'geofence', content: t('alert_table.geofence')},
        { id:'recipients', content: t('alert_table.recipients')},
        { id:'trigger', content: t('alert_table.trigger')},
        { id:'days', content: t('alert_table.days')},
        { id:'time-range', content: t('alert_table.time_range')},
        { id:'action', content: t('alert_table.action')}
    ];

    const rows = getFilteredAlerts().map((alert) => {
        return <GeofenceAlertRow key={alert.id} alert={alert} updateTrigger={getAllGeofenceAlerts}/>
    })

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "space-between",
            margin: '30px',
            marginTop: '10px',
            height: '100%',
        }}>
            <FilterBar onSearchChange={setSearchInput} filter={searchInput} label={t('search_bar.search_for')}/>

            <Table
                id="geofence-alerts"
                label="Geofence Alerts"
                columns={columns}
                loading={loading}
                rows={rows}
                createAction={() => { navigate('/alerts/create-geofence-alerts') }}
                emptyLabel={t('alert_table.no_alerts') as string}
            />
        </Box>
    );
};

function GeofenceAlertRow(props: { alert: GeofenceAlert, updateTrigger: () => void }) {
    const {t} = useTranslation();
    const {twentyFourHourEnabled} = useContext(UserPreferencesContext)
    const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false)
    const [selectedAlertId, setSelectedAlertId] = React.useState<number | null>(null);
    const navigate = useNavigate()
    const {deleteGeofenceAlert} = useGeofenceAlertsApi()

    const getTriggerText = (alert: GeofenceAlert) => {
        if (alert.alertOnEntry && alert.alertOnExit) {
            return t('alert_table.entry') + ' & ' + t('alert_table.exit');
        } else if (alert.alertOnEntry) {
            return t('alert_table.entry');
        } else if (alert.alertOnExit) {
            return t('alert_table.exit');
        } else {
            return null;
        }
    }

    async function handleDelete() {
        await deleteGeofenceAlert(selectedAlertId?.toString() as string)
            .catch((error) => {
                alert(t('alert_delete.failed'))
            })

        setShowDeleteDialog(false)
        props.updateTrigger()
    }

    return <TableRow id={props.alert.id.toString()}>
        <TableCell>
                {props.alert.alertForAllVehicles ? t('alert_table.all') : props.alert.vehicles.length > 3 ?
                    props.alert.vehicles.length + " " + t('alert_table.vehicles') :
                    props.alert.vehicles.map(vehicle => (
                        <div
                            key={props.alert.id.toString() + "_vehicles_" + vehicle}
                            id={props.alert.id.toString() + "_vehicles_" + vehicle}>
                            {vehicle} <br/>
                        </div>
                    ))
                }
        </TableCell>
        <TableCell>{props.alert.geofenceDto.name}</TableCell>
        <TableCell>
                {props.alert.recipients.length > 3 ? (
                        <Tooltip title={props.alert.recipients.join(', ')} arrow>
                            <div>
                                {props.alert.recipients.length + ' ' + t('alert_table.recipients')}
                            </div>
                        </Tooltip>
                    ) :
                    props.alert.recipients.map(recipient => (
                        <div
                            key={props.alert.id.toString() + "_recipients_" + recipient}
                            id={props.alert.id.toString() + "_recipients_" + recipient}>
                            {recipient} <br/>
                        </div>
                    ))
                }
        </TableCell>
        <TableCell>
            <div className="bg-[#b5e3b5] rounded-lg p-1 text-center text-sm whitespace-nowrap">
                {getTriggerText(props.alert)}
            </div>
        </TableCell>
        <TableCell>
            {props.alert.alertPeriod}
        </TableCell>
        <TableCell>
            {`${formatTime(props.alert.startTime, twentyFourHourEnabled)} - ${formatTime(props.alert.endTime, twentyFourHourEnabled)}`}
        </TableCell>

        <TableCell>
            <RowEditButton action={() => {
                const selectedAlert = props.alert;
                navigate('/alerts/update-geofence-alerts', {state: {selectedAlert}});
            }}/>
            <RowDeleteButton action={() => {
                setSelectedAlertId(props.alert.id);
                setShowDeleteDialog(true);
            }}/>

            <ConfirmationDialog open={showDeleteDialog}
                                title={t('alerts_page.geofence_alerts_page.delete_dialog.title')}
                                description={t('alerts_page.geofence_alerts_page.delete_dialog.description')}
                                confirmText={t('button.delete')} cancelText={t('button.cancel')}
                                onConfirm={handleDelete}
                                onCancel={() => setShowDeleteDialog(false)}/>
        </TableCell>
    </TableRow>
}

