export enum LabelLayerType {
    REGISTRATION = "REGISTRATION",
    ALIAS = "ALIAS",
    DRIVER_NAME = "DRIVER_NAME",
}

export const DEFAULT_LABEL_LAYER = LabelLayerType.ALIAS;

interface LabelLayerProps {
    labelLayer: LabelLayerType
}

function LabelLayer(props: LabelLayerProps) {
    return (<>
        
    </>)
    
}

export default LabelLayer;