import { useEffect, useState } from "react";
import usePlatformFetch from "../shared/PlatformFetch";
import PropertiesLoader from "../../properties/PropertiesLoader";
import { Polygon } from 'geojson';
const properties = PropertiesLoader();

export class Zone {
    id: string;
    name: string;
    polygon: Polygon;

    constructor(props: {
        id: string;
        name: string;
        polygon: Polygon;
    }) {
        this.id = props.id;
        this.name = props.name;
        this.polygon = props.polygon;
    }
}

function mapToZoneArray(json: any) {
    return json.map((zone: any) => {
        return new Zone({
            id: zone.id,
            name: zone.name,
            polygon: zone.polygon as Polygon
        })
    })
}

export function useCongestionZonesApi(shouldPoll?: boolean, pollFrequency?: number) {
    const { platformFetch, checkOk } = usePlatformFetch();

    const [zones, setZones] = useState<Zone[] | null>(null);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const getAllCongestionZones = async () => {
        return new Promise((resolve, reject) => {
            setLoading(true)
            platformFetch(
                properties.platformApiEndpoint + "tracking/v1/congestionzones/",
                'get')
                .then(checkOk)
                .then(response => response.json())
                .then(json => mapToZoneArray(json))
                .then(loadedZones => {
                    setZones(loadedZones)
                    setLoading(false)
                    resolve(loadedZones)
                })
                .catch((error) => {
                    console.error("Error getting congestion zones: " + error)
                    setError(error)
                    setLoading(false)
                    reject(error)
                });
        });
    }


    useEffect(() => {
        getAllCongestionZones();
        if (shouldPoll) {
            const interval = setInterval(getAllCongestionZones, pollFrequency || 30000);
            return () => clearInterval(interval);
        }
    }, []);

    return { loading, error, zones, getAllCongestionZones }
}
