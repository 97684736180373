import { Box, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import isEmailValid from '../../utils/EmailValidator';

interface TextInputProps {
    label: string;
    required: boolean;
    isEmail?: boolean;
    isNumber?: boolean;
    allowNegative?: boolean;
    editMode: boolean;
    placeholder: string;
    value: string;
    onChange: (value: string) => void;
    maxLength?: number;
}

export default function TextInput(props: TextInputProps) {
    const { t } = useTranslation();
    const DEFAULT_MAX_LENGTH = 255;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const maxLen = props.maxLength ?? DEFAULT_MAX_LENGTH;

        if (value.length <= maxLen) {
            if (props.isNumber) {
                if (props.allowNegative) {
                    // Negative numbers and zero
                    if (/^-?\d+(\.\d+)?$/.test(value) || value === '-' || value === '') {
                        props.onChange(value);
                    }
                } else {
                    // Positive numbers and zero
                    if (/^\d+(\.\d+)?$/.test(value) || value === '') {
                        props.onChange(value);
                    }
                }
            } else {
                props.onChange(value);
            }
        }
    };

    const isNumber = (value: string): boolean => {
        if (value === null || value == undefined || value.trim() === '') return false;
        const num = Number(value);
        if (props.allowNegative) {
            return Number.isFinite(num);
        }
        return Number.isFinite(num) && num >= 0;
    };

    const isError = (): boolean => {
        return ((props.required && props.value === '')
            || (props.isEmail && !validEmail)
            || (props.isNumber && !validNumber)) ? true : false;
    };

    const getHelperText = (): string => {
        if (props.required && props.value === '') {
            return props.label + ' ' + t('form.required');
        }
        if (props.isEmail && props.value !== '' && !isEmailValid(props.value)) {
            return t('form.valid_email_required');
        }
        if (props.isNumber && props.value !== '' && !isNumber(props.value)) {
            return t('form.valid_number_required');
        }

        // Show remaining characters only for text inputs (not numbers or email)
        if (!props.isNumber && !props.isEmail && props.value?.length > 0) {
            const maxLen = props.maxLength ?? DEFAULT_MAX_LENGTH;
            const remaining = maxLen - props.value.length;
            return `${remaining} ${t('form.characters_remaining')}`;
        }

        return '';
    };

    let validEmail = isEmailValid(props.value);
    let validNumber = isNumber(props.value);
    const label = props.label && <Typography variant="smallTitle">{props.label} {props.required ? "*" : ""}</Typography>;

    return (
        <Box sx={{ paddingBottom: "20px" }}>
            {label}
            <TextField
                id={props.label + "-text-input"}
                size="small"
                placeholder={props.placeholder}
                variant="outlined"
                error={isError()}
                helperText={getHelperText()}
                color="info"
                disabled={!props.editMode}
                value={props.value}
                type="text"
                onChange={handleChange}
                inputProps={{
                    maxLength: props.maxLength ?? DEFAULT_MAX_LENGTH
                }}
                sx={{
                    width: "100%"
                }}
            />
        </Box>
    );
}
