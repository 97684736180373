import { Box, Typography } from "@mui/material";
import LoadingSpinner from "../../components/loadingspinner/LoadingSpinner";
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";
import { useContext } from "react";

export default function LoadingPage() {

    const auth: IAuthContext = useContext(AuthContext)

    return (
        <Box sx={{
            height: 'calc(100vh - 67px)',
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
        }}>
            <LoadingSpinner />

            <Typography
                sx={{ display: "block", width: "100%" }}
                component="div"
                variant="cardtitle"
                color="text.primary"
            >
                Taking too long?
            </Typography>
            <Typography
                sx={{ display: "block", width: "100%", color: "blue", cursor: "pointer" }}
                component="div"
                variant="cardtitle"
                color="text.primary"
                onClick={() => {
                    auth.logOut();
                }}
            >
                Click here to start again.
            </Typography>
        </Box >
    );
}

