import { FormControlLabel, Grid, Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SelectorLabel } from "./SelectorLabel";

interface SummariseIncludeProps {
    selectedIncludeFullReport: boolean;
    setSelectedIncludeFullReport: (selectedIncludeFullReport: boolean) => void;
    selectedIncludeSummaryTotals: boolean;
    setSelectedIncludeSummaryTotals: (selectedIncludeSummaryTotals: boolean) => void;
}

export function SummariseIncludeSelector(props: SummariseIncludeProps) {

    const { t } = useTranslation();

    const handleFullReportChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setSelectedIncludeFullReport(event.target.checked);
    };

    const handleSummaryTotalsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setSelectedIncludeSummaryTotals(event.target.checked);
    };

    return <Grid container>
    <SelectorLabel
        title={t("report.summarise_include")}
        subtitle={t("report.select_summarise_include")}
        />
                
        <Grid item xs={12} md={8} lg={10} padding="20px">
            <Grid container spacing={2}>
                <Grid item>
                    <FormControlLabel control={<Checkbox
                        checked={props.selectedIncludeFullReport}
                        onChange={handleFullReportChange}
                        color="primary"
                    />} label={t("report.full_report")}/>
                </Grid>
                <Grid item>
                    <FormControlLabel control={<Checkbox
                        checked={props.selectedIncludeSummaryTotals}
                        onChange={handleSummaryTotalsChange}
                        color="primary"
                    />} label={t("report.summary_totals")}/>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}