import {Box, Button, Divider, Grid, InputAdornment, Paper, TextField, Typography, useTheme} from "@mui/material";

import {useTranslation} from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";

import React, {useContext, useState} from "react";
import {FilterSortContext} from "../../../providers/FilterSortProvider";
import {Asset, AssetType} from "../../../hooks/assets/dto/Asset";
import {AssetSortOptions, sortByDriverName, sortByLastEventTime} from "../../../utils/AssetArraySorting";
import {useAssetApi} from "../../../hooks/assets/Assets";
import {useLocation, useNavigate} from "react-router-dom";
import useAccountApi, {AccountContact} from "../../../hooks/account/Account";
import {filterAssetsBySearch} from "../../../utils/AssetArrayFiltering";
import TextInput from "../../../components/form/TextInput";
import {WasteAlertRequest, useWasteAlertsApi} from "../../../hooks/alerts/WasteAlerts";
import SelectAssetTable from "../../../components/table/shared/SelectAssetTable";
import SelectRecipientTable from "../../../components/table/shared/SelectRecipientTable";

export default function UpdateWasteAlertsPage() {
    const location = useLocation();
    const selectedAlert = location.state.selectedAlert;

    const theme = useTheme();
    const navigate = useNavigate()
    const {t} = useTranslation();

    const [threshold, setThreshold] = useState<number>(selectedAlert.threshold)

    const [assetFilter, setAssetFilter] = React.useState<string | undefined>(undefined);
    const [recipientFilter, setRecipientFilter] = React.useState<string | undefined>(undefined);
    const {sort} = useContext(FilterSortContext);

    const {assets, getAllAssets, loading: loadingAssets, hasGenerator} = useAssetApi({shouldLoadAll: true});
    const {getCurrentAccountContacts} = useAccountApi();
    const {updateWasteAlert} = useWasteAlertsApi()

    const [selectedRows, setSelectedRows] = useState<number[]>(selectedAlert.vehicleIds || []);
    const [recipients, setRecipients] = useState<AccountContact[]>([]);
    const [selectedRecipientsRows, setSelectedRecipientsRows] = useState<string[]>(selectedAlert.recipientIds || []);


    const handleThresholdChange = (value: string) => {
        const numberValue = parseFloat(value);
        setThreshold(numberValue);
    };

    const onSearchChange = (search: string) => {
        if (search === undefined || search === "") {
            setAssetFilter(undefined);
        } else {
            setAssetFilter(search);
        }
    }
    const onRecipientSearchChange = (search: string) => {
        if (search === undefined || search === "") {
            setRecipientFilter(undefined);
        } else {
            setRecipientFilter(search);
        }
    }

    const filterAssets = (assets: Array<Asset>) => {
        let filteredAssets = filterAssetsBySearch(assets, assetFilter);

        filteredAssets = filterGenerator(filteredAssets);

        switch (sort) {
            case AssetSortOptions.DRIVER_NAME:
                filteredAssets.sort(sortByDriverName);
                break;
            case AssetSortOptions.LAST_REPORTED:
                filteredAssets.sort(sortByLastEventTime);
                break;
            default:
                break;
        }

        return filteredAssets;
    }

    function filterGenerator(assets: Asset[]) {
        return assets.filter(asset => asset.type === AssetType.GENERATOR)
    }

    const filterRecipients = (recipients: Array<AccountContact>) => {
        let filteredRecipients = recipients

        if (recipientFilter) {
            let lowerCaseFilter = recipientFilter.toLowerCase();
            filteredRecipients = recipients.filter(recipient => {
                if (recipient) {
                    if (recipient.email.toLowerCase().includes(lowerCaseFilter)) {
                        return true;
                    }
                }
                return false;
            });
        }

        return filteredRecipients;
    }

    const btnUpdateWasteAlert = async () => {
        if (100 <= threshold || threshold <= 0) {
            alert("Threshold value must be greater then 0 and less then 100");
            return;
        }

        await updateWasteAlert(new WasteAlertRequest({
            threshold: threshold,
            recipientIds: recipients.filter(recipient => selectedRecipientsRows.includes(recipient.email)).map(recipient => recipient.id),
            vehicleIds: selectedRows,
        }), selectedAlert.id)
            .then(() => {
                navigate('/alerts/waste-alerts')
            })
            .catch(() => {
                alert(t('alert_update.failed'))
            })
    }

    const handleCancel = () => {
        navigate('/alerts/waste-alerts')
    }

    const [loadingRecipients, setLoadingRecipients] = useState<boolean>(false);

    React.useEffect(() => {
        async function getAccountRecipients() {
            setLoadingRecipients(true)
            let contacts = await getCurrentAccountContacts() as AccountContact[];
            if (!contacts) {
                setRecipients([])
            } else {
                let selectedRecipients = contacts
                    .filter(value => {
                        return selectedAlert.recipients.includes(value.email);
                    })
                    .map(value => value.email);

                setSelectedRecipientsRows(selectedRecipients)
                setRecipients(contacts)
            }
            setLoadingRecipients(false)
        }

        getAccountRecipients()

        async function getAssets() {

            let assetList = await getAllAssets() as Asset[];

            if (assetList) {
                let selectedRowsList = assetList.filter(asset => {
                    return selectedAlert.vehicles.includes(asset.identificationMark);
                }).map(asset => asset.id);

                setSelectedRows(selectedRowsList ? selectedRowsList : [])
            }
        }

        getAssets()
    }, []);

    return (
        !hasGenerator ? <></> :
            <Box
                component="main"
                sx={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: theme.palette.contentPage.background
                }}
            >
                <Grid container component={Paper} sx={{padding: '10px', height: '100%', width: '100%'}} elevation={3}>

                    {/* Threshold */}

                    <Grid container>
                        <Grid item xs={12} md={4} lg={2}>
                            <Typography sx={{marginLeft: "20px", marginTop: "20px"}}
                                        variant="title">{t("waste_alert.admin.threshold")}</Typography>
                            <Typography sx={{marginLeft: "20px"}} variant="body">{t("waste_alert.admin.enter_threshold")}</Typography>

                        </Grid>
                        <Grid item xs={12} md={8} lg={10} padding="20px">
                            <TextInput editMode={true} isNumber={true}
                                       label={''} required={true} placeholder={''}
                                       value={threshold.toString()} onChange={handleThresholdChange}/>
                        </Grid>
                    </Grid>

                    <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                    {/* Asset Selection */}

                    <Grid container>
                        <Grid item xs={12} md={4} lg={2}>
                            <Typography sx={{marginLeft: "20px", marginTop: "10px"}}
                                        variant="title">{t("event.asset_selection")}</Typography>
                            <Typography sx={{marginLeft: "20px"}} variant="body">{t("event.asset_selection_message")}</Typography>
                        </Grid>
                        <Grid item xs={12} md={8} lg={10} padding="20px">
                            <Box sx={{
                                paddingBottom: '10px'
                            }}>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    size="small"
                                    label={t("event.asset_search_box_label")}
                                    variant="outlined"
                                    color="info"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={assetFilter}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        onSearchChange(event.target.value);
                                    }}
                                />
                            </Box>
                            <Grid>
                                <SelectAssetTable
                                    multiselect={true}
                                    loading={loadingAssets}
                                    assets={filterAssets(assets || [])}
                                    selectedRows={selectedRows}
                                    setSelectedRows={setSelectedRows}
                                />
                            </Grid>

                        </Grid>
                    </Grid>

                    <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                    {/* Recipients Selection */}

                    <Grid container>
                        <Grid item xs={12} md={4} lg={2}>
                            <Typography sx={{marginLeft: "20px", marginTop: "10px"}} variant="title">{t("event.recipients")}</Typography>
                            <Typography sx={{marginLeft: "20px"}} variant="body">{t("event.select_recipients")}</Typography>

                        </Grid>
                        <Grid item xs={12} md={8} lg={10} padding="20px">
                            <Box sx={{
                                paddingBottom: '10px'
                            }}>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    size="small"
                                    label={t("event.recipient_search_box_label")}
                                    variant="outlined"
                                    color="info"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={recipientFilter}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        onRecipientSearchChange(event.target.value);
                                    }}
                                />
                            </Box>
                            <Grid>
                                <SelectRecipientTable
                                    multiselect={true}
                                    loading={loadingRecipients}
                                    recipients={filterRecipients(recipients).map(recipient => recipient.email)}
                                    selectedRows={selectedRecipientsRows}
                                    setSelectedRows={setSelectedRecipientsRows}
                                />
                            </Grid>

                        </Grid>
                    </Grid>

                    <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                    <Grid container>
                        <Grid item xs={12} md={12} lg={12}>
                            <Box
                                bottom="0px"
                                sx={{
                                    padding: '10px',
                                    display: 'flex', flexDirection: "row", justifyContent: "space-between"
                                }}>
                                <Button
                                    variant="contained"
                                    size='small'
                                    color="secondary"
                                    onClick={() => {
                                        handleCancel()
                                    }}>
                                    {t('button.cancel')}
                                </Button>
                                <Button
                                    disabled={selectedRows.length === 0 || selectedRecipientsRows.length === 0 || threshold >= 100 || threshold <= 0}
                                    variant="contained"
                                    size='small'
                                    color="primary"
                                    onClick={btnUpdateWasteAlert}
                                >
                                    {t("button.edit")}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
    );
};
