import {useEffect, useState} from "react";
import usePlatformFetch from "../shared/PlatformFetch";
import PropertiesLoader from "../../properties/PropertiesLoader";

const properties = PropertiesLoader();

export class FuelAlert {
    id: number;
    accountId: number;
    threshold: number;
    recipients: string[];
    vehicles: string[];

    constructor(props: {
        id: number;
        accountId: number;
        threshold: number;
        recipients: string[];
        vehicles: string[];
    }) {
        this.id = props.id;
        this.accountId = props.accountId;
        this.threshold = props.threshold;
        this.recipients = props.recipients;
        this.vehicles = props.vehicles;
    }
}

export class FuelAlertRequest {
    threshold: number;
    recipientIds: number[];
    vehicleIds: number[];

    constructor(props: {
        threshold: number;
        recipientIds: number[];
        vehicleIds: number[];
    }) {
        this.threshold = props.threshold;
        this.recipientIds = props.recipientIds;
        this.vehicleIds = props.vehicleIds;
    }
}

interface FuelAlertsApiOptions {
    shouldLoadAll?: boolean;
    shouldPoll?: boolean;
    pollFrequency?: number;
}

export function useFuelAlertsApi(props: FuelAlertsApiOptions = {}) {
    const {platformFetch, checkOk, checkCreated} = usePlatformFetch();

    const [fuelAlerts, setFuelAlerts] = useState<FuelAlert[] | null>(null);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const getAllFuelAlerts = async () => {
        return new Promise((resolve, reject) => {
            setLoading(true)
            platformFetch(
                properties.platformApiEndpoint + "tracking/v1/alerts/fuel-alerts/",
                'get')
                .then(checkOk)
                .then(response => response.json())
                .then(alerts => {
                    setFuelAlerts(alerts)
                    resolve(alerts)
                })
                .catch((error) => {
                    console.error("Error getting fuel alerts: " + error)
                    setError(error)
                    reject(error)
                })
                .finally(() => {
                    setLoading(false)
                });
        });
    }

    const createFuelAlert = async (fuelAlertRequest: FuelAlertRequest) => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + "tracking/v1/alerts/fuel-alerts",
                'post',
                JSON.stringify(fuelAlertRequest))
                .then(checkCreated)
                .then(response => response.json())
                .then(alert => resolve(alert))
                .catch((error) => {
                    console.error("Error creating fuel alerts: " + error)
                    reject(error)
                });
        });
    }

    const updateFuelAlert = async (fuelAlertRequest: FuelAlertRequest, fuelAlertId: string) => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `tracking/v1/alerts/fuel-alerts/${fuelAlertId}`,
                'put',
                JSON.stringify(fuelAlertRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(alert => resolve(alert))
                .catch((error) => {
                    console.error("Error updating fuel alerts: " + error)
                    reject(error)
                });
        });
    }

    const deleteFuelAlert = async (fuelAlertId: string) => {
        return new Promise<void>((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `tracking/v1/alerts/fuel-alerts/${fuelAlertId}`,
                'delete')
                .then(checkOk)
                .then(alert => resolve())
                .catch((error) => {
                    console.error("Error deleting fuel alerts: " + error)
                    reject(error)
                });
        });
    }

    useEffect(() => {
        if (props.shouldLoadAll) {
            getAllFuelAlerts();
            if (props.shouldPoll) {
                const interval = setInterval(getAllFuelAlerts, props.pollFrequency || 30000);
                return () => clearInterval(interval);
            }
        }
    }, []);

    return {loading, error, fuelAlerts: fuelAlerts, getAllFuelAlerts, createFuelAlert, updateFuelAlert, deleteFuelAlert}
}
