import React from 'react';
import { Box, Typography } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";
import { Product } from './ProductSelector';
import { colors } from '../../themes/AppTheme';

interface ProductSelectorProductProps {
    product: Product;
}

const ProductSelectorProduct = (props: ProductSelectorProductProps) => {
    const { t } = useTranslation();

    const handleClick = (url: string, name: string) => {
        ReactGA.event("RT2-PS-Link-" + name);
        window.open(url, "_blank");
    };

    return (
        <Box
            component="a"
            onClick={() => handleClick(props.product.url, t(props.product.nameKey))}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                textAlign: 'left',
                textDecoration: 'none',
                margin: '5px',
                py: 2,
                px: 3,
                borderRadius: '5px',
                border: '1px solid',
                borderColor: colors.ram_light_blue,
                "&:hover": {
                    borderColor: colors.ram_purple,
                    backgroundColor: colors.ram_light_blue,
                },
                gap: 2
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '50px',
                    height: '50px',
                    flexShrink: 0
                }}
            >
                <img
                    src={props.product.icon}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        pointerEvents: 'none'
                    }}
                    alt={`${t(props.product.nameKey)} Logo`}
                />
            </Box>

            <Box sx={{ flex: 1 }}>
                <Typography variant="dropdownBody" sx={{ fontWeight: 'bold' }}>
                    {t(props.product.nameKey)}
                </Typography>
                <Typography variant="dropdownCaption" component='span'>
                    {t(props.product.descriptionKey)}
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <LaunchIcon />
            </Box>
        </Box>
    );
};

export default ProductSelectorProduct;
