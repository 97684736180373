import usePlatformFetch from "../shared/PlatformFetch";
import PropertiesLoader from "../../properties/PropertiesLoader";
import { Notification } from "./dto/Notification";
const properties = PropertiesLoader();

export function useNotificationsApi() {

    const { platformFetch, checkOk } = usePlatformFetch();

    const getNotificationCount = async () => {
        return new Promise<number>((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + "notification/v1/notifications/count",
                'get')
                .then(checkOk)
                .then(response => response.text())
                .then(text => {
                    const count = parseInt(text, 10);
                    if (!isNaN(count)) {
                        resolve(count);
                    } else {
                        reject("Invalid count received");
                    }
                })
                .catch((error) => {
                    console.error("Error getting notification count: " + error)
                    reject(error)
                });
        });
    }

    const getNotifications = async () => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + "notification/v1/notifications",
                "get")
                .then(checkOk)
                .then(response => response.json())
                .then(json => mapToNotificationObjects(json))
                .then(notifications => resolve(notifications))
                .catch((error) => {
                    console.error("Failed to get notifications: " + error)
                    reject(error)
                });
        });
    }

    const deleteNotification = async (notificationId: number) => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + "notification/v1/notifications/" + notificationId,
                "delete")
                .then(checkOk)
                .then(() => resolve(true))
                .catch((error) => {
                    console.error("Failed to delete notification: " + error)
                    reject(error)
                });
        });
    }

    const deleteAllNotifications = async () => {
        return new Promise<void>((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + "notification/v1/notifications",
                "delete")
                .then(checkOk)
                .then(() => resolve())
                .catch((error) => {
                    console.error("Failed to delete notifications: " + error)
                    reject(error)
                });
        });
    }


    return { getNotificationCount, getNotifications, deleteNotification, deleteAllNotifications };
}

function mapToNotificationObjects(json: any) {
    return json.map((object: any) => {
        return new Notification({
            id: object.id,
            title: object.title,
            description: object.description,
            unread: object.unread,
            createdAt: new Date(object.createdAt),
        }
        )
    })
}
