import PropertiesLoader from "../../properties/PropertiesLoader";
import usePlatformFetch from "../shared/PlatformFetch";
import {EngineHours} from "../assets/dto/Asset";

const properties = PropertiesLoader();
export function useEngineHoursApi() {

    const {platformFetch, checkOk, checkCreated} = usePlatformFetch();

    const enableEngineHoursForAsset = async (assetId: number) => {
        return new Promise<void>((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `enginehours/v1/${assetId}/enable`,
                'post')
                .then(checkCreated)
                .then(() => resolve())
                .catch((error) => {
                    console.error("Error enabling engine hours: " + error)
                    reject(error)
                });
        });
    }

    const disableEngineHoursForAsset = async (assetId: number) => {
        return new Promise<void>((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `enginehours/v1/${assetId}/disable`,
                'post')
                .then(checkOk)
                .then(() => resolve())
                .catch((error) => {
                    console.error("Error deleting engine hours: " + error)
                    reject(error)
                });
        });
    }

    const getAssetEngineHours = async (assetId: number) => {
        return platformFetch(`${properties.platformApiEndpoint}enginehours/v1/${assetId}`, 'get')
            .then(checkOk)
            .then(response => response.json())
            .then((data: EngineHours) => data)
            .catch((error) => {
                console.error("Error getting engine hours for asset: " + error);
                return undefined;
            });
    }

    const updateEngineHoursForAsset = async (assetId: number, engineTimeHours: number) => {
        const engineTimeSeconds = engineTimeHours * 3600;
        const engineTimeObject = {engineTimeSeconds: engineTimeSeconds};
        return new Promise<void>((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `enginehours/v1/${assetId}/edit`,
                'post',
                JSON.stringify(engineTimeObject))
                .then(checkOk)
                .then(() => resolve())
                .catch((error) => {
                    console.error("Error editing engine hours: " + error)
                    reject(error)
                });
        });
    }

    return {enableEngineHoursForAsset, disableEngineHoursForAsset, getAssetEngineHours, updateEngineHoursForAsset}
}