import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FileFormat } from "../../../enum/FileFormat";
import { colors } from "../../../themes/AppTheme";

interface ReportFormatProps {
    selectedReportFormat: string;
    setSelectedReportFormat: (selectedReportFormat: FileFormat) => void;
}

export default function ReportFormatSelection(props: ReportFormatProps) {
    const { t } = useTranslation();

    function handleReportFormat(event: SelectChangeEvent<any>) {
        props.setSelectedReportFormat(event.target.value);
    }

    // Filter the enum values to get only PDF and CSV
    const fileFormats = Object.values(FileFormat).filter(
        (format) => format === FileFormat.PDF || format === FileFormat.CSV
    );

    return (
        <Select
            id="file_format"
            size="small"
            value={props.selectedReportFormat}
            color="info"
            style={{ height: '100%', fontSize: '12px', color: colors.ram_purple }}
            onChange={handleReportFormat}
            fullWidth
        >
            {fileFormats.map((format) => (
                <MenuItem key={format} style={{ height: 25, fontSize: '12px' }} value={format}>
                    {t(`scheduled_report.${format.toLowerCase()}`)}
                </MenuItem>
            ))}
        </Select>
    );
}
