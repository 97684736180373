import {useEffect, useState} from "react";
import usePlatformFetch from "../shared/PlatformFetch";
import PropertiesLoader from "../../properties/PropertiesLoader";
import {Webhook} from "./dto/Webhook";
import {WebhookRequest} from "./dto/WebhookRequest";

const properties = PropertiesLoader();

interface WebhookApiOptions {
    shouldLoadAll?: boolean;
    shouldPoll?: boolean;
    pollFrequency?: number;
}

export function useWebhookApi(props: WebhookApiOptions = {}) {
    const {platformFetch, checkOk} = usePlatformFetch();

    const [webhooks, setWebhooks] = useState<Webhook[]>([]);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const getAllWebhooks = async () => {
        return new Promise((resolve, reject) => {
            setLoading(true)
            platformFetch(
                properties.platformApiEndpoint + "tracking/v1/webhooks",
                'get')
                .then(checkOk)
                .then(response => response.json())
                .then(webhooks => {
                    setWebhooks(webhooks)
                    setLoading(false)
                    resolve(webhooks)
                })
                .catch((error) => {
                    console.error("Error getting webhooks: " + error)
                    setError(error)
                    setLoading(false)
                    reject(error)
                });
        });
    }

    const createWebhook = async (webhookRequest: WebhookRequest) => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `tracking/v1/webhooks`,
                'post',
                JSON.stringify(webhookRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(webhook => resolve(webhook))
                .catch((error) => {
                    console.error("Error creating webhook: " + error)
                    reject(error)
                });
        });
    }

    const updateWebhook = async (webhookUpdateRequest: WebhookRequest, webhookId: string) => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `tracking/v1/webhooks/${webhookId}`,
                'put',
                JSON.stringify(webhookUpdateRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(webhook => resolve(webhook))
                .catch((error) => {
                    console.error("Error updating webhook: " + error)
                    reject(error)
                });
        });
    }

    const deleteWebhook = async (webhookId: string) => {
        return new Promise<void>((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `tracking/v1/webhooks/${webhookId}`,
                'delete')
                .then(checkOk)
                .then(() => resolve())
                .catch((error) => {
                    console.error("Error deleting webhook: " + error)
                    reject(error)
                });
        });
    }

    useEffect(() => {
        if (props.shouldLoadAll) {
            getAllWebhooks();
            if (props.shouldPoll) {
                const interval = setInterval(getAllWebhooks, props.pollFrequency ?? 30000);
                return () => clearInterval(interval);
            }
        }
    }, []);

    return {
        loading, error, webhooks: webhooks, getAllWebhooks: getAllWebhooks,
        createWebhook: createWebhook, updateWebhook: updateWebhook, deleteWebhook: deleteWebhook
    }
}
